import { Token } from "types"


const DEFAULT_TOKEN_OUT:Token=
{
    chain: "base",
    contract_address: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
    symbol: "USDT",
    name: "USDT",
    image_url: "",
    decimals: 6
  }

const DEFAULT_TOKEN_IN:Token={
    name: "",
    symbol: "WETH",
    chain: "base",
    contract_address: "",
    image_url: "",
    decimals: 18
}

export {DEFAULT_TOKEN_IN,DEFAULT_TOKEN_OUT}