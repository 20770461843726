import { Currency, Percent } from '@uniswap/sdk-core';
import { StyledModal } from "components/Modal/styled_modal";
import { Input } from "components/NumericalInput";
import TokenAvatar from "components/TokenAvatar";
import TokenSelectPanel from "components/TokenSelectPanel";
import { useState } from "react";
import { ChevronDown } from "react-feather";
import { Flex } from "rebass";
import styled from "styled-components";
import { Token } from "types";
const Container = styled(Flex)`
  border-radius: 8px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};

  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 18px;
  > div:first-child {
      >div {
        margin-right:5px;
      }
  }
  > input {
    border: 0;
    text-align: left;
    font-weight: 700;
    margin:0;
    padding:0;
  }
`;

const TokenLabel=styled.div`
display: -webkit-flex; /* Safari */
align-items: center;
cursor: pointer;
`

interface SwapCurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  onCurrencySelect?: (currency: Token) => void
  currency: Token 
  hideBalance?: boolean
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  priceImpact?: Percent
  locked?: boolean
  loading?: boolean
  disabled?: boolean
  numericalInputSettings?: {
    disabled?: boolean
    onDisabledClick?: () => void
  }
}

export default function TokenAmountInput({value,onUserInput,onCurrencySelect,showMaxButton,currency}:SwapCurrencyInputPanelProps) {
   const [modalVisable,setModalVisable]=useState(false)

   const handleTokenChange=(token:Token)=>{
    onCurrencySelect&& onCurrencySelect(token)
   }
   const handleAmmountChange=(val:string)=>{
    onUserInput(val)
   }
  return (
    <>
      <Container>
      <Input width={"100%"}  
      
      value={value}
      onUserInput={onUserInput}

      maxDecimals={currency.decimals}
      
      />
        <TokenLabel onClick={()=>{
          setModalVisable(true)
        }}>
            <TokenAvatar
              size={32}
              token={currency}
            />
         
          <div style={{marginLeft:"5px"}}>{currency.symbol}</div>
          <div><ChevronDown size={12}/></div>
        </TokenLabel>

      </Container>

      <StyledModal isOpen={modalVisable} modalTitle={"Select Crypto"} onDismiss={()=>{setModalVisable(false)}}>
        <div><TokenSelectPanel value={""} onChange={function (e: Token): void {
          handleTokenChange(e);
          setModalVisable(false);
        } } /></div>
      </StyledModal>
    </>
  );
}
