import styled from "styled-components/macro";
import {
  useNavigate,
  useLocation,
  Link,
  useSearchParams,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { formatWithArray } from "utils";
import { useClientWidth } from "../../state/application/hooks";
import NoData from "components/NoData";
import {MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"
import ListSelect from "components/ListSelect";

import { SearchCollectionLoadItem } from "pages/Nft/components/loadCompents";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { Input} from "@rebass/forms";
import { useCollectionListQuery } from "apis/collection";
import {
  HeaderTop,
  TopLine,
  TopLeft,
  TopCenter,
  TopRight,
  LeftImg,
  LeftDiv,
  LogoText,
  MenuItem,
  TopLineTel,
  TopTelLeft,
  CloseImg,
  TopTelLeftImg,
  SearchImg,
  TopTelLeftText,
  DownBlock,
  BackImg,
} from "./components_";
import {
  SearchCollectionItem,
  SearchCollectionLine,
  SearchCollectionImgBlock,
  PopCollectionImg,
  SearchCollectionName,
  TrendLineText1,
  TrendLineImg,
} from "pages/Nft/components_";
import { FlexBlock } from "pages/Nft/NftItems/components_";
import topLogo from "../../assets/top_logo.png";
import searchIcon from "assets/tool/search_icon.png";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import arrowIcon from "assets/tool/arrow_icon.png";
import arrowIconN from "assets/tool/arrow_iconw.png";
import nftDefault from "assets/nft/nft_defult.png";

import "./index.css";

import Web3Status from "components/Web3Status";
import { Collection } from "types";
const ItemValue = styled.div`
  max-width: 400px;
  min-width: 150px;
  height: 38px;
  border-radius: 8px;
  background: ${({ theme }) => theme.modalBG1};
  // background: #F00;
  border: 1px solid ${({ theme }) => theme.bg2};
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  position: relative;
  :hover {
    border: 1px solid ${({ theme }) => theme.border1};
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    max-width: 800px;
    min-width: 0px;
    width: calc(100% - 25px);
  }
`;
const inputStyle = {
  outline: "none",
  width: "calc(100% - 40px)",
  height: "40px",
  border: "none",
  textAlign: "left",
  background: "transparent",
};
export default function Header(prop: any) {
  let location = useLocation();
  const [darkMode] = useDarkModeManager();

  const clientWidth = useClientWidth();
  let navigate = useNavigate();
  const [isTel, setIsTel] = useState(false);
  const [name, setName] = useState("");
  const [isAddNft, setIsAddNft] = useState(false);
  const [isNft, setIsNft] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [isDropEditor, setIsDropEditor] = useState(false);
  const [collections, setCollections] = useState<Collection[] | null>(null);

  const { data, status } = useCollectionListQuery({
    page: 1,
    pageSize: 4,
    name: name,
  });

  if (isScroll !== prop.isScroll) {
    setIsScroll(prop.isScroll);
  }

  let [searchParams, setSearchParams] = useSearchParams();

  const getCollection = () => { };
  useEffect(() => {
    if (name && name !== "") {
      getCollection();
    }
  }, [name]);
  useEffect(() => {
    if (status === "fulfilled" && data?.success) {
      if (data?.data) {
        setCollections(data.data);
      } else {
        setCollections([]);
      }
    }
  }, [data, status]);
  useEffect(() => {
    var tab = searchParams.get("tab");
    if (tab === "Drop page") {
      setIsDropEditor(true);
    } else {
      setIsDropEditor(false);
    }
  }, [searchParams]);

  if (Number(clientWidth) < 801 && !isTel) {
    setIsTel(true);
  } else if (Number(clientWidth) > 800 && isTel) {
    setIsTel(false);
  }
  useEffect(() => {
    let url = window.location.pathname;
    if (url === "/creat-nft") {
      setIsAddNft(true);
    } else {
      setIsAddNft(false);
    }
    if (
      url === "/nfts" ||
      url === "/" ||
      url === "/drop" ||
      url === "/droplist"
    ) {
      setIsNft(true);
    } else {
      setIsNft(false);
    }
  }, []);

  return (
    <>
      <HeaderTop>

        {isTel ? (<TopLineTel
          isTel={isTel && !isAddNft}
          // style={
          //   isNft &&  !isScroll
          //     ? { backdropFilter: "blur(0px)", background: "transparent" }
          //     : {}
          // }
        >
          {isSearch ? (
            <>
              {" "}
              <FlexBlock style={{ width: "100%" }}>
                <BackImg
                  onClick={() => {
                    setIsSearch(false);
                    setName("");
                  }}
                  src={darkMode ? arrowIcon : arrowIconN}
                />
                <ItemValue>
                  <SearchImg src={searchIcon} />
                  <Input
                    type="text"
                    placeholder="Search"
                    value={name}
                    sx={inputStyle}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <CloseImg
                    onClick={() => setName("")}
                    src={darkMode ? closeIconN : closeIcon}
                    style={name ? { opacity: "0.5" } : { opacity: "0" }}
                  />
                  {name && name !== "" ? (
                    <DownBlock>
                      {status === "fulfilled" ? (
                        //nodata or collections.map
                        collections && collections.length > 0 ? (
                          collections?.map((i: Collection, index: number) => (
                            <SearchCollectionItem
                              key={i.id}
                              onClick={() => {
                                navigate(
                                  `/nfts/collection/${i.chain}/${i.contract_address}`
                                );
                                setName("");
                              }}
                            >
                              <SearchCollectionLine>
                                <div>
                                  <SearchCollectionImgBlock style={i.image_url ? { background: `url("${i.image_url || nftDefault}") no-repeat center`, backgroundSize: 'cover' } : {}}>

                                    {i.image_url ? null : <PopCollectionImg

                                      src={nftDefault}

                                      alt=""
                                    />}

                                  </SearchCollectionImgBlock>
                                  <SearchCollectionName>
                                    {i.name}
                                  </SearchCollectionName>
                                </div>

                                <div>
                                  {i.payment_tokens?.[0].image_url ? (
                                    <TrendLineImg
                                      src={i.payment_tokens?.[0].image_url}
                                      alt=""
                                      style={
                                        i.payment_tokens?.[0].image_url
                                          ? { opacity: "1", marginRight: "2px" }
                                          : { opacity: "0", marginRight: "2px" }
                                      }
                                    />
                                  ) : null}

                                  <TrendLineText1>
                                    <span>
                                      {formatWithArray(i.stats.floor_price)}{" "}
                                    </span>

                                    {/* {i.payment_tokens[0].symbol} */}
                                  </TrendLineText1>
                                </div>
                              </SearchCollectionLine>
                            </SearchCollectionItem>
                          ))
                        ) : (
                          <NoData
                            onChange={() => setName("")}
                            size={"small"}
                          ></NoData>
                        )
                      ) : (
                        //loading
                        <>
                          {" "}
                          <SearchCollectionLoadItem />
                          <SearchCollectionLoadItem />
                          <SearchCollectionLoadItem />
                          <SearchCollectionLoadItem />
                        </>
                      )}
                    </DownBlock>
                  ) : null}
                </ItemValue>
              </FlexBlock>
            </>
          ) : (
            <>
              <Link to={"/"}>
                {" "}
                <TopTelLeft isShow={!isAddNft}>
                  <TopTelLeftImg src={topLogo} style={{ marginRight: "5px" }} />
                  <TopTelLeftText className="fontFrk3">
                    zooker.io
                    {/* <div>Testnets</div> */}
                  </TopTelLeftText>
                </TopTelLeft>
              </Link>
              <FlexBlock>
                {" "}
                <SearchImg
                  src={searchIcon}
                  style={{ marginRight: "5px" }}
                  onClick={() => setIsSearch(true)}
                />
                <Web3Status />
              </FlexBlock>
            </>
          )}
        </TopLineTel>) : (<TopLine
          isTel={isTel}
          // style={
          //  isNft && !isScroll
          //     ? { backdropFilter: "blur(0px)", background: "transparent" }
          //     : {}
          // }
        >
          {isDropEditor ? (
            <TopLeft>
              <BackImg
                onClick={() => navigate(`${location.pathname}?tab=Settings`)}
                src={darkMode ? arrowIcon : arrowIconN}
              />
              Drop Page
            </TopLeft>
          ) : (
            <Link to={"/"}>
              <TopLeft>
                <LeftImg
                  src={topLogo}
                  alt=""
                  style={{ width: "40px", height: "40px" }}
                />

                <LeftDiv>
                  <LogoText>zooker.io</LogoText>
                </LeftDiv>
              </TopLeft>
            </Link>
          )}
          {isDropEditor ? null : (
            <TopCenter>
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <MenuItem onClick={() => { navigate("/nfts") }}>NFTs</MenuItem>
                {/* <MenuItem onClick={() => navigate("/drop")}>Drop</MenuItem> */}
                <MenuItem onClick={() => navigate("/cryptos")}>Crypto</MenuItem>
                <div style={{width:'auto'}}>
                <ListSelect
                value={'value'}
                defaultText={'Stats'}
                optionArr={[
                  "Token Stats",
                  "NFT Stats",
                  "Account Stats",
                ]}
                typeValues={["/tokens/rankings/top", "/nfts/rankings/top", "",]}
                onChange={(str)=>{navigate(str)}}
                size=""
              />
                  {/* Stats */}
                  </div>
              </div>
              <ItemValue>
                <SearchImg src={searchIcon} />
                <Input
                  type="text"
                  placeholder="Search"
                  value={name}
                  sx={inputStyle}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <CloseImg
                  onClick={() => setName("")}
                  src={darkMode ? closeIconN : closeIcon}
                  style={name ? { opacity: "0.5" } : { opacity: "0" }}
                />
                {name && name !== "" ? (
                  <DownBlock>
                    {status === "fulfilled" ? (
                      //nodata or collections.map
                      collections && collections.length > 0 ? (
                        collections?.map((i: Collection, index: number) => (
                          <SearchCollectionItem
                            key={i.id}
                            onClick={() => {
                              navigate(
                                `/nfts/collection/${i.chain}/${i.contract_address}`
                              );
                              setName("");
                            }}
                          >
                            <SearchCollectionLine>
                              <div>
                                <SearchCollectionImgBlock style={i.image_url ? { background: `url("${i.image_url || nftDefault}") no-repeat center`, backgroundSize: 'cover' } : {}}>
                                  {/* <PopCollectionImg src={i.image_url||nftDefault} alt="" /> */}

                                  {i.image_url ? null : <PopCollectionImg src={nftDefault} alt="" />}
                                </SearchCollectionImgBlock>
                                <SearchCollectionName>
                                  {i.name}
                                </SearchCollectionName>
                              </div>

                              <div>
                                {i.payment_tokens?.[0].image_url ? (
                                  <TrendLineImg
                                    src={i.payment_tokens?.[0].image_url}
                                    alt=""
                                    style={
                                      i.payment_tokens?.[0].image_url
                                        ? { opacity: "1", marginRight: "2px" }
                                        : { opacity: "0", marginRight: "2px" }
                                    }
                                  />
                                ) : null}

                                <TrendLineText1>
                                  <span>
                                    {formatWithArray(i.stats.floor_price)}{" "}
                                  </span>

                                </TrendLineText1>
                              </div>
                            </SearchCollectionLine>
                          </SearchCollectionItem>
                        ))
                      ) : (
                        <NoData
                          onChange={() => setName("")}
                          size={"small"}
                        ></NoData>
                      )
                    ) : (
                      <>
                        {" "}
                        <SearchCollectionLoadItem />
                        <SearchCollectionLoadItem />
                        <SearchCollectionLoadItem />
                        <SearchCollectionLoadItem />
                      </>
                    )}
                  </DownBlock>
                ) : null}
              </ItemValue>
            </TopCenter>
          )}
          <TopRight>
            <Web3Status />
          </TopRight>
        </TopLine>)}

      </HeaderTop>
    </>
  );
}
