
import topLogo from "../../assets/top_logo.png";
import btnTwicon from "../../assets/btn_twicon.png";
import btnTwiconN from "../../assets/btn_twicon_n.png";
import btnDisicon from "../../assets/btn_disicon.png";
import btnDisiconN from "../../assets/btn_disicon_n.png";
import btnEmilicon from "../../assets/btn_emilicon.png";
import btnEmiliconN from "../../assets/btn_emilicon_n.png";
import styled from "styled-components/macro";
import { useState,useEffect  } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

import { useClientWidth } from "../../state/application/hooks";

// import Frick3 from '../../assets/Frick/Frick3.otf';
import "./index.scss";
import { Flex  } from "rebass";
import { StyledLink } from "theme";
import { useDarkModeManager } from "theme/components/ThemeToggle";

// font-family:Frick3;
// src:url('${Frick3}') format('TrueType');
const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 500;

  color: ${({ theme }) => theme.text1};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    font-size: 18px;
  
    color: #fff;
  }
`;
const FootDiv = styled.div`
  width: 100vw;
  overflow: hidden;
  background: ${({ theme }) => theme.bg0};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    background: #181818;
  }
`;
const FootLine = styled.div`
  width:95vw;
  max-width: 1600px;
  margin: 0 auto;

  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  box-sizing: border-box;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    width:91.6vw;
    margin: 0 auto;
    display: flex;
    padding: 7vw 0 8vw;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    box-sizing: border-box;
    align-items: flex-start;
  }
`;
const FootLeft = styled.div`
  display: flex;
  align-items: center;
`;
const LogoImg = styled.img`
  width: 54px;
  height: 54px;
`;
const BtnImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px; 
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 4.9vw;
  height: 4.9vw;
  margin-right: 5px;
  }
`;
const FootBtns = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    margin-top:10px;
    width:100%;
    justify-content: space-around;
  }
`;
const BtnOne = styled.div`
  width: 129px;
  height: 46px;
  margin-right: 20px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.text2};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 25vw;
    min-width: 82px;
    height: 7.5vw;
    min-height: 28px;
    border-radius: 6px;
    margin-right: 0px;
    border: 1px solid #fff;}
`;
const BtnText = styled.div`
  font-size: 16px;

  font-family: Amiko-SemiBold, Amiko;
  font-weight: bold;
  color: ${({ theme }) => theme.text2};
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}){
    font-size: 12px;
    color: #fff;
  }
`;
export default function Header() {
  const clientWidth = useClientWidth();
  const [darkMode] = useDarkModeManager();
  const [isTel, setIsTel] = useState(false);
  const [showFoot, setShowFoot] = useState(true)
  const {pathname}=useLocation()
  if (Number(clientWidth) < 801 && !isTel) {
    setIsTel(true);
  } else if (Number(clientWidth) > 800 && isTel) {
    setIsTel(false);
  }  
  useEffect(() => {
   if(pathname==='/account/collected'||pathname.includes('/drop/')||pathname.includes('/overview')){
    setShowFoot(false)
   }else{
    setShowFoot(true)
   }
  }, [pathname])
  return (
    <>
      {showFoot?( <FootDiv>
        <FootLine style={Number(clientWidth) < 801?{paddingBottom:'0'}:{}}>
          <FootLeft>
            <LogoImg src={topLogo} alt="" style={{ marginRight: "5px" }} />
            <LogoText className="fontFrk3">ZOOKER.IO</LogoText>
          </FootLeft>

          <FootBtns>
            <BtnOne
              onClick={() => {
                window.open("https://twitter.com/zooker_io");
              }}
            >
              <BtnImg src={darkMode||Number(clientWidth) < 801 ? btnTwiconN : btnTwicon} />
              <BtnText>Twitter</BtnText>
            </BtnOne>
            <BtnOne
              onClick={() => {
                window.open("https://discord.gg/JXtnC58YJ7");
              }}
            >
              <BtnImg src={darkMode||Number(clientWidth) < 801 ? btnDisiconN : btnDisicon} />

              <BtnText>Discord</BtnText>
            </BtnOne>
            <BtnOne>
              <BtnImg src={darkMode||Number(clientWidth) < 801 ? btnEmiliconN : btnEmilicon} />
              <a href="mailto: business@zooker.io">
                {" "}
                <BtnText>Email</BtnText>
              </a>
            </BtnOne>
          </FootBtns>
        </FootLine>
        <FootLine>
          <Flex flexDirection={"column"}>
            <StyledLink
              style={{ paddingBottom: 10 }}
              href="https://zooker.gitbook.io/whitepaper/"
            >
              Whitepaper
            </StyledLink>
            <StyledLink
              style={{ paddingBottom: 10 }}
              href="https://github.com/zooker-io"
            >
              GitHub
            </StyledLink>
          </Flex>
        </FootLine>
      </FootDiv>):null}
      
   

    </>
  );
}
