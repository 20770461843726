import { useEffect, useState } from "react";
import { useDarkModeManager } from "theme/components/ThemeToggle";

import RadixPopconfirm from "components/Popconfirm";
import RadixPopover from "components/Popover";
import { useClientWidth } from "state/application/hooks";
import { formatWithArray, formatWithUnit } from "utils";

import { useGetChainListQuery } from "apis/chain";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import delB from "assets/tool/del_b.png";
import delW from "assets/tool/del_w.png";

import nftDefault from "assets/nft/nft_defult.png";
import starB from "assets/tool/star_b.png";
import starF from "assets/tool/star_f.png";
import starW from "assets/tool/star_w.png";
import {
  LARGE_MEDIA_BREAKPOINT,
  MIN_WIDTH_MEDIA_BREAKPOINT,
} from "constants/width";
import { TopToken } from "types";
// import { CollectInfoBlock } from "../CardTableLoadRow";

export const CardTableMain = styled.div`
  width: 100%;
`;
export const CardTableMaincol = styled.div`
  width: 12.5%;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: none;
  }
`;
export const CardTableMaincol1 = styled(CardTableMaincol)`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: block;
    width: calc(22.5% - 20px);
    font-size: 12px;
  }
`;
export const CardTableMaincolLast = styled.div`
  width: 5%;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 20px;
  }
`;
export const CardTableMainRowWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.border1};
  padding: 0 14px;
  box-sizing: border-box;
  height: 96px;
`;
export const CollectInfoWeb = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 77.5%;
    font-size: 12px;
  }
`;
export const RowImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const CollectInfoImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;
export const CollectInfoLogo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  object-fit: contain;

  position: absolute;
  bottom: 0;
  right: -5px;
`;

export const CollectInfoBlock = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg2};
  position: relative;
`;
export const CollectInfoIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const ChangeDiv = styled.div`
  color: #31bd65;
  margintop: 4px;
  overflow: hidden;
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
`;
export const CollectInfoText = styled.div`
  font-size: 14px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
  margin: 0 6px;
  max-width: calc(100% - 80px);
  overflow: hidden;
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
`;
export const Text3 = styled.span`
  color: ${({ theme }) => theme.text3};
`;

interface TopRowProps {
  i: TopToken;
  type: string;
  iswatch: boolean | null;
  onDelete: () => void;
  onAdd: () => void;
}

export default function CardTableMainRow({
  i,
  type,
  iswatch,
  onDelete,
  onAdd,
}: TopRowProps) {
  const [darkMode] = useDarkModeManager();
  const clientWidth = useClientWidth();
  const navigate = useNavigate();
  const [idList, setIdList] = useState<string[]>([]);

  const { data } = useGetChainListQuery({});
  useEffect(() => {
    if (data) {
      if (data.data) {
        let idList: string[] = [];
        data.data.forEach((el) => {
          idList.push(el.id || "");
        });
        setIdList(idList);
      }
    }
  }, [data]);
  return (
    <>
      <CardTableMainRowWrap>
        <CollectInfoWeb
          onClick={() => {
            navigate(`/tokens/${i.chain}/${i.contract_address}/details`);
          }}
        >
          <CollectInfoBlock>
            {i.image_url ? (
              <CollectInfoImg src={i.image_url || nftDefault} />
            ) : (
              <>{i.name.slice(0, 1)}</>
            )}
            {idList.indexOf(i.chain) === -1 ? null : (
              <CollectInfoLogo
                src={data?.data?.[idList.indexOf(i.chain)].logo}
              />
            )}
          </CollectInfoBlock>
          <CollectInfoText>
            <div> {i.name}</div>
            {Number(clientWidth) < 801 ? (
              <div style={{ marginTop: "4px" }}>
                <Text3>Liquidity：</Text3>
                {formatWithArray(i.liquidity, 2)}
              </div>
            ) : null}
          </CollectInfoText>

          {/* <CollectInfoIcon src={attestationIcon} /> */}
        </CollectInfoWeb>
        <CardTableMaincol1>
          <div>
            <RadixPopover
              val={"$ " + formatWithArray(i.usd_price)}
              label={"$ " + formatWithArray(i.usd_price)}
            ></RadixPopover>
          </div>

          {Number(clientWidth) < 801 ? (
            <ChangeDiv
              style={i.change ? (i.change < 0 ? { color: "#eb4b6d" } : {}) : {}}
            >
              {i.change ? (
                <RadixPopover
                  val={
                    i.change < 0
                      ? formatWithArray(i.change * 100, 2) + "%"
                      : "+" + formatWithArray(i.change * 100, 2) + "%"
                  }
                  label={
                    i.change < 0
                      ? formatWithArray(i.change * 100, 2) + "%"
                      : "+" + formatWithArray(i.change * 100, 2) + "%"
                  }
                ></RadixPopover>
              ) : (
                "--"
              )}
            </ChangeDiv>
          ) : null}
        </CardTableMaincol1>
        <CardTableMaincol
          style={
            i.change
              ? i.change < 0
                ? { color: "#eb4b6d" }
                : { color: "#31bd65" }
              : {}
          }
        >
          {i.change ? (
            <RadixPopover
              val={
                i.change < 0
                  ? formatWithArray(i.change * 100, 2) + "%"
                  : "+" + formatWithArray(i.change * 100, 2) + "%"
              }
              label={
                i.change < 0
                  ? formatWithArray(i.change * 100, 2) + "%"
                  : "+" + formatWithArray(i.change * 100, 2) + "%"
              }
            ></RadixPopover>
          ) : (
            "--"
          )}
        </CardTableMaincol>
        <CardTableMaincol>
          <RadixPopover
            val={"$ " + formatWithArray(i.volume, 2)}
            label={"$ " + formatWithUnit(i.volume, 2)}
          ></RadixPopover>
        </CardTableMaincol>

        <CardTableMaincol>
          <RadixPopover
            val={formatWithArray(i.liquidity, 2) || "0"}
            label={formatWithUnit(i.liquidity, 2) || ""}
          ></RadixPopover>

          {/* {formatWithArray(i.native_floor_price)}<Text3>{' '}{i.native_symbol}</Text3> */}
        </CardTableMaincol>
        <CardTableMaincol>
          <RadixPopover
            val={"$ " + formatWithArray(i.fully_diluted_market_cap, 2)}
            label={"$ " + formatWithUnit(i.fully_diluted_market_cap, 2)}
          ></RadixPopover>
        </CardTableMaincol>
        <CardTableMaincol> {formatWithArray(i.holders)}</CardTableMaincol>
        {type == "del" ? (
          <CardTableMaincolLast>
            <RadixPopconfirm
              val={'Are you sure to delete this?'}
              labelNode={ <RowImg src={darkMode ? delW : delB} />}
              onConfirm={() => {
                onDelete();
              }}
            ></RadixPopconfirm>
          </CardTableMaincolLast>
        ) : iswatch ? (
          <RadixPopconfirm
          val={'Are you sure to delete this?'}
          labelNode={   <RowImg src={iswatch ? starF : darkMode ? starW : starB} />}
          onConfirm={() => {
            onDelete();
          }}
        ></RadixPopconfirm>
        ) : (
          <CardTableMaincolLast>
            <RowImg
              onClick={() => {
                onAdd();
              }}
              src={iswatch ? starF : darkMode ? starW : starB}
            />
          </CardTableMaincolLast>
        )}
      </CardTableMainRowWrap>
    </>
  );
}
