import { Box, Text } from "rebass";
import { useSelectChain } from "state/chain/hooks";
import styled from "styled-components";
import { Token } from "types";


 const CollectInfoBlock = styled(Box)`
  
  border-radius: 50%;
  text-align: center;
  align-content:center;
  align-items:center;
  color: ${({ theme }) => theme.text1};
  background: ${({ theme }) => theme.bg2};
  position: relative;
  width:${({ size }) => size?`${size}px`:"32px"};
  height:${({ size }) => size?`${size}px`:"32px"};
`;

 const CollectInfoImg = styled.img<{size:number}>`
 width:${({ size }) => size?`${size}px`:"16px"};
 height:${({ size }) => size?`${size}px`:"16px"};
 
  border-radius: 50%;
  object-fit: cover;
`;

 const ChainImage = styled.img<{size:number}>`
 width:${({ size }) => size?`${size}px`:"16px"};
 height:${({ size }) => size?`${size}px`:"16px"};
 background: ${({ theme }) => theme.bg1};
  border-radius: 50%;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  right: -5px;
`;


function Avatar({size,token}:{size:number,token:Token}) {

   const chain= useSelectChain(token.chain)
  
return (
    
    <>
    <Box  width={`${size}px`}>
    <CollectInfoBlock size={size} fontSize={`${size/2}px`}>
    {token.image_url ? (
              <CollectInfoImg size={size} src={token.image_url} />
            ) : (
              <Text lineHeight={`${size}px`} alignContent={"center"} alignItems={"center"} justifyContent={"center"} justifyItems={"center"}>{token.symbol.slice(0, 1)}</Text>
            )}
       <ChainImage size={size/2} src={chain?.logo} />
         
    </CollectInfoBlock>
    </Box>
    </>
)
};
export default Avatar