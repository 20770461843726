import { Collection, CurrencyInfo, MarketPrice, Token, TokenBalance } from 'types';
import { emptySplitApi } from './emptySplitApi'
export interface WatchListRequest {
  page: number;
  pageSize: number | null;
  chain: string;
  period: string;
  sortby: string;
  direct: string;
}
export interface WatchListResponse {
  data: []
  // data: Collection[]
  total: number;
  message: null | string;
  success: boolean;
}
export interface AddWatchListResponse {
  msg: null | string;
  success: boolean;
}
export interface IsWatchResponse {
  id: string;
  account: string;
  is_watching: boolean;
}
export interface TrendingListResponse {
  data?: Collection[]
  total: number;
  msg?: null | string;
  success: boolean;
}
export interface WatchListIDListResponse {
  data: string[]
  msg: null | string;
  success: boolean;
}
export interface TrendingListRequest {
  page: number;
  pageSize: number;
  sort: string;
  period: string;
  chain: string;
  direct?: string;
}

const tokenApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    tokenList: builder.query<{

      data: Token[]
      total: number;
      msg: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos?current=${params.current}&pageSize=${params.pageSize}&sort=${params.sort}&order=${params.order}&key=${params.key}&chain=${params.chain}`,
        method: "GET",
      }),
    }),

    marketPrice: builder.query<{
      data: MarketPrice
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos/market-price?base=${params.base}&quote=${params.quote}`,
        method: "GET",
      }),
    }),

    tokenBalance: builder.query<{
      data: TokenBalance
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/cryptos/balance?token_id=${params.token_id}&account=${params.account}`,
        method: "GET",
      }),
    }),

    currencyList: builder.query<{
      data: CurrencyInfo[]
      total: number;
      message: null | string;
      success: boolean;
      

    }, Record<string, any>>({
      query: (params) => ({
        url: `/currency/${params.chain}?current=${params.current}&pageSize=${params.pageSize}${params.native?'&native='+params.native:''}`,
        method: "GET",
      }),
    }),

  }),
});

export const {
  useTokenListQuery,
  useCurrencyListQuery,
  useMarketPriceQuery,
  useTokenBalanceQuery,
} = tokenApi;
