import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { ButtonTransparent, ButtonBg } from "components/Button";
import { useWeb3React } from "@web3-react/core";
import {useToastOpen } from 'state/stats/hooks'
import { useAccount } from "state/user/hooks";
import { formatWithArray,priceText } from "utils"
import { useAppDispatch } from 'state/hooks'
import { ApplicationModal, setOpenModal } from 'state/application/reducer';
import PortfolioDrawer, { useAccountDrawer } from 'components/AccountDrawer'
import { useEffect, useState, useCallback } from "react";
import {
  TopWhite,
  PageCenter,
  InfoBtns,
  TopBannerBgNone,
} from "pages/Drop/components_";
import { useClientWidth } from "state/application/hooks";
import Image from "components/Image"
import { useCollectionListQuery } from "apis/collection";
import nftDefault from "assets/nft/nft_defult.png";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"
import { Collection, Price } from "types";

const LinearGradient = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  color: ${({ theme }) => theme.text1};
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(
    transparent,
    ${({ theme }) => theme.bg0},
    ${({ theme }) => theme.bg0}
  );
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding-bottom:10px;
  }
`;

const CollectDesc = styled.div`
  color: ${({ theme }) => theme.text2};
  margin-top: 6px;
  font-size: 18px;
  font-weight: 400;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 14px;
  }
`;
const CollectName = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-top: 6px;
  font-size: 28px;
  font-weight: 600;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 20px;
  }
`;
const FeaturedImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  // min-height:300px;
`;
const FeaturedImgBlock = styled.div`
  width: 100%;
  // min-height:300px;
`;
const AvatarImg = styled.img`
  width: 80px;
  height: 80px;
  margin: 5px 10px;
  border-radius: 50%;
  object-fit: cover;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 40px;
    height: 40px;
  }
`;
const InfoArea = styled.div`
  width: 100%;
  height: 500px;

  border-radius: 8px;
  background: ${({ theme }) => theme.bg0};

  padding: 35px;
  box-sizing: bordedr-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    padding: 30px 15px;
    height: auto;

    flex-wrap: wrap;
  }
`;
const InfoAreaL = styled.div`
  width: 50%;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
const InfoAreaR = styled.div`
  width: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.bg2};
  cursor: pointer;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export default function Home(props: any) {
  let navigate = useNavigate();
  const [bannerDrop, setBannerDrop] = useState<Collection | null>(null);
  const [mintPrice, setMintPrice] = useState<Price | null>(null);
  const [isLoad, setIsLoad] = useState(true);
  const [isError, setIsError] = useState(true);
  const [,,, setToastOpen] = useToastOpen();
  const dispatch = useAppDispatch()
  const [acc, setAccount] = useAccount();
  const [walletDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {

    toggleAccountDrawer()
  }, [toggleAccountDrawer])
  const { account } = useWeb3React();
  const { data, status } = useCollectionListQuery({
    page: 1,
    pageSize: 1,
    featured: true,
  });
  const toPage = (url: string) => {
    if (account) {
      navigate(url);
    } else {
      setToastOpen({open:true,type:'error',label:"please connect wallet"})
      handleWalletDropdownClick()
    }
  }
  // const imgerrorfun=(event)=>{
  //   if(isError){
  //    setIsError(false)
  //   }
  // }
  useEffect(() => {
    if (status === "fulfilled" && data?.success) {
      if (data?.data) {
        if(data.data[0]?.drop?.status&&data.data[0].drop.status>0){
          let arr=data.data[0].drop.settings?.stages
          let now = Date.parse(new Date().toString()) / 1000;

          arr?.forEach((el, index) => {
         
            if (el.start_time < now && el.end_time > now) {
              setMintPrice(el.sale_price)
            }
          });
        }
        setBannerDrop(data.data[0]);
      }
      setIsLoad(false);
    }
  }, [data, status]);
  const clientWidth = useClientWidth();
  return (
    <>
      {isLoad ? (
        <TopBannerBgNone style={{ width: clientWidth, height: "500px" }} />
      ) : (
        <>
          <PageCenter>
            <InfoArea>
              <InfoAreaL>
                <div>
                  <CollectName>
                    Zooker NFT: One-stop NFT trading platform
                  </CollectName>
                  <CollectDesc>
                    It supports the most complete public chain, purchases in any
                    currency, has the lowest Gas, and is a one-stop NFT platform
                    that connects Web and App
                  </CollectDesc>
                </div>
                <InfoBtns style={{ justifyContent: "flex-start" }}>
                  <ButtonBg
                    padding="10px 20px"
                    $borderRadius="8px"
                    width="auto"
                    margin="0 20px 0 0"
                    onClick={() =>
                      toPage(`/my-collection`)
                    }
                  >
                    Creators
                  </ButtonBg>

                  <ButtonTransparent
                    padding="10px 20px"
                    $borderRadius="8px"
                    width="auto"
                    onClick={() =>
                      toPage(`/account/${account}/collected`)}
                  >
                    Users
                  </ButtonTransparent>
                </InfoBtns>
              </InfoAreaL>
              {bannerDrop ? (<InfoAreaR
                onClick={() => {
                  bannerDrop?.drop?.status && bannerDrop.drop.status > 0 ? navigate(
                    `/nfts/collection/${bannerDrop?.chain}/${bannerDrop?.contract_address}/overview`
                  ) : navigate(
                    `/nfts/collection/${bannerDrop?.chain}/${bannerDrop?.contract_address}`
                  )
                }}
              >
                <FeaturedImgBlock>                <Image src={bannerDrop?.featured_image_url || nftDefault} alt="" style={{ width:'100%',objectFit: 'cover'}}/>
                </FeaturedImgBlock>
                {/* <FeaturedImg onError={(e) => { }} src={bannerDrop?.featured_image_url || nftDefault} /> */}
                <LinearGradient>
                  <div>
                    <AvatarImg src={bannerDrop?.image_url || nftDefault} />
                  </div>
                  <div>
                    <div>{bannerDrop?.name || "zooker"}</div>
                    <div style={{ fontSize: "15px", marginTop: "6px" }}>
                      { bannerDrop?.drop?.status && bannerDrop.drop.status > 0 ?(<> Mint price：
                      {mintPrice?priceText(mintPrice):''}</>):(<> Floor price：
                      {formatWithArray(bannerDrop?.stats?.floor_price)}
                      {" "}
                      {bannerDrop?.payment_tokens?.[0]?.symbol}</>)}
                     
                    </div>
                  </div>
                </LinearGradient>
              </InfoAreaR>) : null}

            </InfoArea>
          </PageCenter>
        </>
      )}
    </>
  );
}
