import styled from "styled-components/macro";

import CollectionBanner from "pages/Crypto/components/HeadBanner";
import { Box, Button, Flex, Text } from "rebass";

import Row from "components/Row";
import Column from "components/Column";

import TokenAvatar from "components/TokenAvatar";
import { Repeat } from "react-feather";
import { Page } from "components/Layout";
import { Token } from "types";




const HeadRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: nowrap;
  > div {
    text-align: left;
    width: 100%;
  };
  > div:last-child {
    text-align: right;
    width: 100%;
  }
`;
const DataRow = styled(Row)`
  justify-content: space-between;
  flex-wrap: nowrap;

  display: flex;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.border1};
  padding: 12px 0;
  margin: 8px 0;
  box-sizing: border-box;

  > div {
    text-align: left;
    width: 100%;
  };
  > div:last-child {
    text-align: right;
    width: 100%;
  }
`;
const Divider = styled.hr`
  border: 0.5px solid red;
`;

const ButtonSell=styled(Button)`
cursor: pointer;
background-color: ${({ theme }) => theme.primary1};
border-radius: 20px;
`
const ButtonBuy=styled(Button)`
cursor: pointer;
background-color: ${({ theme }) => theme.secondary1};
border-radius: 20px;
`

export default function Home() {
  const token:Token={
    chain: "ethereum",
    contract_address: "",
    image_url: "",
    name: "",
    symbol: "",
    decimals : 18
  }
  return (
    <>   
      <CollectionBanner />
      <Page>
        <Box textAlign={"left"} width={1}>
          <Text pb={1} fontSize={36}>
            Latest orders
          </Text>
        </Box>

        <HeadRow fontSize={12}>
          <Column>
          <Text>Crypto</Text>
          </Column>
          <Column>
            <Text>Quote</Text>
          </Column>
          <Column>
            <Text>Unit Price</Text>
          </Column>
          <Column>
            <Text>Operation</Text>
          </Column>
        </HeadRow>

        <DataRow>
          <Column>
          <Flex wrap="nowrap">
          <TokenAvatar size={32} token={token}></TokenAvatar>
            <Box ml={3}>
              <Text>20000000</Text>
              <Text>BNB</Text>
            </Box>
            </Flex>
          </Column>
          <Column>
          <Flex wrap="nowrap">
          <TokenAvatar size={32} token={token}></TokenAvatar>
            <Box ml={3}>
              <Text>20000</Text>
              <Text>ZKRS</Text>
            </Box>
            </Flex>
          </Column>
          <Column>
          <Flex wrap="nowrap">
          <Box>
            <Text>5000000</Text>
            <Text >ZKRS/ETH</Text>
            </Box>
           <Box > <Button><Repeat /></Button></Box>
           </Flex>
          </Column>
          <Box>
            <Text fontSize={"12px"} pb={1}>Expires in 6D 23h</Text>
            <ButtonSell width={"80px"} height={"40px"}>
              Sell
            </ButtonSell>
          </Box>
        </DataRow>
      
        <DataRow>
          <Column>
          <Flex wrap="nowrap">
          <TokenAvatar size={40}  token={token}></TokenAvatar>
            <Box ml={3}>
              <Text>20000000</Text>
              <Text>BNB</Text>
            </Box>
            </Flex>
          </Column>
          <Column>
          <Flex wrap="nowrap">
          <TokenAvatar  size={40}   token={token}></TokenAvatar>
            <Box ml={3}>
              <Text>20000</Text>
              <Text>ZKRS</Text>
            </Box>
            </Flex>
          </Column>
          <Column>
          <Flex wrap="nowrap">
          <Box>
            <Text>5000000</Text>
            <Text>ZKRS/ETH</Text>
            </Box>
           <Box > <Button><Repeat /></Button></Box>
           </Flex>
          </Column>
          <Box>
            <Text fontSize={"12px"} pb={1}>Expires in 6D 23h</Text>
            <ButtonBuy width={"80px"} height={"40px"}>
              Buy
            </ButtonBuy>
          </Box>
        </DataRow>
      </Page>
    </>
  );
}
