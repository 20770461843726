import arrowU from "assets/tool/arrow_u.png";
import arrowUn from "assets/tool/arrow_u_n.png";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { ChainInfo } from "types";

const OptionText = styled.div<{ selected: boolean }>`

  font-size: 14px;
  height: 36px;
  line-height: 36px;
  width: 100%;
  padding: 0 6px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 6px;
  cursor: pointer;
  background: ${({ selected, theme }) => (selected ? theme.bg2 : theme.bg0)};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.text3)};
`;

const DropSelect = styled.div`
  padding: 0 7px;
  height: 39px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  position: relative;
  z-index: 1000;
`;
const DropDown = styled.div<{ isopen: boolean }>`
  padding: 7px 7px;
  width: 100%;
  text-align:left;
  height: ${({ isopen }) => (isopen ? "auto" : "0")};
  overflow:${({ isopen }) => (isopen ? "visible" : "hidden")};;
  opacity: ${({ isopen }) => (isopen ? "1" : "0")};
  border-radius: 6px;
  font-size: 14px;
  background: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg2};
  position: absolute;
  left: 0;
  top: 39px;
`;
const SelectLine = styled.div`
  display: flex;
  width: 100%;
  height: 39px;
  padding: 0 6px;
  margin: 0 auto;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > div {
    white-space: nowrap;
  }
`;
const SelectIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-bottom: 5px;
`;
interface ChainSelectProps {
  size: string;
  value: string;
  optionArr:ChainInfo[];
  onChange: (value: ChainInfo) => void;
}


export default function ChainIDSelect({
  size,
  value,
  optionArr,
  onChange,
}: ChainSelectProps) {
  const [darkMode] = useDarkModeManager();
  const [openDown, setOpenDown] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string>("");
  // const optionArr = [
  //   "Goerli",
  // ];
  const chainValues = [5];

  // useEffect(() => {
  //   chainValues.forEach((e, index) => {
  //     if (e === value) {
  //       setCurrentLabel(optionArr?.[index]?.name);
  //     }
  //   });
  // }, [value]);
  useEffect(() => {
    optionArr.forEach((e, index) => {
      if (e.chain_id === value) {
        setCurrentLabel(optionArr?.[index]?.name);
      }
    });
  }, [optionArr,value]);
  return (
    <>
      <DropSelect style={size ==='filed'?{width:'100%',background:'transparent'}:size === "big" ? { height: "50px" } : {}}>
        <SelectLine
          onClick={() => setOpenDown(!openDown)}
          style={size ==='filed'?{width:'100%'}:size === "big" ? { height: "50px", lineHeight: "50px" } : {}}
        >
          <div>{currentLabel}</div>
          <SelectIcon src={darkMode ? arrowUn : arrowU} />
        </SelectLine>
        <DropDown
          isopen={openDown}
          onClick={() => {
            setOpenDown(!openDown);
          }}
          style={size ==='filed'?{width:'100%'}:size === "big" ? { top: "50px" } : {}}
        >
          {optionArr.map((e, index) => (
            <OptionText
              selected={value === e.chain_id}
              onClick={() => {
                onChange(e);
              }}
              style={size ==='filed'?{width:'calc(100% - 10px)'}: {}}
            >
              {optionArr?.[index]?.name}
            </OptionText>
          ))}
        </DropDown>
      </DropSelect>
    </>
  );
}
