import { useWeb3React } from "@web3-react/core";
import styled from "styled-components/macro";
import Image from "components/Image"

import { useAccountDrawer } from 'components/AccountDrawer'
import ABI from "abis/erc721SeaDrop.json";
import {useToastOpen } from 'state/stats/hooks'
import { ethers, BigNumber } from "ethers";
import { useSwitchChain } from 'hooks/useSwitchChain';
import {
  useErc721SeaDropContract,
  useSeaDropContract,
} from "hooks/useContract";
import { matchFileSuffixType, priceText } from "utils"
import { useGetChainQuery } from "apis/chain";

import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import {
  MintParamsStruct,
  allowListElementsBuffer,
  createLeaf,
  createMerkleTree,
} from "utils/merkletree";
import {
  DropInfoWeb,
  DropInfoWebL,
  DropInfoWebLBottom,
  DropInfoWebLImg1,
  DropInfoWebLImg4, DropInfoWebLImg4Block,
  DropInfoWebLImgL,
  DropInfoWebLImgLImg, TeamChange, FaqItem, ItemQuestion, ItemAnswer, DropInfoWebLImgLImgBlock,
  DropInfoWebLImgnone,
  DropInfoWebR,
  DropTitleWeb,
  MintItem,
  MintItemIcon,
  MintItemTextSub, TeamImg, TeamName,
  SecList
} from "pages/Nft/EditCollection/components/DropPageForm/components_";
import {
  CloseImg
} from "pages/Nft/EditCollection/components_";
import {
  WebMain
} from "pages/Nft/NftItems/components_";
import {
  DivNone
} from "pages/Nft/components_";
import {
  DropInfoWebLImg
} from "../components_";

import {
  Available,
  DescBtn,
  DescBtnText,
  DescText,
  DropIconTel,
  DropImgWeb,
  DropNameTextWeb,
  DropNameWeb,
  DropTitle,
  DropTitleLeft,
  DropTitleRight,
  MintItemBuy, MintBuyNot,
  MintItemText,
  PublicCardBottomL,
  PublicCardBottomR,
  PublicCardBottomWeb,
  PublicCardPrice,
  PublicCardWeb,
  TagItemWeb,
  TagList, MintItemIconNow, AvailableColor,
} from "pages/Drop/components_";
import { SecTitle, ArrowImg, PageBottom, BottomLine, BottomLeft, BottomRight, LeftImgBlock, DropImg, DropImg1, StageInfo, TeamItem, TeamInfo, } from "pages/Drop/DropDetail/components_";
import { LoadMintStage } from "pages/Nft/components/loadCompents"
import dropImg from "assets/nft/drop_img.png";
import arrowU from "assets/tool/arrow_icon.png";
import arrowUN from "assets/tool/arrow_iconN.png";

import arrowIcon from "assets/tool/arrow_iconb.png";
import arrowIconN from "assets/tool/arrow_iconN.png";

import nftDefault from "assets/nft/nft_defult.png";
import { useAllowlist } from "hooks/useSeadrop";
import { MIN_WIDTH_MEDIA_BREAKPOINT, LARGE_MEDIA_BREAKPOINT } from "constants/width"

import {
  useGetDropPublishMutation
} from "apis/drop";
import { ChainInfo, DropMintStats, DropSettings,  MintStage, User } from "types";
const DropInfoWebMint = styled.div`

width: 100%;
max-width: 1440px;
min-width:800px;
height: auto;
margin: 0 auto 20px;
text-align: left;
overflow: hidden;
@media screen and (min-width: 0px) and (max-width: 800px) {
  min-width:0;
  width: 100%;
}
`;
export const SecItemVideo = styled.div<{ even: boolean }>`
  width: 100%;
  display: flex;
  
  flex-direction:${({ even }) => even ? 'row-reverse' : 'row'}; ;
  align-items: center;
  justify-content: space-between;
  padding:60px;
  box-sizing:border-box;
  background: ${({ theme, even }) => even ? theme.bg1 : theme.bg2};

  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    flex-wrap: wrap;
    padding:20px 2.5%;
  }
  > div {
    width: calc(50% - 10px);
    
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      width: 100%;
    }
  }
`;
export const SecVideo = styled.video`
  width: calc(50% - 10px);
  border-radius: 10px;
  max-height: 500px;
  object-fit: cover;
  autoplay:true;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
  }
`;
const SecItemImg = styled.div`
  width: 100%;
  height:500px;
  padding:20px;
  overflow-y: hidden;
  margin-bottom:3px;
  color: ${({ theme }) => theme.bg0};
  > div {
    width: calc(50% - 10px);
    height:460px;
   
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      width: 100%;
    }
  }
`;
const SecDesc = styled.div`
  height:380px;
  width: 80%;
  text-align: left;
  margin: 10px auto 0;

  overflow-y: auto;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    font-size:12px;
  }
`;
const SecVideDesc = styled.div`
  height:380px;
  width: 80%;
  text-align: left;
  margin: 10px auto 0;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    height:auto;
    width: 100%;
    font-size:12px;
  }
`;
export interface DropMint {

  chainInfo: ChainInfo;
  desc?: string;
  chain?: string;
  edition?: string;
  token_standard?: string;
  secList?: {
    description: string;
    image_url: string;
    title: string;
  }[];
  user?: User;
  imgListUrl?: { img: string }[];
  settings?: DropSettings;
}

interface DropMintFormProps {
  visible: boolean;

}


interface MintParams {
  mintPrice: BigNumber;
  maxTotalMintableByWallet: number;
  startTime: number;
  endTime: number;
  dropStageIndex: number;
  maxTokenSupplyForStage: number;
  feeBps: number;
  restrictFeeRecipients: boolean;
}
const defaultSetting: DropSettings = {
  max_supply: 0,
  edition: '',
  mint_start_time: 0,
  stages: []
};
export default function DropMintForm({ visible }: DropMintFormProps) {
  const [darkMode] = useDarkModeManager();
  const [,,, setToastOpen] = useToastOpen();
  const { chain, contract_address } = useParams();
  const switchChain = useSwitchChain()
  const { provider, chainId, account, connector } = useWeb3React();
  const [scrollHeight, setScrollHeight] = useState(0);
  const [chainCurrency, setChainCurrency] = useState<ChainInfo | null>();

  const [walletDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const handleWalletDropdownClick = useCallback(() => {

    toggleAccountDrawer()
  }, [toggleAccountDrawer])
  const [settings, setSettings] = useState<DropSettings>(defaultSetting);
  const [tokenStandard, setTokenStandard] = useState<string>('');
  const [desc, setDesc] = useState<string>('');

  const [isLoad, setIsLoad] = useState(true);
  const [secList, setSecList] = useState<{
    description: string;
    image_url: string;
    title: string;
  }[]>();

  const [getDropPublish] = useGetDropPublishMutation();
  const [imagesIndex, setImagesIndex] = useState(0);
  const [selectImage, setSelectImage] = useState('');
  const [mintStages, setMintStages] = useState<MintStage[]>([]);
  const [mintStats, setMintStats] = useState<DropMintStats>();
  const [currentMintStage, setCurrentMintStage] = useState<MintStage | null>();
  const [currentParams, setCurrentParams] = useState<MintParams>();
  const [dropStatus, setDropStatus] = useState(0); //0未开始 1进行中 2已结束
  const [teamList, setTeamList] = useState<{ bio: string; image_url: string; name: string; title: string; }[]>([]);
  const [mapList, setMapList] = useState<{ description: string; image_url: string; name: string; date: string; }[]>([]);
  const [questList, setQuestList] = useState<{ answer: string; question: string; showAnswer?: boolean; }[]>([]);

  const [imgListUrl, setImgListUrl] = useState<string[]>([]);
  const [mintNum, setMintNum] = useState(1);
  const [showDesc, setShowDesc] = useState(false);
  const [mintText, setMintText] = useState("Mint");


  const seadrop = useSeaDropContract(chainCurrency?.seadrop_impl as string, true);
  const seadropErc721 = useErc721SeaDropContract(contract_address, ABI, true);
  const allowlist = useAllowlist(
    settings,
    1000,
    chain as string,
    contract_address as string
  );
  const currencydata = useGetChainQuery({
    chain: chain as string,
  });
  const getDropPublishInfo = async () => {
    let paramdata = {
      chain: chain as string,
      address: contract_address as string
    };
    const { success, message, data } = await getDropPublish(paramdata).unwrap();
    if (success) {


      if (data && data.drop_page) {
        let str = ''
        let previewList = data.drop_page.preview_image_urls?.map((el: string) => {
          return { img: el }
        })
        if (data?.settings) {
          setSettings(data?.settings)
        }
        data.drop_page.roadmaps && setMapList(data.drop_page.roadmaps)
        data.drop_page.team && setTeamList(data.drop_page.team)
        previewList && setImgListUrl(data.drop_page.preview_image_urls)
        setTokenStandard(data?.token_standard || '')
        setDesc(data?.drop_page?.description || '')
        setSecList(data?.drop_page?.sections)
        let list = questList.map(el => {
          return { answer: el.answer, question: el.question }
        })
        if (data.drop_page.faqs && data.drop_page.faqs !== list) {
          let templist = data.drop_page.faqs.map(el => {
            return { answer: el.answer, question: el.question, showAnswer: false }
          })
          setQuestList(templist)
        }
      }
    } else {
      setToastOpen({open:true,type:'error',label:message})

    }
  };
  useEffect(() => {
    if (currencydata && currencydata.data?.success) {
      if (currencydata?.data?.data) {
        setChainCurrency(currencydata?.data.data);
        console.log('currencydata', currencydata)
      }
    }
  }, [currencydata]);
  useEffect(() => {
    getDropPublishInfo()
  }, []);
  useEffect(() => {
    console.log('aaaaaaaaaa',imagesIndex)
    console.log('imgListUrl[imagesIndex]',imgListUrl[imagesIndex])
    // setSelectImage(imgListUrl[imagesIndex].img)
    // console.log('SelectImage',selectImage)

  }, [imagesIndex,imgListUrl]);
  useEffect(() => {
    const fetchMintStats = async () => {
      if (account && seadropErc721) {
        try {
          const data = (await seadropErc721.getMintStats(
            account
          )) as DropMintStats;
          console.log("getMintStats:", data);
          setMintStats(data);
        } catch (err) {
          setToastOpen({open:true,type:'error',label:(err as Error).message})

          console.log("errorerror:", err);
        }

      }

    };
    fetchMintStats();
  }, [account, seadropErc721]);

  useEffect(() => {
    if (dropStatus === 0) {
      let str = new Date(settings.mint_start_time * 1000).toLocaleDateString()
      setMintText('Mint start at ' + str)
    }
    if (dropStatus === 1) {
      setMintText('Mint')
    }
    if (dropStatus === 2) {
      setMintText('Mint End')
    }

  }, [dropStatus]);

  const editShow = (index: number) => {
    let list = [...questList]
    if (list[index].showAnswer) {
      list[index].showAnswer = false
    } else {
      list[index].showAnswer = true
    }
    setQuestList(list)
  }



  const chooseShow = (index: number) => {
    let templist = [...mintStages]
    templist.forEach((el, stageindex) => {
      if (stageindex == index) {
        el.showPrice = !el.showPrice
      }
    })
    setMintStages(templist)
  }
  const mintAllowlist = async () => {
    if (!account) {
      setToastOpen({open:true,type:'error',label:"please connect wallet"})

      return;
    }
    if (!contract_address) {
      setToastOpen({open:true,type:'error',label: "contract address is null"})

      return;
    }
    if (currentParams == null) {
      setToastOpen({open:true,type:'error',label: "You're not eligible for this mint stage"})

      return
    }
    const now = (new Date().getTime()) / 1000
    if (currentParams.startTime > now) {
      console.log("currentParams.startTime:", (new Date((currentParams.startTime * 1000)).toLocaleDateString()))
      setToastOpen({open:true,type:'error',label:"mint stage not start"})
      return
    }
    if (currentParams.endTime > 0 && currentParams.endTime < now) {
      setToastOpen({open:true,type:'error',label:"mint stage end"})

      return
    }
    if (!currentMintStage) {
      setToastOpen({open:true,type:'error',label:"mint stage error"})

      return
    }

    if (!(currentMintStage as MintStage).qualified) {
      setToastOpen({open:true,type:'error',label:"You're not eligible for this mint stage"})

      return
    }

    setMintText("Mint Loading...");
    const balance = await provider?.getBalance(account);

    let total_price = ethers.BigNumber.from(currentParams.mintPrice).mul(mintNum);

    if (balance && total_price.gt(balance)) {
      setToastOpen({open:true,type:'error',label:"余额不足"})

      return;
    }

    console.log("total_price:", total_price.toString());
 
    try {
      const elementsBuffer = await allowListElementsBuffer(allowlist);
      const merkleTree = createMerkleTree(elementsBuffer);
      const root = merkleTree.getHexRoot()
      const chain_root = await seadrop.getAllowListMerkleRoot(contract_address as string)
      if (root.toLowerCase() !== chain_root.toLowerCase()) {
        setToastOpen({open:true,type:'error',label:"merkle root error"})

        return
      }
  
  
      const leafStr = createLeaf(account, currentParams)
      const proof = merkleTree.getHexProof(leafStr);
      // console.log('contract_address', contract_address,'account', account,'quantity', mintNum,'currentParams', currentParams,'proof', proof)
      const feeRecipients = await seadrop.getAllowedFeeRecipients(contract_address)

      const res = await seadrop.mintAllowList(
        contract_address,
        feeRecipients[0],
        account,
        mintNum,
        currentParams,
        proof,
        { value: total_price }
      );
      if (res) {
        setMintText("Mint");
      }
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message})

      console.log('error', err)
      setMintText("Mint");
    }

  }
  const isChainTrue = async () => {
    if (chainId && chain && chainCurrency && (Number(chainCurrency.chain_id) === chainId)) {

      return true
    } else {
      if (chainId && chain && chainCurrency) {
        setToastOpen({open:true,type:'error',label: "Plase Switch chain"})

        let newchain = -1
        newchain = Number(chainCurrency.chain_id)

        if (newchain !== -1) {
          try {
            await switchChain(connector, newchain, chainCurrency)
            return false
          } catch (error) {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }

    }
  }
  const mint = async () => {
    if (!account) {
      setToastOpen({open:true,type:'error',label:"please connect wallet" })

      handleWalletDropdownClick()
      return;
    }
    if (!await isChainTrue()) {
      // mes.open({ type: "error", content: "chain error" });
      return;
    }

    if (mintNum < 1) {
      setToastOpen({open:true,type:'error',label:"mint number error" })

      return;
    }

    if (dropStatus === 0) {
      setToastOpen({open:true,type:'error',label:"mint not start" })
      return;
    }
    if (dropStatus === 2) {
      setToastOpen({open:true,type:'error',label:"mint end" })
      return;
    }
    if (mintText === 'Mint Loading...') {
      setToastOpen({open:true,type:'error',label:"Loading..." })

      return;
    }
    if (currentMintStage && contract_address) {
      console.log("currentMintStage", currentMintStage)
      if (currentParams && currentParams.maxTotalMintableByWallet > 0) {
        var limit =
          currentParams.maxTotalMintableByWallet -
          mintStats?.minterNumMinted.toNumber();
        if (mintNum > limit) {
          setToastOpen({open:true,type:'error',label: "you have " + limit + " left" })

          return;
        }
      }
      const balance = await provider?.getBalance(account)
      if (currentMintStage.is_public) {
        const price = Number(currentMintStage?.sale_price.value);
        let price_wei = BigNumber.from(price.toString());
        let total_price = price_wei.mul(mintNum);
        if (balance && total_price.gt(balance)) {
          setToastOpen({open:true,type:'error',label: "余额不足 " })

          return;
        }
        setMintText("Mint Loading...");
        try {
          let publicdrop = await seadrop.getPublicDrop(contract_address)
          console.log('publicdrop', publicdrop)
          const feeRecipients = await seadrop.getAllowedFeeRecipients(contract_address)

          let res = await seadrop.mintPublic(
            contract_address,
            feeRecipients[0],
            account,
            mintNum,
            { value: total_price }
          );
          if (res) {
            setMintText("Mint");
          }
        } catch (err) {
          setToastOpen({open:true,type:'error',label: (err as Error).message })

          console.log('error', err)
          setMintText("Mint");
        }

      } else {
        console.log("start mint allowlist")
        await mintAllowlist()

      }


    }
  };

  const changeNum = (str: string) => {
    if (currentMintStage && mintStats && settings) {
      if (str === "+1") {
        if (settings.edition === "limited") {
          if (settings.max_supply && mintNum < settings.max_supply) {
            if (currentMintStage.per_wallet_mint_limit - mintNum - mintStats.minterNumMinted > 0) {
              setMintNum(mintNum + 1);
            }
          }
        } else {
          setMintNum(mintNum + 1);
        }
      } else {
        if (mintNum > 1) {
          setMintNum(mintNum - 1);
        }
      }
    }
  };

  useEffect(() => {
    if (settings) {
      const now = Date.parse(new Date().toString()) / 1000;
      console.log('now', now, settings.stages)
      var currentIndex = -1
      const newmintStage: MintStage[] = []
      const stageLength = settings.stages?.length || 0
      settings.stages?.forEach((el, index) => {
        const newStage: MintStage = { ...el, qualified: false, showPrice: false }
        if (el.is_public) {
          newStage.qualified = true
        }
        if (el.start_time < now && el.end_time > now) {
          currentIndex = index
          setDropStatus(1);
          for (var i = 0; i < allowlist.length; i++) {
            let allow = allowlist[i]
            const address = allow[0]
            const mintparams = allow[1]
            if (address === account && mintparams.dropStageIndex === (stageLength - index - 1)) {
              newStage.qualified = true
              setCurrentParams(mintparams)
            }
          }
        }
        newmintStage.push(newStage)
      });

      if (newmintStage?.[newmintStage.length - 1]?.end_time <= now && currentIndex === -1) {//已结束
        currentIndex = newmintStage.length - 1
        setDropStatus(2)
      }
      setMintStages(newmintStage)
      setCurrentMintStage(newmintStage[currentIndex])
      console.log('newmintStagenewmintStagenewmintStage', newmintStage)
      console.log('currentIndexcurrentIndexcurrentIndex', currentIndex)
      setIsLoad(false)
    }



  }, [settings, allowlist, account]);


  return (
    <>
      <DropInfoWebMint>
        {isLoad ? (<WebMain style={{ paddingBottom: '20px', minHeight: '0' }}>
          <DropInfoWeb isShow={visible} style={{ flexWrap: "wrap" }}>
            <DropInfoWebL>
              <DropInfoWebLImg1>
                <DropInfoWebLImgnone src={nftDefault} />
                <DropInfoWebLImgL>
                  <DropInfoWebLImg4 src={nftDefault} />
                </DropInfoWebLImgL>
              </DropInfoWebLImg1>

              <DropInfoWebLBottom>
                <DropInfoWebLImg4 src={nftDefault} /><DropInfoWebLImg4 src={nftDefault} /><DropInfoWebLImg4 src={nftDefault} /><DropInfoWebLImg4 src={nftDefault} />
              </DropInfoWebLBottom>
            </DropInfoWebL>
            <DropInfoWebR>
              <DropNameWeb>
                <DropImgWeb src={nftDefault} />
                <DropNameTextWeb><DivNone>{'     '}
                </DivNone></DropNameTextWeb>
              </DropNameWeb>
              <TagList><TagItemWeb><DivNone>{'     '}
              </DivNone></TagItemWeb>

                <TagItemWeb><DivNone>{'     '}
                </DivNone></TagItemWeb>
              </TagList>
              <DescText isShow={true}>
                <DivNone>{'                                        '}
                </DivNone>
              </DescText>

              <DropTitle style={{ marginBottom: "15px" }}>
                <DropTitleLeft>
                  Available items{" "}

                </DropTitleLeft>
                <DropTitleRight>
                  <DivNone>{'     '}
                  </DivNone>
                </DropTitleRight>
              </DropTitle>
              <Available>
              </Available>
              <LoadMintStage />

              <DropTitleWeb>Mint schedule</DropTitleWeb>

              <MintItem >
                <MintItemIcon></MintItemIcon>

                <MintItemText>
                  <div><DivNone>{'     '}
                  </DivNone></div>
                  <MintItemBuy >
                    <DivNone>{'     '}
                    </DivNone>
                    <ArrowImg src={darkMode ? arrowIconN : arrowIcon} />
                  </MintItemBuy>
                </MintItemText>
                <MintItemTextSub>
                  <DivNone>{'         '}
                  </DivNone>
                </MintItemTextSub>

              </MintItem>
              <MintItem>
                <MintItemIcon></MintItemIcon>

                <MintItemText>
                  <div><DivNone>{'     '}
                  </DivNone></div>
                  <MintItemBuy >
                    <DivNone>{'     '}
                    </DivNone>
                    <ArrowImg src={darkMode ? arrowIconN : arrowIcon} />
                  </MintItemBuy>
                </MintItemText>
                <MintItemTextSub>
                  <DivNone>{'         '}
                  </DivNone>
                </MintItemTextSub>

              </MintItem>
            </DropInfoWebR>
          </DropInfoWeb>
        </WebMain>) : (<WebMain style={{ paddingBottom: '20px', minHeight: '0' }}>
          <DropInfoWeb isShow={visible} style={{ flexWrap: "wrap" }}>
            <DropInfoWebL>
              {imgListUrl[imagesIndex]? (
                <DropInfoWebLImg1>
                  <DropInfoWebLImg src={imgListUrl[imagesIndex]} alt=""/>
                </DropInfoWebLImg1>
              ) : (
                <DropInfoWebLImg1>
                  <DropInfoWebLImgnone src={dropImg} />
                  <DropInfoWebLImgL>
                    <DropInfoWebLImg4 src={nftDefault} />
                  </DropInfoWebLImgL>
                </DropInfoWebLImg1>
              )}

              <DropInfoWebLBottom>
                {imgListUrl?.map((i:string , index: number) => (

                  i ? <DropInfoWebLImg4Block onClick={() => {setImagesIndex(index)}}>
                    <Image src={i} alt="" style={{ width: '100%', objectFit: 'cover' }} />

                  </DropInfoWebLImg4Block> : null
                ))}
              </DropInfoWebLBottom>
            </DropInfoWebL>
            <DropInfoWebR>

              <TagList>
                {chain ? <TagItemWeb>{chain}</TagItemWeb> : null}
                {tokenStandard ? (
                  <TagItemWeb>{tokenStandard}</TagItemWeb>
                ) : null}
              </TagList>
              <DescText isShow={true}>
                {showDesc ? desc : desc.length > 70 ? desc.slice(0, 70) + "..." : desc}
              </DescText>
              {desc && desc.length > 70 ? (
                <DescBtn onClick={() => setShowDesc(!showDesc)}>
                  <DescBtnText>See {showDesc ? "Less" : "More"}</DescBtnText>
                  <DropIconTel
                    src={darkMode ? arrowUN : arrowU}
                    style={
                      showDesc
                        ? { transform: "rotate(180deg)", marginTop: "2px" }
                        : {}
                    }
                  />
                </DescBtn>
              ) : null}
              {settings.edition === "limited" ? (
                <>
                  <DropTitle style={{ marginBottom: "15px" }}>
                    <DropTitleLeft>
                      Available items{" "}
                      {mintStats &&
                        mintStats.maxSupply.toNumber() -
                        mintStats.currentTotalSupply.toNumber()}
                    </DropTitleLeft>
                    <DropTitleRight>
                      {mintStats?.maxSupply.toNumber()}
                    </DropTitleRight>
                  </DropTitle>
                  <Available>
                    {mintStats && mintStats.maxSupply ? (<AvailableColor style={{ width: (mintStats.currentTotalSupply.toNumber() * 100 / mintStats.maxSupply.toNumber()) + '%' }}></AvailableColor>) : null}

                  </Available>
                </>
              ) : null}
              {currentMintStage || dropStatus === 0 ? (<PublicCardWeb>
                <DropTitleWeb>{currentMintStage?.name}</DropTitleWeb>
                {currentMintStage && currentMintStage.per_wallet_mint_limit > 0 ? <div>Limit {currentMintStage.per_wallet_mint_limit} per wallet</div> : null}

                <PublicCardPrice>
                  {currentMintStage?.sale_price ? priceText(currentMintStage?.sale_price) : 0}


                </PublicCardPrice>
                <PublicCardBottomWeb>
                  {dropStatus === 1 ? (<PublicCardBottomL>
                    <div onClick={() => changeNum("-1")}>-</div>
                    <div>{mintNum}</div>
                    <div onClick={() => changeNum("+1")}>+</div>
                  </PublicCardBottomL>) : null}


                  <PublicCardBottomR
                    style={{ lineHeight: "56px" }}
                    onClick={() => {
                      mint();
                    }}
                  >
                    {mintText}
                  </PublicCardBottomR>

                </PublicCardBottomWeb>
              </PublicCardWeb>) : <LoadMintStage />}




              <DropTitleWeb>Mint schedule</DropTitleWeb>

              {mintStages?.map((i, index) => (
                <MintItem key={index}>
                  {i.start_time < Date.parse(new Date().toString()) / 1000 && i.end_time > Date.parse(new Date().toString()) / 1000 ? (<MintItemIconNow></MintItemIconNow>) : (<MintItemIcon></MintItemIcon>)}

                  <MintItemText>
                    <div>{i.name}</div>
                    <MintItemBuy onClick={() => chooseShow(index)}>
                      {i.qualified ? <>Eligible</> : <MintBuyNot>Not eligible</MintBuyNot>}
                      <ArrowImg style={i.showPrice ? { transform: ' rotate(0deg)' } : {}} src={darkMode ? arrowIconN : arrowIcon} />
                    </MintItemBuy>
                  </MintItemText>
                  <MintItemTextSub>
                    {new Date(
                      i?.start_time ? Number(i.start_time) * 1000 : ""
                    ).toString()}
                  </MintItemTextSub>
                  {i.showPrice ? (<MintItemTextSub>
                    {priceText(i?.sale_price)}
                    {i.per_wallet_mint_limit > 0 ? <div>Limit {i.per_wallet_mint_limit} per wallet</div> : null}

                  </MintItemTextSub>) : null}

                </MintItem>
              ))}
            </DropInfoWebR>
          </DropInfoWeb>
        </WebMain>)}

        <SecList isShow={visible}>
          {secList?.map((i, index: number) => (
            matchFileSuffixType(i.image_url) === 'image' ? (<SecItemImg key={index} style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url(${i.image_url})` }}>
              <div>
                <SecTitle>{i.title}</SecTitle>
                <SecDesc>{i.description}</SecDesc>
              </div>
              {/* <SecImg src={i.image_url} style={{opacity:'0'}}/> */}
            </SecItemImg>) : (
              <SecItemVideo key={index} even={index % 2 == 1}>

                <SecVideo muted controls loop autoPlay>
                  <source src={i.image_url} />
                </SecVideo>
                <div>
                  <SecTitle>{i.title}</SecTitle>
                  <SecVideDesc>{i.description}</SecVideDesc>
                </div>
              </SecItemVideo>
            )
          )
          )}
        </SecList>
      </DropInfoWebMint>
      <WebMain style={{ minHeight: '0' }}>
        {teamList && teamList.length > 0 ? (<DropTitleWeb>Team</DropTitleWeb>) : null}

        <DropInfoWeb isShow={visible} style={{ flexWrap: 'wrap', flexDirection: 'row-reverse', paddingTop: '0' }}>
          {teamList?.map((i: { bio: string; image_url: string; name: string; title: string; }, index: number) => (

            <TeamItem key={index}>
              <TeamImg src={i.image_url} />
              <TeamInfo>
                <TeamName>{i.name}</TeamName>
                <div>{i.title}</div>
                <div>{i.bio}</div>
              </TeamInfo>
            </TeamItem>

          ))} </DropInfoWeb>
        {mapList && mapList.length > 0 ? (<DropTitleWeb>Roadmap</DropTitleWeb>) : null}

        <DropInfoWeb isShow={visible} style={{ flexWrap: 'wrap', flexDirection: 'row-reverse', paddingTop: '0' }}>
          {mapList?.map((i: { description: string; image_url: string; name: string; date: string; }, index: number) => (

            <TeamItem key={index}>
              <TeamImg src={i.image_url} style={{ borderRadius: '8px' }} />
              <TeamInfo>
                <TeamName>{i.name}</TeamName>
                <div>{i.date ? i.date.split('T')[0] + ' ' + i.date.split('T')[1].split('Z')[0] : ''}</div>
                <div>{i.description}</div>
              </TeamInfo>
            </TeamItem>

          ))}
        </DropInfoWeb>
        {questList && questList.length > 0 ? (<DropTitleWeb>FAQ</DropTitleWeb>) : null}

        <DropInfoWeb isShow={visible} style={{ flexDirection: 'column', paddingTop: '0', alignItems: 'flex-end' }}>
          {questList?.map((i: { answer: string; question: string; showAnswer?: boolean; }, index: number) => (

            <FaqItem key={index}>
              <ItemQuestion>{i.question}</ItemQuestion>
              {i?.showAnswer ? <ItemAnswer >{i.answer}</ItemAnswer> : null}

              <TeamChange>



                {i?.showAnswer ? (<CloseImg onClick={() => { editShow(index) }}
                  style={{ transform: 'rotate(180deg)' }}
                  src={darkMode ? arrowIconN : arrowIcon}
                />) : (<CloseImg onClick={() => { editShow(index) }}
                  src={darkMode ? arrowIconN : arrowIcon}
                />)}

                {/* Edit */}
              </TeamChange>
            </FaqItem>

          ))}
        </DropInfoWeb></WebMain>

      {dropStatus === 1 && scrollHeight > 50 && (currentParams || currentMintStage?.is_public) ? (<PageBottom style={{ zIndex: '9999' }}>
        <BottomLine>
          <BottomLeft>
            <LeftImgBlock> <DropImg1 src={imgListUrl[0]} /><DropImg src={imgListUrl[0]} />
            </LeftImgBlock>
            <StageInfo> <div>{currentMintStage?.name}</div>
              <div>
                {currentMintStage?.sale_price?.value || "0"}{" "}
                {currentMintStage?.sale_price?.currency?.symbol || "none"}
              </div></StageInfo>
          </BottomLeft>
          <BottomRight>
            <PublicCardBottomL >
              <div onClick={() => changeNum("-1")}>-</div>
              <div>{mintNum}</div>
              <div onClick={() => changeNum("+1")}>+</div>
            </PublicCardBottomL>
            <PublicCardBottomR
              style={{ lineHeight: "56px" }}
              onClick={() => {
                mint();
              }}
            >
              {mintText}
            </PublicCardBottomR>
          </BottomRight>

        </BottomLine>
      </PageBottom>) : null}
    </>
  );
}
