import { useParams } from "react-router-dom";

import { Input, Radio } from "@rebass/forms";

import "pages/index.scss";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { v1 as uuidv1 } from "uuid";
import {
  CardBox,
  CardItem,
  CardMain,
  CardTitle,
  ItemLabel,
  ItemRadio,
  ItemRadioOne,
  ItemTip,
  ItemValue,
  inputStyleNum,
  styleRadio
} from "../../components_";

import { useGetChainQuery } from "apis/chain";
import { useUpdateAllowListMutation } from "apis/file";
import MintStageInput from "components/MintStageInput";
import MintStageForm from "components/MintStageInput/form";
import styled from "styled-components";
import { Currency, DropSettings,  MintStage } from "types";

const PopupDiv = styled.div<{ isShow: boolean }>`
  width: ${({ isShow }) => (isShow ? "100vw" : "0")};
  height: ${({ isShow }) => (isShow ? "100vh" : "0")};
  overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  background: ${({ theme }) => theme.modalBG2};
  position: fixed;
  top: 0;
  left: 0;
`;

const defaultToken: Currency = {
  chain: "",
  contract_address: "",
  decimals: 0,
  image_url: "",
  name: "",
  symbol: "",
}
const defaultPublicStage: MintStage = {
  allowlist_file: '',
  is_public: true,
  duration: 0,
  start_time: 0,
  end_time: 0,
  name: "Public",
  per_wallet_mint_limit: 0,
  sale_price: {
    currency: {
      chain: "",
      contract_address: "",
      decimals: 0,
      image_url: "",
      name: "",
      symbol: "",
    },
    value: "",
  },
};
const defaultSettings: DropSettings = {
  edition: "limited",
  max_supply: 0,
  stages: [],
  mint_start_time: new Date().getTime() / 1000,
};

interface SettingsFormProps {
  visible: boolean;
  value?: DropSettings;
  onChange: (value: DropSettings) => void;
}

export default function SettingsForm({
  visible,
  value,
  onChange,
}: SettingsFormProps) {
  const [darkMode] = useDarkModeManager();
  const [updateAllowList] = useUpdateAllowListMutation();
  const { chain, contract_address } = useParams();
  const [isAdd, setIsAdd] = useState(true);
  const [showUploadAllowAlert, setShowUploadAllowAlert] = useState(false);
  const [settings, setSettings] = useState<DropSettings>(defaultSettings);
  const [currentStageIndex, setCurrentStageIndex] = useState<number | null>(null);
  const [currentStage, setCurrentStage] = useState<MintStage | null>();
  const [currency, setCurrency] = useState<Currency>(defaultToken);
  const [isShowMintStageForm, setIsShowMintStageForm] = useState(false);
  const { data } = useGetChainQuery({ chain: chain as string });
  useEffect(() => {
    if (value) {
      setSettings(value);
      var show = false;
      value?.stages?.forEach((stage, index) => {
        console.log("stage", stage);
        if (!stage.is_public && stage.allowlist_file === "") {
          show = true;
        }
      });
      setShowUploadAllowAlert(show);
    }

  }, [value]);


  useEffect(() => {

    if (data?.success) {
      setCurrency(data.data.currency)
    }
  }, [data]);



  const handleDelete = (index: number) => {
    const newStages = [...settings.stages]
    newStages.splice(index, 1)
    console.log("new stages", newStages)
    const _tempStages = reCalculateStageTime(newStages)
    onChange({ ...settings, stages: _tempStages });
  };

  const ondataChange = (val: Date | null) => {
    if (value && val) {
      var newValue = { ...value };
      console.log(' Date.parse(val.toString()) / 1000;', val)
      // console.log('.val',Date.parse(val.toString()))

      // let day = new Date(val.toString());
      newValue.mint_start_time = Date.parse(val.toString()) / 1000;
      onChange(newValue);
    }
  };


  const showAddStage = () => {

    const newPresale: MintStage = {
      allowlist_file: "",
      duration: 0,
      start_time: settings?.stages[-1]?.start_time || 0,
      end_time: 0,
      is_public: false,
      name: "Presale" + ((settings?.stages?.length || 0) + 1),
      per_wallet_mint_limit: 0,
      sale_price: {
        currency: currency,
        value: "0",
      },
    };
    setCurrentStage(newPresale);
    setIsShowMintStageForm(true);
  };

  const reCalculateStageTime = (stages: MintStage[]) => {

    let startTime = settings.mint_start_time;
    let length = stages.length;
    const tempStages = stages.reverse();
    const newStages: MintStage[] = [];
    for (var i = 0; i < length; i++) {
      if (i === 0) {
        let start_time = startTime
        const end_time = startTime + tempStages[i].duration * 60
        newStages.push({ ...tempStages[i], end_time, start_time })
      } else {
        const end_time = newStages[i - 1].start_time
        const start_time = end_time - tempStages[i].duration * 60
        newStages.push({ ...tempStages[i], end_time, start_time })
      }
    }
    return newStages.reverse()
  }

  const openEdit = (index:number,stage:MintStage) => {
    if(stage.sale_price.value===''&&stage.sale_price.currency.decimals===0){
      stage.sale_price.currency=currency
    }
    setIsAdd(false)
    setCurrentStageIndex(index);
    setCurrentStage(stage);
    setIsShowMintStageForm(true);
  }
  const handleAddStage = (val: MintStage) => {
    setIsShowMintStageForm(false);
    const newStages = settings.stages.slice(0, settings.stages.length - 1)

    console.log("new stages", newStages)
    console.log("val", val)
    let num = Number(val.sale_price.value) * 10 ** val.sale_price.currency.decimals
    val.sale_price.value = String(num)

    newStages.push(val)
    newStages.push(settings.stages[settings.stages.length - 1])
    console.log("new stages", newStages)
    const _tempStages = reCalculateStageTime(newStages)
    onChange({ ...settings, stages: _tempStages });
  }
  const handleEditStage = (val: MintStage) => {
    setIsShowMintStageForm(false);
    console.log("val", val)
    let num = Number(val.sale_price.value) * 10 ** val.sale_price.currency.decimals
    val.sale_price.value = String(num)
    console.log("num", num)
    console.log("val", val)
    const newStages = [...settings.stages]
    newStages[currentStageIndex as number] = val
    console.log("new stages", newStages)
    const _tempStages = reCalculateStageTime(newStages)
    onChange({ ...settings, stages: _tempStages });
  }

  return (
    <>
      {" "}
      <CardBox isAct={visible}>
        <CardMain>
          <CardTitle>Drop Settings</CardTitle>
          <CardItem>
            <ItemRadio>
              <ItemRadioOne
                onClick={() => onChange({ ...settings, edition: "limited" })}
              >
                <div>
                  <ItemLabel>Limited Edition</ItemLabel>
                  <ItemTip>A limited number of items</ItemTip>
                </div>
                <div className="radio-r">
                  <Radio
                    checked={value?.edition === "limited"}
                    sx={styleRadio}
                    readOnly
                  />
                </div>
              </ItemRadioOne>
              <ItemRadioOne
                style={{ border: "none" }}
                onClick={() => onChange({ ...settings, edition: "open" })}
              >
                <div>
                  <ItemLabel>Open Edition</ItemLabel>
                  <ItemTip>An unlimited number of items</ItemTip>
                </div>
                <div className="radio-r">
                  <Radio
                    checked={value?.edition === "open"}
                    sx={styleRadio}
                    readOnly
                  />
                </div>
              </ItemRadioOne>
            </ItemRadio>
          </CardItem>
          <CardItem>
            <ItemLabel>Mint Start Date & Time</ItemLabel>
            <ItemValue
              className={darkMode ? "date-picker-divw" : "date-picker-div"}
            >
              <DatePicker
                selected={new Date(settings.mint_start_time * 1000)}
                onChange={(date) => ondataChange(date)}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                className={darkMode ? "date-picker-w" : "date-picker"}
                showTimeInput
              />
            </ItemValue>
          </CardItem>
          {value?.edition === "limited" ? (
            <CardItem>
              <ItemLabel>Number of items</ItemLabel>
              <ItemValue>
                <Input
                  type="number"
                  placeholder="Example:1000"
                  value={value.max_supply}
                  sx={inputStyleNum}
                  onChange={(e) => {
                    onChange({
                      ...settings,
                      max_supply: Number(e.target.value),
                    });
                  }}
                />

              </ItemValue>
            </CardItem>
          ) : null}

          <CardItem>
            <ItemLabel>Mint Stages</ItemLabel>

            <ItemLabel
              style={{ color: "#6a5bda", cursor: "pointer" }}
              onClick={() => { setIsAdd(true); showAddStage() }}
            >
              + Add a presale
            </ItemLabel>

            {showUploadAllowAlert ? (
              <>
                {" "}
                <ItemLabel>
                  Don't forget to add allowlists for your presales！
                </ItemLabel>
                <ItemTip>
                  Add an allowlist before minting starts so only specific
                  walletaddresses are allowed to mint during a presale.
                </ItemTip>
              </>
            ) : null}
          </CardItem>
          {settings?.stages?.map((stage, index) => (
            <MintStageInput
              key={stage.name}
              size={""}
              chain={chain || ""}
              contract_address={contract_address || ""}
              value={stage}
              onChange={function (value: MintStage): void { }}
              onDel={() => { handleDelete(index) }}
              onEdit={() => {
                openEdit(index, stage)

              }}
            />
          ))}
        </CardMain>
      </CardBox>
      <PopupDiv isShow={isShowMintStageForm}>
        <MintStageForm
          value={currentStage || defaultPublicStage}
          chain={chain || ""}
          contract_address={contract_address || ""}
          isAdd={isAdd}
          onEdit={handleEditStage}
          onAdd={(val: MintStage) => {
            setIsShowMintStageForm(false);
            handleAddStage(val)
          }}
          onClose={() => {
            setCurrentStageIndex(null);
            setCurrentStage(null);
            setIsShowMintStageForm(false);
          }}
        />
      </PopupDiv>
    </>
  );
}
