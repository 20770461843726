import { Page } from "components/Layout";
import { useEffect, useState } from "react";
import { Box, Flex, Text } from "rebass";

import { Seaport } from "@opensea/seaport-js";
import { useWeb3React } from "@web3-react/core";
import { useTokenBalanceQuery } from "apis/token";
import { useAccountDrawer } from "components/AccountDrawer";
import { BaseButton, ButtonPrimary } from "components/Button";
import { DEFAULT_TOKEN_IN, DEFAULT_TOKEN_OUT } from "constants/tokens";
import {
  LARGE_MEDIA_BREAKPOINT,
  MIN_WIDTH_MEDIA_BREAKPOINT,
} from "constants/width";
import { useSwitchChain } from "hooks/useSwitchChain";
import DurationInput from "pages/Crypto/components/DurationInput";
import { OrderDetail } from "pages/Crypto/components/Order";
import TokenAmountInput from "pages/Crypto/components/TokenAmountInput";
import UnitPriceInput from "pages/Crypto/components/UnitPriceInput";
import { useSelectChain } from "state/chain/hooks";
import styled from "styled-components";
import { Order, Token } from "types";

const FormItem = styled(Box)`
  margin-bottom: 42px;
`;
const FormLabel = styled(Box)`
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 600;
`;
const Title = styled(Box)`
  margin-bottom: 52px;
`;

const PageRow = styled(Flex)`
  max-width: 1052px;
  flex-wrap: nowrap;
  justify-content: space-around;
  > div:first-child {
    @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
      width: 100%;
    }
  }
`;

const OrderDetailWrap = styled(Box)`
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    display: None;
  }
`;

const ButtonUse = styled(BaseButton)`
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};
  padding: 6px 16px;
  line-height: 12px;
  font-size: 14px;
  font-weight: 500;
`;

const BlanceWrapper = styled(Flex)`
  flex-wrap: nowrap;
  margin-top: 10px;
  justify-content: space-between;
`;

const ExpiresFlex = styled(Flex)`
  justify-content: space-between;
`;

export default function Home() {
  const { provider, chainId, account, connector } = useWeb3React();
  const [walletDrawerOpen, toggleWalletDrawer] = useAccountDrawer();
  const [limitPriceInverted, setLimitPriceInverted] = useState(false);
  const [tokenIn, setTokenIn] = useState<Token>(DEFAULT_TOKEN_IN);
  const [tokenOut, setTokenOut] = useState<Token>(DEFAULT_TOKEN_OUT);
  const [base, setBase] = useState<Token>(DEFAULT_TOKEN_IN);
  const [quote, setQuote] = useState<Token>(DEFAULT_TOKEN_OUT);
  const [amountIn, setAmountIn] = useState<string>("");
  const [amountOut, setAmountOut] = useState<string>("");
  const [duration, setDuration] = useState("1day");
  const [chainName, setChainName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const switchChain = useSwitchChain();
  const [tokenOutBalance, setTokenOutBlance] = useState(0.0);
  const [unitPrice, setUnitPrice] = useState<number | string>("");

  const chainInfo = useSelectChain(chainName);


  const [order, setOrder] = useState<Order>({
    input: { token: tokenIn, amount: "" },
    output: { token: tokenOut, amount: "" },
    total_amount: 1,
    start_time: 1,
    end_time: 2,
    expiry: "1day",
  });

  useEffect(() => {
    const input = { token: tokenIn, amount: amountIn };
    const output = { token: tokenOut, amount: amountOut };
    const newOrder: Order = {
      total_amount: 0,
      start_time: 0,
      end_time: 0,
      input: input,
      output: output,
      expiry: duration,
    };
    setOrder(newOrder);
    const valIn = parseFloat(amountIn);
    const valOut = parseFloat(amountOut);
    if (
      Number.isNaN(valIn) ||
      Number.isNaN(valOut) ||
      tokenIn.chain !== tokenOut.chain
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [amountIn, amountOut, tokenIn, tokenOut, duration]);
  var token_id_out = tokenOut.chain + "/" + tokenOut.contract_address;

  const { data, isSuccess } = useTokenBalanceQuery({
    token_id: token_id_out,
    account,
  });

  useEffect(() => {
    if (isSuccess) {
      setTokenOutBlance(data.data.balance);
    }
  }, [data, isSuccess]);

  const formatPrice = (v: number | string): number => {
    if (typeof v === "string") {
      const vv = parseFloat(v);
      if (Number.isNaN(vv)) {
        return 0;
      }
      return vv;
    }
    return v;
  };

  const handleTokenIn = (v: Token) => {
    setTokenIn(v);
  };
  const handleTokenOut = (v: Token) => {
    setTokenOut(v);
  };

  useEffect(() => {
    setChainName(tokenIn.chain);
    if (limitPriceInverted) {
      setBase(tokenOut);
      setQuote(tokenIn);
    } else {
      setBase(tokenIn);
      setQuote(tokenOut);
    }
  }, [limitPriceInverted, tokenIn, tokenOut]);

  const handlePrice = (v: number | string) => {
    setUnitPrice(v);
    const newPrice = formatPrice(v);
    if (newPrice > 0 && formatPrice(amountIn) > 0) {
      var price = tokenIn.symbol === base.symbol ? newPrice : 1 / newPrice;
      const amount = Number(
        (formatPrice(amountIn) * formatPrice(price)).toFixed(8)
      );
      setAmountOut(amount.toString());
    }
  };

  const handelMountOut = (v: string) => {
    setAmountOut(v);
    if (formatPrice(unitPrice) > 0) {
      const amount = limitPriceInverted
        ? formatPrice(unitPrice) * formatPrice(v)
        : formatPrice(v) / formatPrice(unitPrice);
      setAmountIn(amount.toString());
    }
  };
  const handelMountIn = (v: string) => {
    setAmountIn(v);
    if (formatPrice(unitPrice) > 0) {
      const amount = limitPriceInverted
        ? formatPrice(v) / formatPrice(unitPrice)
        : formatPrice(unitPrice) * formatPrice(v);
      setAmountOut(amount.toString());
    }
  };

  const handleReverse = () => {
    setLimitPriceInverted(!limitPriceInverted);
    const newPrice =
      typeof unitPrice == "string" ? parseFloat(unitPrice) : unitPrice;
    if (newPrice > 0) {
      const newValue = (1 / newPrice).toFixed(8);
      setUnitPrice(Number(newValue));
    }
  };

  const handleQuoteChange = (token: Token) => {
    setQuote(token);
    if (limitPriceInverted) {
      setTokenIn(token);
    } else {
      setTokenOut(token);
    }
  };
  const handleBaseChange = (token: Token) => {
    setBase(token);
    if (limitPriceInverted) {
      setTokenOut(token);
      
    } else {
      setTokenIn(token);
    }
  };


  const handlePlaceOrder=async ()=>{
    try {
      const seaport = new Seaport(provider as any);
      console.log("seaport address",seaport.contract.address)
      const offerer = account;
      const startTime=(new Date().getTime()/1000).toFixed(0)
       const endTime=(new Date().getTime()/1000).toFixed(0)
      const { executeAllActions } = await seaport.createOrder(
        {
          startTime: startTime,
          endTime: endTime,
          offer: [
            {
              token: tokenOut.contract_address,
              amount:amountOut,
              endAmount:amountOut
            },
          ],
          consideration: [
            {
              token: tokenIn.contract_address,
              amount: amountIn,
              endAmount:amountIn,
              recipient: offerer,
            },
          ],
        },
        offerer,
      );

      const order = await executeAllActions();
      const orderHash = seaport.getOrderHash(order.parameters);
      
      //  onChange && onChange({ orderHash, order })
    } catch (err) {
      console.log('errorerror', err)
    }
  }

  return (
    <>
      <Page>
        <Title>
          <Text pb={1} fontSize={36}>
            Limit Order
          </Text>
          <Text>Create order to buy or sell the crypto you prefer</Text>
        </Title>
        <Box>
          <PageRow>
            <Box>


            <FormItem>
                <Box>
                  <FormLabel>Unit price</FormLabel>
                  <UnitPriceInput
                    onQuoteChange={(v) => {
                      handleQuoteChange(v);
                    }}
                    onBaseChange={(v) => {
                      handleBaseChange(v);
                    }}

                    unitPrice={unitPrice}
                    onReverse={handleReverse}
                    base={base}
                    quote={quote}
                    onChange={(v) => {
                      handlePrice(v);
                    }}
                  />
                </Box>
              </FormItem>

              <FormItem>
                <FormLabel>I will receive</FormLabel>
                <Box>
                  <TokenAmountInput
                    value={amountIn}
                    onUserInput={function (value: string): void {
                      handelMountIn(value);
                    }}
                    showMaxButton={false}
                    currency={tokenIn}
                    onCurrencySelect={(v) => {
                      handleTokenIn(v);
                    }}
                  />
                </Box>
              </FormItem>

              <FormItem>
                <FormLabel>I will sell</FormLabel>
                <Box>
                  <TokenAmountInput
                    value={amountOut}
                    onUserInput={function (value: string): void {
                      handelMountOut(value);
                    }}
                    showMaxButton={false}
                    currency={tokenOut}
                    onCurrencySelect={(v) => {
                      handleTokenOut(v);
                    }}
                  />
                  <BlanceWrapper>
                    <Text>Balance</Text>{" "}
                    <Flex>
                      <Text marginRight={"10px"}>{tokenOutBalance}</Text>{" "}
                      <ButtonUse onClick={() => {}}>Max</ButtonUse>
                    </Flex>
                  </BlanceWrapper>
                </Box>
              </FormItem>
              <FormItem>
                <ExpiresFlex>
                  <Box>Expires in</Box>
                  <Box>
                    <DurationInput
                      value={duration}
                      onChange={function (value: number | string): void {
                        setDuration(value as string);
                      }}
                    />
                  </Box>
                </ExpiresFlex>
              </FormItem>
              <Box marginTop={5} marginBottom={5}>
                {chainInfo &&
                parseInt(chainInfo.chain_id) === chainId &&
                account ? (
                  <ButtonPrimary disabled={!isValid} onClick={()=>{handlePlaceOrder()}}>Place order</ButtonPrimary>
                ) : null}

                {!account ? (
                  <ButtonPrimary
                    onClick={() => {
                      !walletDrawerOpen && toggleWalletDrawer();
                    }}
                  >
                    Connect wallet
                  </ButtonPrimary>
                ) : null}
                {account &&
                chainInfo &&
                parseInt(chainInfo.chain_id) !== chainId ? (
                  <ButtonPrimary
                    onClick={() => {
                      switchChain(
                        connector,
                        parseInt(chainInfo.chain_id),
                        chainInfo
                      );
                    }}
                  >
                    Switch to {`${chainName}`}
                  </ButtonPrimary>
                ) : null}
              </Box>
            </Box>
            <OrderDetailWrap>
              <OrderDetail order={order} />
            </OrderDetailWrap>
          </PageRow>
        </Box>{" "}
      </Page>
    </>
  );
}
