import { MintParamsStruct } from "utils/merkletree";
import { BigNumber } from "ethers";
import { useGetAllowListMutation } from "apis/drop";
import { useEffect, useMemo, useState } from "react";
import { DropSettings, MintStage } from "types";

export const useAllowlist = (
  settings: DropSettings,
  fee_bps: number,
  chain: string,
  contract_address: string
) => {
  const [getAllowList] = useGetAllowListMutation();
  const [allowList, setAllowList] = useState<any[]>([]);

  useEffect(() => {
    const fetchAllowList = async () => {
      const presales = settings.stages;
      if (!presales) return;
      const max_supply = settings.max_supply as number;
      console.log("presale length:", presales?.length);
      let templist: any[] = [];
      const length=presales.length
      for (const [index, el] of presales.entries()) {
        const { data, success } = await getAllowList({
            chain: chain,
            address: contract_address as string,
            key: el.allowlist_file,
          }).unwrap();
          if (success && data) {
            
            data.forEach(
              (allowItem: {
                address: string;
                custom_mint_limit: number;
                price: string;
              }) => {
                const price=BigNumber.from(
                      (Number(allowItem.price) *
                        10 ** el.sale_price.currency.decimals).toString()
                  );
                // console.log(" el.start_time:", new Date( el.start_time*1000).toISOString());
                // console.log(" el.end_time:", new Date( el.end_time*1000).toISOString());
                const maxTotalMintableByWallet =
                  allowItem.custom_mint_limit && allowItem.custom_mint_limit !== 0
                    ? allowItem.custom_mint_limit
                    : el.per_wallet_mint_limit || max_supply;
                let mintParams: MintParamsStruct = {
                  mintPrice: price,
                  dropStageIndex: length-index-1,
                  maxTotalMintableByWallet: maxTotalMintableByWallet as number,
                  maxTokenSupplyForStage: max_supply,
                  startTime: el.start_time,
                  endTime: el.end_time,
                  feeBps: fee_bps,
                  restrictFeeRecipients: true,
                };
                let item = [allowItem.address, mintParams];
                templist.push(item);
              }
            );
          }
      }
    
      setAllowList(templist);
    };
    fetchAllowList();
  }, [getAllowList, settings, chain, contract_address, fee_bps]);

  return allowList;
};

export const usePublicStage = (
  stages: MintStage[] | undefined
): MintStage | null => {
  return useMemo(() => {
    stages?.forEach((el, index) => {
      if (el.is_public) {
        return el;
      }
    });
    return null;
  }, [stages]);
};
