
import { useWeb3React } from "@web3-react/core";
import { useDelWatchOneMutation, useGetWatchListQuery } from "apis/ranking";
import sortFilled from "assets/tool/sort_filled.png";
import sortIcon from "assets/tool/sort_icon.png";
import NoData from "components/NoData";
import { useEffect, useState } from "react";
import { ApplicationModal, setOpenModal } from 'state/application/reducer';
import { useAppDispatch } from 'state/hooks';
import { useCollectionWatchChain, useCollectionWatchPeriod, useToastOpen } from 'state/stats/hooks';
import { useToken } from 'state/user/hooks';

import ChainSelect from "components/ChainSelect";
import PeriodSelect from "components/PeriodSelect";
import {
  MainBB, MainBottom,
  WebMain,
} from "pages/Nft/NftItems/components_";
import { TopCollection } from "types";
import CardTableLoadRow from "../components/CardTableLoadRow/index";
import CardTableMainRow from "../components/CardTableMainRow/topIndex";
import Tab from "../components/Tab";
import {
  CardTableMain,
  CardTableTitleColFirst, CardTableTitleColLast,
  CardTableTitleColTop,
  CardTableTitleColTop1,
  CardTableTitleRow,
  ChainSelectBlock,
  FilterLine,
  OrderImg,
  PageLine, WebAll
} from "../components_";

export function Watchlist() {
  const dispatch = useAppDispatch()
  const token = useToken();
  const { account } = useWeb3React();
  const [loadList] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const [collectionWatchPeriod,setCollectionWatchPeriod] = useCollectionWatchPeriod()
  const [collectionWatchChain,setCollectionWatchChain] = useCollectionWatchChain()
  const [,,, setToastOpen] = useToastOpen();

  const [delWatchOne] = useDelWatchOneMutation()
  const [sortby, setSortby] = useState("volumn");
  const [direct, setDirect] = useState("desc");
  const [next, setNext] = useState<number>(1);//再次获取watchids
  const handleChainChange = (value: string) => {
    console.log("value", value);
    if (!value) {
      setCollectionWatchChain('');
    } else {
      setCollectionWatchChain(value);
    }
  };
  const handleTimeChange = (value: string) => {
    setCollectionWatchPeriod(value);
  };

  const { data: watchlist, status } = useGetWatchListQuery({
    account: account as string,
    page: 1,
    pageSize: 10,
    chain: collectionWatchChain||'',
    period: collectionWatchPeriod||'',
    direct: direct,
    sortby: sortby,
    next: next
  });
  const getSign = () => {
    dispatch(setOpenModal(ApplicationModal.ACCEPT_SIGN));
  }
  const changeSort = (str: string) => {
    if (sortby === str) {
      if (direct === "asc") {
        setDirect("desc");
      } else if (direct === "desc") {
        setSortby("");
        setDirect("");
      } else if (direct === "") {
        setDirect("asc");
      }
    } else {
      setSortby(str);
      setDirect("asc");
    }
  };
  const delOne = async (i: TopCollection, index: number) => {
    if (token) {
      const res = await delWatchOne({
        chain: i.chain,
        contract_address: i.contract_address
      }).unwrap();
      if (res.success) {
        setToastOpen({open:true,type:'success',label:'success'})
        setNext(next + 1)
      } else {
        setToastOpen({open:true,type:'error',label:res.msg })

      }
    } else {//没sign
      getSign()
    }
  }
  //监听拉取数据变化，处理出渲染数据。
  useEffect(() => {
    setNext(next + 1)
  }, []);
  return (
    <>
      <WebAll>
        <WebMain>
          <PageLine>Collections Stats</PageLine>

          <MainBottom>
            <Tab current="watchlist" />

            <MainBB
              style={{ display: "flex", flexDirection: "column" }}
            >
              <FilterLine>
                <ChainSelectBlock>
                  <ChainSelect
                    value={collectionWatchChain||''}
                    onChange={handleChainChange}
                    size="big"
                  />
                </ChainSelectBlock>
                <PeriodSelect
                  value={collectionWatchPeriod||''}
                  onChange={handleTimeChange}
                  size="big5"
                />
              </FilterLine>
              <CardTableTitleRow>
                <CardTableTitleColFirst>Collection</CardTableTitleColFirst>
                <CardTableTitleColTop1
                  onClick={() => {
                    changeSort("volume");
                  }}
                >
                  <div>Volume</div>
                  <OrderImg
                    src={sortby === "volume" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop1>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("volume_change");
                  }}
                >
                  <div>Vol Change</div>
                  <OrderImg
                    src={sortby === "volume_change" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("floor_price_change");
                  }}
                >
                  <div>Floor Change</div>
                  <OrderImg
                    src={sortby === "floor_price_change" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                {/*                 
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("change");
                  }}
                >
                  <div>Change%</div>
                  <OrderImg
                    src={sortby === "change" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop> */}
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("floor_price");
                  }}
                >
                  <div>Floor price</div>
                  <OrderImg
                    src={sortby === "floor_price" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("sales");
                  }}
                >
                  <div>Sales</div>
                  <OrderImg
                    src={sortby === "sales" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>                <CardTableTitleColTop style={{ cursor: 'auto' }}>Owners</CardTableTitleColTop>
                <CardTableTitleColLast></CardTableTitleColLast>
              </CardTableTitleRow>
              {status === "fulfilled" ? (!watchlist || !watchlist.data || watchlist?.data.length < 1 ? (<NoData onChange={() => setCollectionWatchChain('')}></NoData>) : <CardTableMain>
                {watchlist?.data?.map((i: TopCollection, index: number) => (
                  <CardTableMainRow
                    i={i}
                    iswatch={i.iswatch || false}
                    type={"del"}
                    onDelete={() => { delOne(i, index) }}
                    onAdd={() => { }}
                  />
                ))}
              </CardTableMain>) : (<CardTableMain>
                {loadList?.map(() => (
                  <CardTableLoadRow />
                ))}
              </CardTableMain>)}

            </MainBB>
          </MainBottom>
        </WebMain>
      </WebAll>
    </>
  );
}
