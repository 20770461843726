import { useNavigate } from "react-router-dom";
import { Select } from "@rebass/forms";
import { useState, useEffect } from "react";
import ListSelect from "components/ListSelect";
import NoData from "components/NoData"
import AccountTop from "../components/AccountTop";

import { useClientWidth } from "state/application/hooks";
import {
  MainItemNone,
  WebMainB,
  FilterSearch,
  SearchL,
  SearchM,
  SearchIcon,
  SearchR,
  SearchInput,
  CollectionsSearch,
  MainBBl,
  LeftSearchTitle, SearchInput1, SearchIcon1, SearchClose, SearchSelect,
  PropItemIcon,
} from "../components_";

import { MainBB,
  MainBottom,
  MainBBR,} from "pages/Nft/NftItems/components_";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import searchIcon from "assets/tool/search_icon.png";
import fliterIcon from "assets/fliter_icon.png";
import fliterIconN from "assets/fliter_icon_n.png";
import arrowIcon from "assets/tool/arrow_iconw.png"
import arrowIconN from "assets/tool/arrow_iconN.png"
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { Item } from "types";

export function Offers() {
  const clientWidth = useClientWidth();
  let navigate = useNavigate();
  const [darkMode] = useDarkModeManager();
  const [filterKey, setFilterKey] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [isLoad, setIsLoad] = useState(false);
  const [itemsList, setItemsList] = useState<Item[] | null>();
  const [showCollections, setShowCollections] = useState(false);
  const [sorttext, setSorttext] = useState('price_desc');
  const [status, setStatus] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [direct, setDirect] = useState("Low");

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleTypeChange = (value: string) => {
    setType(value);
  };
  const optionArr = ["Low", "Hight"];


  const handleChange = () => {
    if (sorttext == 'price_desc') {
      setSorttext('price_asc')
      setCurrent(1)
    } else {
      setSorttext('price_desc')
      setCurrent(1)
    }
    console.log('sorttextchange')
  };

  const backToAll = () => {
    setFilterKey('')
    setCurrent(1)
  }
  return (
    <>
      <AccountTop current="offers"></AccountTop>
      <WebMainB>
        <MainBottom>

          <FilterSearch>
            <SearchL
              src={darkMode ? fliterIconN : fliterIcon}
              onClick={() => {
                setIsFilter(!isFilter);
              }}
            />
            <SearchSelect>
              <ListSelect
                value={type}
                optionArr={["All Type", "Single offers", "Collection offers", "Trait collection offers"]}
                typeValues={["", "Single offers", "Collection offers", "Trait collection offers",]}
                onChange={handleTypeChange}
                size=""
              />
            </SearchSelect>
            <SearchSelect>
              <ListSelect
                value={status}
                optionArr={["All Status", "Listed", "On auction", "New", "Has offers",]}
                typeValues={["", "Listed", "On auction", "New", "Has offers",]}
                onChange={handleStatusChange}
                size=""
              />
              {/* <StatusSelect
                value={status}
                onChange={handleStatusChange}
                size=""
              /> */}
            </SearchSelect>

            <SearchM>
              <SearchIcon src={searchIcon} />
              <SearchInput
                id="email"
                name="email"
                type="text"
                placeholder="Name or ID in the collection"
                value={filterKey}
                onChange={(e) => {
                  setFilterKey(e.target.value);
                  setCurrent(1);
                }}
              />
            </SearchM>
            <SearchR>
            <ListSelect
                value={direct}
                optionArr={[ "Low",  "Height", ]}
                typeValues={["Low", "Height",]}
                onChange={(val:string)=>setDirect(val)}
                size=""
              />
            </SearchR>
          </FilterSearch>

          <MainBB>
            <MainBBl isFilter={isFilter}>
              {Number(clientWidth) < 801 ? (
                <LeftSearchTitle style={{ margin: '20px 0' }}><div>Search Filtwer</div> <PropItemIcon src={darkMode ? closeIconN : closeIcon} onClick={() => setIsFilter(!isFilter)} /></LeftSearchTitle>
              ) : null}

              <LeftSearchTitle onClick={() => setShowCollections(!showCollections)}><div>Collections</div> <PropItemIcon src={darkMode ? arrowIconN : arrowIcon} style={showCollections ? {} : { transform: 'rotate(180deg)' }} /></LeftSearchTitle>
              {showCollections ? (<div>
                <CollectionsSearch>
                  <SearchIcon1 src={searchIcon} />
                  <SearchInput1
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Search"
                    value={filterKey}
                    onChange={(e) => {
                      setFilterKey(e.target.value);
                      setCurrent(1);
                    }}
                  />
                  <SearchClose src={darkMode ? closeIconN : closeIcon} />

                </CollectionsSearch>
              </div>) : null}
            </MainBBl>
            <MainBBR isFilter={isFilter}>
              {!isLoad && total < 1 ? (
                <NoData onChange={() => backToAll()}></NoData>


              ) : ('')}
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
              <MainItemNone></MainItemNone>
            </MainBBR>
          </MainBB>

        </MainBottom>
      </WebMainB>

    </>
  );
}
