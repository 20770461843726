import styled, { keyframes } from "styled-components/macro";
import {MAX_WIDTH_MEDIA_BREAKPOINT,MIN_WIDTH_MEDIA_BREAKPOINT,LARGE_MEDIA_BREAKPOINT} from "constants/width"

export const rotateImg = keyframes`
  0% {
    transform:rotate(0deg);
  }

  100% {
    transform:rotate(-360deg);
  }
`
export const PageAll = styled.div`
  max-width: 1600px;
  min-width: 800px;
  min-height: calc(100vh - 102px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  padding-top: 40px;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
    min-height: calc(100vh - 152px);
    margin: 0 auto;
    text-align: left;
    overflow: hidden;
    > div {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`;
export const PageRight = styled.div`
  width: 25%;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const PageLeft = styled.div`
  width: calc(75% - 20px);
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`;
export const PageCenter = styled.div`
  max-width: 1440px;
  min-width: 800px;
  height: auto;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;

  justify-content: space-between;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
    width: 95vw !important;
    max-width: 1440px !important;
    min-width: 760px !important;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 95vw !important;
    max-width: 800px !important;
    min-width: 0px !important;

    flex-wrap: wrap;
  }
`;
export const PageMain = styled(PageCenter)`
max-width: 1440px;
min-width: 800px;
@media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}){
  width: 95vw !important;
  max-width: 1440px !important;
  // max-height: 1106px;
  min-width: 760px !important;
}
`;

export const ActivityIcon = styled.img`
width:22px;
height:22px;
cursor: pointer
`
export const ActivityLoadIcon = styled.div`
width:22px;
height:22px;
background: ${({ theme }) => theme.bg2};
`
export const ActivityIconRotate = styled.img`
width:22px;
height:22px;
animation: ${rotateImg} 2s linear 0s infinite normal;
-webkit-animation: ${rotateImg} 2s linear 0s infinite normal;
`
export const TitleItem = styled.div`
  font-size: 38px;
  font-family: Arial-Bold, Arial;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 45upx;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    font-size: 38px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: ${({ theme }) => theme.text1};
    line-height: 45upx;
    // margin-bottom: 28px;
  }
  @media screen and (min-width:${LARGE_MEDIA_BREAKPOINT}) and (max-width: 1000px) {
    font-size: 30px;
    margin-bottom: 16px;
    margin-top: 12px;
    > div {
      font-size: 30px;
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 16px;
    margin-top: 12px;
    > div {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 0;
    }
  }
`;

export const TitleRight = styled.div`
  display: flex;
  align-items: center;
`;

export const ImgOne = styled.img`
  width: 27px;
  height: 27px;
`;
export const BtnOne = styled.div`
  width: 100px;
  height: 41px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border1};
  background: transparent;
  text-align: center;
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 41px;
`;
export const TitleText = styled.div`
  color: ${({ theme }) => theme.text1};
`;
export const TrendList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1600px;
`;
export const CollectInfoLogo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50px;
  object-fit: contain;

  position: absolute;
  bottom: 0;
  right: -5px;
`;
export const PopCollectionImgBlock = styled.div`
  flex-shrink: 0;
  width: 10%;
  min-width: 70px;
  min-height: 70px;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  border-radius:50%;
  display: flex;
  
  justify-content: center;
  flex-direction:column;
  align-items: center;
  // overflow: hidden;
`;
export const SearchCollectionImgBlock = styled.div`
  flex-shrink: 0;
  width: 10%;
  min-width: 50px;
  min-height: 50px;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  border-radius:50%;
  display: flex;
  align-items: center;
  overflow: hidden;
`;
export const PopCollectionImg = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit:contain;
  border-radius:50%;
`;

export const PopCollectionName = styled.div`
  font-size: 18px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 40px;
  max-width: calc(100% - 140px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
`;
export const SearchCollectionName = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 40px;
  max-width: calc(100% - 100px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
`;
export const PopCollectionList = styled.div`
  width:100%;
  // border:1px solid ${({ theme }) => theme.border1};
  border-radius:10px;
  padding:20px 0;
  box-sizing:border-box;
`;
export const PopCollectionLine = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text2};
  font-size:14px;
  >div{
    width:20%;
    display: flex;
    align-items: center;
  }
  >div:first-child{
    width:40%;
  }
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 12px;
  }
`;
export const SearchCollectionLine = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.text2};
  font-size:14px;
  >div{
    width:20%;
    display: flex;
    align-items: center;
  }
  >div:first-child{
    width:80%;
  } 
  >div:last-child{
    display: block;
    text-align:right;
  }
  
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    font-size: 12px;
  }
`;

export const PopCollectionItem = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  border-radius: 20px; 
  padding: 15px 20px;
  box-sizing: border-box;
  line-height: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: background 450ms ease;

  :hover {
    background: ${({ theme }) => theme.bg2};
  }
`;
export const SearchCollectionItem = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  border-radius: 20px; 
  padding: 10px 5px;
  box-sizing: border-box;
  line-height: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: background 450ms ease;

  :hover {
    background: ${({ theme }) => theme.bg2};
  }
`;
export const TrendListTel = styled.div``;
export const TrendItem = styled.div`
  flex-shrink: 0;
  width: calc(50% - 20px);
  min-width: 330px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  border-radius: 20px;
  padding: 15px 20px;
  box-sizing: border-box;
  line-height: 16px;
  margin: 0 10px;
  margin-bottom: 26px;
  cursor: pointer;
  overflow: hidden;
  transition: background 450ms ease;

  :hover {
    background: ${({ theme }) => theme.bg2};
  }
`;
export const TrendItemTel = styled.div`
  width: 100%;
  height: 77px;
  padding: 11px 0 13px;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 16px;
`;
export const TrendIndex = styled.div`
  width: 6%;
  flex-shrink: 0;
`;
export const TrendImg = styled.img`
  flex-shrink: 0;
  width: 10%;
  min-width: 70px;
  min-height: 70px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 8%;
`;


export const TrendImgNone = styled.div`
  flex-shrink: 0;
  width: 10%;
  min-width: 70px;
  min-height: 70px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 8%;
  background: ${({ theme }) => theme.bg2};
`;

export const SearchImgNone = styled.div`
  min-width: 50px;
  min-height: 50px;
  width: 100%;
  height:100%;
  border-radius: 50%;

  background: ${({ theme }) => theme.bg2};
`;

export const DivNone = styled.div`
  white-space: pre;

  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
`;

export const TrendImgTel = styled.img`
  flex-shrink: 0;
  width: 53px;
  height: 53px;
  
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  object-fit:cover;
`;
export const TrendImgTelBlock = styled.div`
flex-shrink: 0;
width: 53px;
height: 53px;
position: relative;
margin-left: 5px;
margin-right: 5px;
border-radius:50%;
display: flex;

justify-content: center;
flex-direction:column;
align-items: center;
// overflow: hidden;
`;

export const TrendTextLoad = styled.div`
  background: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.bg2};
  white-space: pre;
`;
export const TrendImgTelLoad = styled.div`
  flex-shrink: 0;
  width: 53px;
  height: 53px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 4px;
  margin-left: 4px;
`;

export const TrendMain = styled.div`
  padding: 0 10px;
  box-sizing: border-box;
  flex: 1;
  @media screen and (min-width: ${LARGE_MEDIA_BREAKPOINT}) and (max-width: 1200px) {
    max-width: 50%;
  }
`;
export const TrendMainTel = styled.div`
  max-width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
  flex: 1;
`;
export const TrendLine = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const TrendLineImg = styled.img`
  width: 12px;
  height: 12px;
`;
export const TrendLineText = styled.div`
  font-size: 18px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 40px;
  max-width: calc(100% - 20px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
`;
export const TopWhite = styled.div`
  background-image: linear-gradient(
    180deg,
    transparent,
    ${({ theme }) => theme.bg1}
  );
  z-index: 10;
  overflow: hidden;
`;
export const TrendLineText1 = styled.div`
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 14px;
  > span {
    color: ${({ theme }) => theme.text1};
  }
`;
export const TrendRight = styled.div`
  width: 30%;
  text-align: right;
  font-size: 14px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  line-height: 16px;
  font-weight: bold;
  overflow: hidden;
  flex: 1;
`;
export const TrendRightTop = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  flex-direction: row-reverse;
`;
export const TrendRightImg = styled.img`
  width: 15px;
  height: 15px;
`;
export const TrendRightBottom = styled.div`
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: #31bd65;
  line-height: 14px;
`;
export const SpotList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  max-width: 1600px;
  position: relative;
`;
export const SpotListTel = styled.div`
  display: flex;
  // width: 100%;
  overflow-x: scroll;
`;
export const PosaLImg = styled.img`
  position: absolute;
  left: 0;
  top: calc(50% - 57px);
  width: 34px;
  height: 34px;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;
export const PosaSImg = styled.img`
  position: absolute;
  right: 0;
  top: calc(50% - 57px);
  width: 34px;
  height: 34px;
  cursor: pointer;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;
export const SpotItemImg = styled.img`
  width: calc(9.5vw - 18px);
  height: calc(9.5vw - 18px);
  max-width: 154px;
  max-height: 154px;
  border-radius: 50%;
  object-fit: cover;
`;
export const SpotItemImgNone = styled.div`
  width: calc(9.5vw - 18px);
  height: calc(9.5vw - 18px);
  // padding-top: 100% ;
  border-radius: 50%;
  background: ${({ theme }) => theme.bg2};
  margin-bottom: 2px;
`;

export const SpotItemImgTel = styled.img`
  width: 86px;
  height: 86px;
`;

export const SpotItemImgTelLoad = styled.div`
  width: 86px;
  height: 86px;
  background: ${({ theme }) => theme.bg2};
`;

export const SpotItem = styled.div`
  width: 10%;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  left: 0;
  margin: 0 1.25% 45px;
  position: relative;
  transition: all 450ms ease;
  :hover {
    background: ${({ theme }) => theme.bg2};
  }
`;
export const SpotItemTel = styled.div`
  width: 86px;
  margin-right: 22px;
  flex-shrink: 0;
`;
export const SpotItemText = styled.div`
  width: 100%;
  text-align: center;
  height: 32px;
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  line-height: 16px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 5px 0;
`;

export const CollectList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const CollectItem = styled.div`
  margin-bottom: 50px;
  cursor: pointer;
  width: calc(23vw - 10px);
  min-width: 189px;
  max-width: 350px;
  background: ${({ theme }) => theme.bg0};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;

  transition: all 450ms ease;
  :hover {
    position: relative;
    bottom: 2px;
    box-shadow: 2px 2px 20px ${({ theme }) => theme.text4};
  }
`;
export const CastItemTelLoad = styled.div`
  margin-bottom: 30px;
  width: 100%;
  height: 150px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  flex-shrink: 0;
`;
export const CastItem = styled.div`
  margin-bottom: 30px;
  cursor: pointer;
  width: 100%;
  background: ${({ theme }) => theme.bg0};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;

  max-height:258px;
  transition: all 450ms ease;
  position: relative;
  :hover {
    position: relative;
    bottom: 2px;
    box-shadow: 2px 2px 20px ${({ theme }) => theme.text4};
  }
`;
export const CastItemTop = styled.div`
  width: 100%;
  height: 12vw;
  min-height: 103px;
  max-height: 206px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;
export const CastItemBottom = styled.div`
  padding: 27px 10px 10px;    
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

`;
export const CastTopImg = styled.img`
width: 100%;
height: 100%;
object-fit: cover;
`;
export const CollectItemNone = styled.div`
  margin-bottom: 50px;
  width: calc(23vw - 10px);
  height: calc(16vw - 10px);
  min-width: 189px;
  max-width: 350px;
  max-height: 250px;
  background: ${({ theme }) => theme.bg2};
`;
export const CollectItemTop = styled.div`
  // width: 15vw;
  width: calc(23vw - 10px);
  height: 12vw;
  min-width: 189px;
  min-height: 103px;
  max-width: 350px;
  max-height: 206px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;
export const CollectItemTopTel = styled.div`
  width: 208px;
  height: 106px;

  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const CollectTopImg = styled.img`
  // height: 8.25vw;
  min-width: 189px;
  // min-height: 103px;
  max-width: 350px;
  // max-height: 206px;
`;
export const CollectTopImg1 = styled.img`
  position: absolute;
  // bottom: -22px;
  top: -22px;
  left: 10px;
  width: 44px;
  height: 44px;
  border: 2px solid ${({ theme }) => theme.bg0};
  background: ${({ theme }) => theme.bg0};
  border-radius: 4px;
  box-sizing: border-box;
`;
export const CollectTopImgTel = styled.img`
  width: 208px;
  // height:106px;
`;
export const CollectTopImg1Tel = styled.img`
  position: absolute;
  top: -23px;
  left: 10px;
  width: 40px;
  height: 40px;
  border: 2px solid ${({ theme }) => theme.bg0};
  border-radius: 4px;
  box-sizing: border-box;
`;
export const CollectItemBottom = styled.div`
  padding: 27px 10px 10px;
  position: relative;
`;
export const CollectItemBottomTel = styled.div`
  padding: 17px 10px 12px;
  position: relative;
`;
export const CollectItemBottomInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const BottomInfoLineTel = styled.div`
  width: 94px;
`;
export const BottomInfoLine = styled.div`
  width: 115px;
`;
export const BottomInfoTitle = styled.div`
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text3};
  line-height: 14px;
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const BottomInfoDesc = styled.div`
  display: flex;
  font-size: 12px;
  font-family: Arial-Regular, Arial;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  margin-top: 2px;
  line-height: 14px;
  max-width: 115px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    color: ${({ theme }) => theme.text3};
  }
`;
export const InfoDescImg = styled.img`
  width: 12px;
  height: 12px;
`;
export const ItemBottomName = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
export const ItemBottomImg = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 2px;
`;
export const ItemBottomNameText = styled.div`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};
  line-height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const CategList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: ${MIN_WIDTH_MEDIA_BREAKPOINT}) and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;
    display: none;
  }
`;
export const CategListTel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
`;

export const CategItem = styled.div`
  margin-bottom: 50px;
  width: calc(30vw - 10px);
  max-width: 500px;
  min-width: 189px;
  background: ${({ theme }) => theme.bg0};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  cursor: pointer;
`;

export const CategItemTelLoad = styled.div`
  margin-bottom: 25px;
  width: 208px;
  height: 150px;
  background: ${({ theme }) => theme.bg2};
  border-radius: 6px;
  margin-right: 15px;
  flex-shrink: 0;
`;

export const CategItemTel = styled.div`
  margin-bottom: 25px;
  width: 208px;
  background: ${({ theme }) => theme.bg0};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.border1};
  overflow: hidden;
  cursor: pointer;
  margin-right: 15px;
  flex-shrink: 0;
`;
export const CategItemText = styled.div`
  width: calc(30vw - 10px);
  max-width: 500px;
  padding: 0 10px;
  font-size: 16px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const CategItemImg = styled.img`
  width: 100%;
`;
export const CategItemImgTel = styled.img`
  width: 208px;
  height: 106px;
`;
export const CategItemTextTel = styled.div`
  width: 208px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 13px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
  color: ${({ theme }) => theme.text1};
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const ShowBtn = styled.div<{ showAll: boolean }>`
  width: ${({ showAll }) => (showAll ? "0" : "109px")};
  height: ${({ showAll }) => (showAll ? "0" : "35px")};
  opacity: ${({ showAll }) => (showAll ? "0" : "1")};
  overflow: hidden;
  background: #6a5bda;
  border-radius: 4px;
  line-height: 23px;
  font-size: 15px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: #ffffff;
  line-height: 35px;
  text-align: center;
`;
