import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useSwitchChain } from 'hooks/useSwitchChain';

import { useNavigate, useParams } from "react-router-dom";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { OrderWithCounter, OrderComponents } from "@opensea/seaport-js/lib/types";
import { Seaport } from "@opensea/seaport-js";
import Modal from "styled-react-modal";
import {useToastOpen } from 'state/stats/hooks'

import { ISO8601DateStr2Date, timestampToTime, priceText } from "utils";
import { useClientWidth } from "state/application/hooks";
import { useGetChainQuery, useGetChainListQuery } from "apis/chain";
import {
  GetListOrderRequest, useGetOrdersMutation,
  useSaveListingOrderMutation, useUpdateListingOrderMutation, useFulfillmentDataMutation
} from "apis/orders";
import {
  useActivityListMutation,
  useCollectionItemInfoQuery,
  useOrderListQuery,
  useRecommendListQuery,
} from "apis/collection";
import { useRefreshMetadataMutation } from "apis/assets";

import DetailForm, { DetailForms } from "./indexTel";
import DetailFormWeb from "./indexWeb";



import QuickListEdit, { QuickListForm } from "pages/My/Item/components/EditListing";
import QuickList from "pages/My/Item/components/QuickList";
import MakeAnOffer, { OfferForm } from "pages/My/Item/components/MakeAnOffer";

import { LoadIcon } from "pages/My/Collection/components_"
import "pages/index.scss";
import refreshN from "assets/tool/refresh_n.png";
import refresh from "assets/tool/refresh.png";

import { SEADPORT_ADDRESS } from "constants/addresses";
import { StyledModal } from "components/Modal/styled_modal";
import { ActivityItem, Assets, ChainInfo, OrderItem } from "types";
import { Box } from "rebass";
const LoadModal = Modal.styled`
  background: ${(props: { theme: any }) => props.theme.bg1};
  color: ${(props: { theme: any }) => props.theme.text3};
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 40px ;
  font-size: 16px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  >div{
    margin-left:5px;
  }
`;
export default function Home(props: any) {
  const clientWidth = useClientWidth();  
  const [,,, setToastOpen] = useToastOpen();

  const switchChain = useSwitchChain()
  const [darkMode] = useDarkModeManager();
  let params = useParams();
  const [getOrders] = useGetOrdersMutation()
  const [activityList] = useActivityListMutation();
  const [refreshMetadata] = useRefreshMetadataMutation();
  const [getFulfillmentData] = useFulfillmentDataMutation()
  const [createlistingOrder] = useSaveListingOrderMutation();
  const [updatelistingOrder] = useUpdateListingOrderMutation();
  const { account, provider, chainId, connector } = useWeb3React();
  const [itemInfo, setItemInfo] = useState<Assets | null>();
  const [detailForm, setDetailForm] = useState<DetailForms>();
  const [recommendList, setRecommendList] = useState<Assets[]>([]);
  const [offers, setOffers] = useState<OrderItem[]>([]);
  const [listings, setListings] = useState<OrderItem[]>([]);
  const [listings1, setListings1] = useState<OrderItem[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [recommendLoad, setRecommendLoad] = useState(true);
  const [itemInfoLoad, setItemInfoLoad] = useState(true);

  const [address, setAddress] = useState("");
  const [actList, setActList] = useState<ActivityItem[]>([]);
  const [actLoad, setActLoad] = useState(false);
  const [current, setCurrent] = useState(1);
  const [actTotal, setActTotal] = useState(0);
  const [next, setNext] = useState(0);

  const [currentAsset, setCurrentAsset] = useState<Assets | null>();
  const [offerFormVisible, setOfferFormVisible] = useState(false);
  const [quickListFormVisible, setQuickListFormVisible] = useState(false);
  const [quickListEditFormVisible, setQuickListEditFormVisible] = useState(false);
  const [chainCurrency, setChainCurrency] = useState<ChainInfo | null>();
  const currencydata = useGetChainQuery({
    chain: params.chain_identifier as string,
  });
  var div = document.getElementById("actmain");
  const handleScroll = () => {
    let scrollHeight = div?.scrollHeight;
    let scrollTop = div?.scrollTop;
    let height = div?.offsetHeight;
    if (
      scrollTop &&
      height &&
      scrollHeight &&
      scrollTop + height >= scrollHeight - 2
    ) {
      console.log("到底了", actLoad, actList.length, actTotal);
      if (!actLoad && actList.length < actTotal) {
        setCurrent(current + 1);
      }
    }
  };
  useEffect(() => {
    // 监听
    div?.addEventListener("scroll", handleScroll);
    // 销毁
    return () => {
      div?.removeEventListener("scroll", handleScroll);
    };
  });
  useEffect(() => {
    if (currencydata && currencydata.data?.success) {
      if (currencydata?.data?.data) {
        setChainCurrency(currencydata?.data.data);
      }
    }
  }, [currencydata]);
  // useEffect(() => {
  //   if(status==='')
  // }, [status]);
  const { data } = useCollectionItemInfoQuery({
    chainIdnetifier: params.chain_identifier || "",
    contractAddress: params.contract_address || "",
    tokenID: params.tokenId || "",
    next: next
  });
  // const res = useRecommendListQuery({
  //   page: 1,
  //   pageSize: 12,
  //   chain: params.chain_identifier || "",
  //   contractAddress: params.contract_address || "",
  //   tokenId: params.tokenId || "",
  // });
  useEffect(() => {
    if (data && data.success && !itemInfo) {
      console.log('setItemInfo0', data?.data)
      setItemInfo(data?.data);
      getActivity(data?.data);
      setItemInfoLoad(false);
      setAddress(data?.data.contract_address);
    } else if (data && data.message) {
      setToastOpen({open:true,type:'error',label:data.message})

    }




  }, [data, address]);
  const getOrderList = async () => {
    let ordersparams: GetListOrderRequest = {
      chain: params.chain_identifier || "",
      contractAddress: params.contract_address || "",
      tokenID: params.tokenId || "",
      side: "ask",
    };
    const { data, success } = await getOrders(ordersparams).unwrap();
    if (success && data !== listings) {
      // listings
      let now = new Date().getTime();
      setListings(data);
      let list = data?.map((q) => Object.assign({}, q));

      list?.map((el) => {
        let str = timestampToTime(el.end_time || 0);
        if ((el.end_time * 1000) > now) {
          let time = (el.end_time * 1000) - now;
          if (Math.ceil(time / 86400000) > 29) {
          } else if (time / 86400000 < 1) {
            str = "in " + Math.ceil(time / 3600000) + " hours";
          } else {
            str = "in " + Math.ceil(time / 86400000) + " days";
          }
        }

        el.timestr = str;
      });
      setListings1(list);
    }
  }
  const getOffer = async () => {
    let ordersparams: GetListOrderRequest = {
      chain: params.chain_identifier || "",
      contractAddress: params.contract_address || "",
      tokenID: params.tokenId || "",
      side: "bid",
    };
    const { data, success } = await getOrders(ordersparams).unwrap();
    if (success && data !== offers) {
      setOffers(data);
    }
  }
  useEffect(() => {
    getOrderList()
    getOffer()
  }, []);
  const getRefreshMetadata = async () => {
    if (detailForm?.isRefresh) {
      setToastOpen({open:true,type:'error',label: `Refresh Loading...`})

      return;
    }
    setDetailForm({
      ...detailForm,
      ...{
        isRefresh: true,
      },
    });
    try {
      const { data, status } = await refreshMetadata({
        chain: params.chain_identifier as string,
        contract_address: params.contract_address as string,
        token_id: params.tokenId as string,
      }).unwrap();
      if (status === "success") {
        setDetailForm({ ...detailForm, ...{ isRefresh: false } });
        if (itemInfo !== data) {
          setItemInfo(data);
          getActivity(data);
          setAddress(data.contract_address);
        } else {
          //没变化
        }
        setToastOpen({open:true,type:'success',label: `We've queued this item for an update! Check back in a minute...`})

      } else {
        setDetailForm({ ...detailForm, ...{ isRefresh: false } });
      }
    } catch (error) {
      setDetailForm({ ...detailForm, ...{ isRefresh: false } });
    }
  };
  const getActivity = async (info: Assets) => {
    if (actLoad) {
      return;
    }
    setActLoad(true);
    const res = await activityList({
      chain: info?.chain_identifier,
      address: info?.contract_address,
      tokenid: info?.token_id,
      pageSize: 10,
      current: current,
    }).unwrap();
    if (res.success) {
      setActLoad(false);
      setActTotal(res.total);
      // setActList(res.data)

      if (res.data) {
        let list = res.data.map((q) => Object.assign({}, q));

        list.forEach((el) => {
          let time = el.timestamp;
          let endtime = ISO8601DateStr2Date(String(time));
          let str = timestampToTime(endtime || 0);
          if (typeof el.timestamp == "number") {
            str = timestampToTime(el.timestamp);
          }

          el.timestamp = str;
        });
        let temp = list;
        if (current !== 1) {
          temp = actList.concat(list);
        }

        setActList(temp);
      }
    } else {
      setActLoad(false);
      setToastOpen({open:true,type:'error',label:res.message })

    }
  };
  useEffect(() => {
    if (itemInfo && !actLoad) {
      getActivity(itemInfo);
    }
  }, [current]);
  useEffect(() => {
    setDetailForm({
      ...detailForm,
      ...{
        address: address,
        itemInfoLoad: itemInfoLoad,
        itemInfo: itemInfo,
        actList: actList,
        listings: listings1,
        offers: offers,
        recommendLoad: recommendLoad,
        recommendList: recommendList,
        chainCurrency: chainCurrency,
        isRefresh: false,
      },
    });
  }, [
    recommendLoad,
    recommendList,
    itemInfoLoad,
    itemInfo,
    address,
    actList,
    chainCurrency,
    listings1, offers
  ]);

  const isChainTrue = async () => {
    let chain = params.chain_identifier
    if (chainId && chain && chainCurrency && (Number(chainCurrency.chain_id) === chainId)) {
      return true
    } else {
      if (chainId && chain && chainCurrency) {
        setToastOpen({open:true,type:'error',label:"Plase Switch chain" })
        let newchain = -1
        newchain = Number(chainCurrency.chain_id)
        console.log('newchain', newchain, chainCurrency.chain_id, chainCurrency)
        if (newchain !== -1) {
          try {
            await switchChain(connector, newchain)
            return true
          } catch (error) {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }

    }
  }
  const openSale = () => {
    console.log('openSale', itemInfo)
    if (itemInfo) {
      if (itemInfo.listing_time && itemInfo.listing_time > 0) {
        if (!quickListEditFormVisible) {
          setCurrentAsset(itemInfo);
          setOfferFormVisible(false)

          setQuickListFormVisible(false);
          setQuickListEditFormVisible(true);
        }

      } else {
        if (!quickListFormVisible) {
          setCurrentAsset(itemInfo);
          setOfferFormVisible(false)

          setQuickListFormVisible(true);
          setQuickListEditFormVisible(false);
        }

      }
    }

  };
  const openOffer = async () => {
    if (!account) {
      setToastOpen({open:true,type:'error',label:"Plase connect wallet" })

      return
    }
    if (!await isChainTrue()) {
      // mes.open({ type: "error", content: "chain error" });
      return;
    }
    if (itemInfo) {
      setCurrentAsset(itemInfo);
      setOfferFormVisible(true)

      setQuickListFormVisible(false);
      setQuickListEditFormVisible(false);
    }

  };
  const fullfillOrder = async (order: OrderWithCounter, hash: string, proaddress: string) => {
    if (!account) {
      setToastOpen({open:true,type:'error',label:"Plase connect wallet" })

      return
    }
    if (!await isChainTrue()) {
      return;
    }
    if (isLoading) {
      setToastOpen({open:true,type:'error',label:"Loading..." })
      return
    }
    setIsLoading(true)
    try {
      const seaport = new Seaport(provider as any);
      console.log("order", order, order.signature)
      console.log("account", account)
      if (order.signature) {
        const { executeAllActions: executeAllFulfillActions } =
          await seaport.fulfillOrder({
            order,
            accountAddress: account,
          });

        const transaction = await executeAllFulfillActions();
        console.log(transaction);
        setIsLoading(false)
      } else {
        
        let { data, success } = await getFulfillmentData({ fulfiller: { address: account }, listing: { chain: params.chain_identifier as string, hash: hash, protocol_address: proaddress } }).unwrap()
        if (success) {
          console.log('data', data)
          // let params={...order,...{signature:data.fulfillment_data.orders[0].signature}}
          let params=data.fulfillment_data.orders[0]
          console.log('params', params)

          const { executeAllActions: executeAllFulfillActions } =
            await seaport.fulfillOrder({
              order:params,
              accountAddress: account,
            });

          const transaction = await executeAllFulfillActions();
          console.log(transaction);
          setIsLoading(false)
        }else{
          setIsLoading(false)
        }
      }

    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message })

      setIsLoading(false)
    }

  };
  const cancelOrder = async (order: OrderComponents[]) => { //取消
    if (!account) {
      setToastOpen({open:true,type:'error',label:"please connect wallet"  })

      return
    }
    if (!await isChainTrue()) {
      return;
    }
    if (isLoading) {
      setToastOpen({open:true,type:'error',label:"Loading..."  })

      return
    }
    setIsLoading(true)
    try {
      const seaport = new Seaport(provider as any);
      console.log("order", order)
      console.log(" seaport.cancelOrders", seaport.cancelOrders)
      const { transact } =
        await seaport.cancelOrders(
          order, account,
        );
      console.log('transact', transact);

      const transaction = await transact();
      console.log('transaction', transaction);
      setIsLoading(false)
    } catch (err) {
      setToastOpen({open:true,type:'error',label:(err as Error).message  })

      console.log("error", err)
      setIsLoading(false)
    }
  };

  return (
    <>
      {Number(clientWidth) < 801 ? (
        <DetailForm
          fullfillOrder={fullfillOrder}
          cancelOrder={cancelOrder}
          value={detailForm}
          openSale={openSale}
          openOffer={openOffer}
          onChange={() => getRefreshMetadata()}
        ></DetailForm>
      ) : (
        <DetailFormWeb
          fullfillOrder={fullfillOrder}
          cancelOrder={cancelOrder}
          openSale={openSale}
          openOffer={openOffer}
          value={detailForm}
          onChange={() => getRefreshMetadata()}
        ></DetailFormWeb>
      )}
      <LoadModal
        isOpen={isLoading}
        onBackgroundClick={() => setIsLoading(false)}
        onEscapeKeydown={() => setIsLoading(false)}
      >
        <LoadIcon src={darkMode ? refreshN : refresh} style={{
          width: '20px',
        }} />
        <div>       loading...
        </div>
      </LoadModal>
      <StyledModal
        modalTitle={'Make an offer'}
        isOpen={offerFormVisible}
        onDismiss={() => {
          setOfferFormVisible(false)
          setQuickListFormVisible(false);
          setQuickListEditFormVisible(false);
          setCurrentAsset(null);
        }}
      >
        {currentAsset && (
          <MakeAnOffer
            value={{
              chain_identifier: currentAsset.chain_identifier,
              contract_address: currentAsset.contract_address,
              token_id: currentAsset.token_id,
              asset: currentAsset,
              floorPrice: listings1?.[0]?.price ? priceText(listings1[0].price) : undefined,
              offerPrice: offers?.[0]?.price ? priceText(offers[0].price) : undefined,
            }}
            onChange={(value) => {
              // const protocol = SEADPORT_ADDRESS[chainId as number]
              // createlistingOrder({ order: value.order, orderHash: value.orderHash, chain: currentAsset.chain_identifier, protocol })
              // setQuickListFormVisible(false)

            }
            }
          />
        )}
        {/* <QuickListForm value={quickListForm} onChange={(value:any) => setQuickListForm({ ...quickListForm, ...value })} /> */}
      </StyledModal>
      <StyledModal
        modalTitle={'Quick list'}
        isOpen={quickListFormVisible}
        onDismiss={() => {
          setOfferFormVisible(false)
          setQuickListFormVisible(false);
          setQuickListEditFormVisible(false);
          setCurrentAsset(null);
        }}
      >

<Box margin={"20px"}>
        {currentAsset && (
          <QuickList
            value={{
              chain_identifier: currentAsset.chain_identifier,
              contract_address: currentAsset.contract_address,
              token_id: currentAsset.token_id,
            }}
            onChange={(value) => {
              const protocol = SEADPORT_ADDRESS[chainId as number]
              createlistingOrder({ order: value.order, orderHash: value.orderHash, chain: currentAsset.chain_identifier, protocol })
              setQuickListFormVisible(false)
              setNext(next + 1)
            }
            }
          />
        )}
        </Box>
        {/* <QuickListForm value={quickListForm} onChange={(value:any) => setQuickListForm({ ...quickListForm, ...value })} /> */}
      </StyledModal>
      <StyledModal
        modalTitle={'Edit listing'}
        isOpen={quickListEditFormVisible}
        onDismiss={() => {
          setOfferFormVisible(false)
          setQuickListEditFormVisible(false);
          setQuickListFormVisible(false);
          setCurrentAsset(null);
        }}
      >
        {currentAsset && (
          <QuickListEdit
            value={{
              chain_identifier: currentAsset.chain_identifier,
              contract_address: currentAsset.contract_address,
              token_id: currentAsset.token_id,
            }}
            onClose={() => {
              setQuickListEditFormVisible(false);
            }}
            onChange={(value) => {
              const protocol = SEADPORT_ADDRESS[chainId as number]
              updatelistingOrder({ order: value.order, orderHash: value.orderHash, chain: currentAsset.chain_identifier, protocol })
              setQuickListEditFormVisible(false)
              setNext(next + 1)
            }
            }
          />
        )}
      </StyledModal>
    </>
  );
}
