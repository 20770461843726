import { Assets, Collection, CollectionInfo, Item, Links, User } from 'types';
import { emptySplitApi } from './emptySplitApi'


export interface CreateCollectionResponse {
  data: {
    user: User;
    token: string;
  };

  msg: null | string;
  code: "fail" | "ok";
}

export interface CreateCollectionRequest {
  logo: string;
  featuredImage: string | null;
  bannerImage: string | null;
  name: string;
  url: string | null;
  description: string | null;
  category: string[] | null;
  links: Links;
  blockchain: string | null
}


export interface CollectionListResponse {

  data: Collection[] | null
  total: number;
  msg: null | string;
  success: boolean;
}
export interface ItemListResponse {

  data: Item[] | null
  total: number;
  msg: null | string;
  success: boolean;
}


export interface OrderResponse {
  data: {
    ask_list: [],
    bid_list: []
  };
  total: number;
  msg: null | string;
  success: boolean;
}
export interface CollectionInfoResponse {
  data: CollectionInfo;
  msg: null | string;
  success: boolean;
}


export interface CollectionItemInfoResponse {
  data: Item;
  message: null | string;
  success: boolean;
}

export interface ItemsResponse {
  data: Item[]

  msg: null | string;
  code: "fail" | "ok";
}

// export interface CollectionListRequest {
//   logo: string;
//   featuredImage: string | null;
//   bannerImage: string | null;
//   name: string;
//   url: string | null;
//   description: string | null;
//   category: string[] | null;
//   links: Links;
//   blockchain:string|null
// }

export interface CollectionListRequest {
  page: number;
  pageSize: number | null;
  sort?: string;
  featured?: boolean;
}
export interface RecommedListRequest {
  page: number;
  pageSize: number | null;
  chain: string;
  contractAddress: string;
  tokenId: string;
}





export interface TokenListRequest {
  current: number;
  pageSize: number | null;
  sort: string;
  order: string
}
export interface CollectionInfoRequest {
  chain: string;
  contract_address: string;
}
export interface CollectionItemInfoRequest {
  chainIdnetifier: string;
  contractAddress: string;
  tokenID: string
}



const assetsApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({

    myAssets: builder.mutation<{

      data: Assets[];
      total: number;
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/assets?current=${params.page}&pageSize=${params.pageSize}&ownerAddress=${params.owner}&chainIdentifier=${params.chain}&hidden=${params.hidden}`,
        method: "GET",
      }),
    }),
    assetsHide: builder.mutation<{

      message: null | string;
      status: string;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/assets/${params.chain}/${params.contract_address}/${params.token_id}/hidden`,
        method: "PUT",
        body:params
      }),
    }),
    assetsListHide: builder.mutation<{

      message: null | string;
      status: string;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/assets`,
        method: "PUT",
        body:params
      }),
    }),
    assetsList: builder.query<{

      data: Assets[];
      total: number;
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/assets${params.assets}`,
        method: "GET",
      }),
    }),
    assetsInfo: builder.query<{

      data: Assets;
      total: number;
      message: null | string;
      success: boolean;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/assets/${params.chain}/${params.contract_address}/${params.token_id}`,
        method: "GET",
      }),
    }),
    refreshMetadata: builder.mutation<{

      data: Assets;
      message: null | string;
      status: string;

    }, Record<string, any>>({
      query: (params) => ({
        url: `/assets/${params.chain}/${params.contract_address}/${params.token_id}/metadata-refresh`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useMyAssetsMutation,
  useAssetsListQuery,
  useAssetsInfoQuery,
  useAssetsHideMutation,
  useAssetsListHideMutation,
  useRefreshMetadataMutation
} = assetsApi;
