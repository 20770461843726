import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'

import { setCurrent } from './reducer'
import { ChainInfo } from 'types'


export function useSupportChains(): [ChainInfo[], (chain: ChainInfo) => void] {
  const dispatch = useAppDispatch()
  const supportChains = useAppSelector((state) => state.chain.supportChains)
  const setCurrentChain = useCallback(
    (chain: ChainInfo) => {
      dispatch(setCurrent(chain))
    },
    [dispatch]
  )
  return [supportChains, setCurrentChain]
}

export function useSelectChain(chain_identifier:string): ChainInfo|null {
    const supportChains = useAppSelector((state) => state.chain.supportChains)
     const a= supportChains.filter(e=>e.id===chain_identifier)
     return a[0]

  }