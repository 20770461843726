import { ActivityItem, Assets, Collection, CollectionInfo, Item, Links, User } from 'types';
import { emptySplitApi } from './emptySplitApi'


export interface CreateCollectionResponse {
  data: {
    user: User;
    token: string;
  };

  msg: null | string;
  code: "fail" | "ok";
}

export interface CreateCollectionRequest {
  logo: string;
  featuredImage: string | null;
  bannerImage: string | null;
  name: string;
  url: string | null;
  description: string | null;
  category: string[] | null;
  links: Links;
  blockchain: string | null
}


export interface CollectionListResponse {

  data: Collection[]|null
  total: number;
  msg: null | string;
  success: boolean;
}
export interface ItemListResponse {

  data: Item[]|null
  total: number;
  msg: null | string;
  success: boolean;
}
export interface AssetsListResponse {

  data: Assets[]|null
  total: number;
  msg: null | string;
  success: boolean;
}


export interface OrderResponse {
  data: {
    ask_list:[],
    bid_list:[]
  };
  total: number;
  msg: null | string;
  success: boolean;
}
export interface CollectionInfoResponse {
  data: CollectionInfo;
  msg: null | string;
  success: boolean;
}


export interface CollectionItemInfoResponse {
  data: Assets;
  message: null | string;
  success: boolean;
}

export interface ItemsResponse {
  data: Item[]

  msg: null | string;
  code: "fail" | "ok";
}

// export interface CollectionListRequest {
//   logo: string;
//   featuredImage: string | null;
//   bannerImage: string | null;
//   name: string;
//   url: string | null;
//   description: string | null;
//   category: string[] | null;
//   links: Links;
//   blockchain:string|null
// }

export interface CollectionListRequest {
  page: number;
  pageSize: number | null;
  sort?: string;
  featured?:boolean;
  chain?: string;
  isRefresh?: number;
  direct?: string;
  name?: string;
}
export interface RecommedListRequest {
  page: number;
  pageSize: number | null;
  chain: string;
  contractAddress: string;
  tokenId: string;
}





export interface TokenListRequest {
  current: number;
  pageSize: number | null;
  sort: string;
  order: string
}
export interface CollectionInfoRequest {
  chain: string;
  contract_address:string;
}
export interface CollectionItemInfoRequest {
  chainIdnetifier: string;
  contractAddress: string;
  tokenID: string;
  next?:number
}



const collectionApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({

    createCollection: builder.mutation<CreateCollectionResponse, CreateCollectionRequest>({
      query: (collection) => ({
        url: `/my/collection`,
        method: "POST",
        body: { ...collection },
      }),
    }),
    myCollection: builder.query<CollectionListResponse, any>({
      query: () => ({
        url: `/my/collection`,
        method: "GET",
      }),
    }),
    importCollection: builder.mutation<{ 
      data: {
        id:string
      };
      total: number;
      message: null | string;
      success: boolean;}, Record<string, any>>({
      query: (params) => ({
        url: `/my/submit-collection`,
        method: "POST",
        body: { ...params }

      }),
    }),
    collectionList: builder.query<CollectionListResponse, CollectionListRequest>({
      query: (params) => ({
        url: `/collection?page=${params.page}&pageSize=${params.pageSize}${params?.chain?'&chain='+params.chain:''}${params?.sort?'&sort='+params.sort:''}${params?.featured?'&featured='+params.featured:''}${params?.direct?'&direct='+params.direct:''}${params?.name?'&name='+params.name:''}`,
        method: "GET",
      }),
    }),
    collectionListM: builder.query<CollectionListResponse, Record<string, any>>({
      query: (params) => ({
        url: `/collection?page=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}`,
        method: "GET",
      }),
    }),
    recommendList: builder.query<AssetsListResponse, RecommedListRequest>({
      query: (params) => ({
        url: `/assets-recommend?current=${params.page}&pageSize=${params.pageSize}&chain=${params.chain}&contractAddress=${params.contractAddress}&tokenId=${params.tokenId}`,
        method: "GET",
      }),
    }),
    orderList: builder.query<OrderResponse, CollectionItemInfoRequest>({
      query: (params) => ({
        url: `/assets/${params.chainIdnetifier}/${params.contractAddress}/${params.tokenID}/order`,
        method: "GET",
      }),
    }),
    changeCollectHidden: builder.mutation<{
      message: string | null,
      status: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/collection/${params.chain}/${params.address}/hidden`,
        method: "PUT",
        body: { ...params }
      }),
    }),
    changeCollectFeatured: builder.mutation<{
      message: string | null,
      status: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/collection/${params.chain}/${params.address}/featured`,
        method: "PUT",
        body: { ...params }
      }),
    }),
   
    activityList: builder.mutation<{

        data: ActivityItem[]
        total: number;
        message: null | string;
        success: boolean;
      
    }, Record<string, any>>({
      query: (params) => ({
        url: `/activity?page=${params.current}&pageSize=${params.pageSize}&chain=${params.chain}&contract_address=${params.address}${params?.tokenid?'&token_id='+params?.tokenid:''}${params.type?'&type='+params.type:''}`,
        method: "GET",
      }),
    }),
   
    collectionItemFilter: builder.mutation<ItemListResponse, Record<string, any>>({
      query: (params) => ({
        url: `/collection/${params.chain}/${params.contract_address}/items-filter`,
        method: "POST",
        body: { ...params }
      }),
    }),
    updateCollection: builder.mutation<{
      data:CollectionInfo,
      status:string|null,
      Message:string|null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/my/collection/${params.chain}/${params.contract_address}`,
        method: "PUT",
        body: { ...params,chain:undefined,contract_address:undefined }
      }),
    }),
    delCollection: builder.mutation<{
      data:CollectionInfo,
      status:string|null,
      message:string|null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/my/collection/${params.chain}/${params.contract_address}`,
        method: "DELETE",
      }),
    }),

  
    collectionInfo: builder.query<CollectionInfoResponse, CollectionInfoRequest>({
      query: (params) => ({
        url: `/collection/${params.chain}/${params.contract_address}`,
        method: "GET"
      }),
    }),
    collectionItemInfo: builder.query<CollectionItemInfoResponse, CollectionItemInfoRequest>({
      query: (params) => ({
        url: `/assets/${params.chainIdnetifier}/${params.contractAddress}/${params.tokenID}`,
        method: "GET"
      }),
    }),
  }),
});

export const {
  useChangeCollectHiddenMutation,
  useChangeCollectFeaturedMutation,
  useCreateCollectionMutation,
  useMyCollectionQuery,
  useOrderListQuery,
  useRecommendListQuery,
  useCollectionListQuery,
  useCollectionListMQuery,

  useCollectionItemFilterMutation,

  useCollectionInfoQuery,

  useCollectionItemInfoQuery,
  useDelCollectionMutation,
  useActivityListMutation,
  useUpdateCollectionMutation,
  useImportCollectionMutation,
  
} = collectionApi;
