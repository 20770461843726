import { useNavigate } from "react-router-dom";

import { useAccountDrawer } from 'components/AccountDrawer'
import { useEffect, useState, useCallback } from "react";
import {useToastOpen } from 'state/stats/hooks'

import { useAppDispatch } from 'state/hooks'
import { useWeb3React } from "@web3-react/core";
import { useAccount, useUpdateAccount } from "state/user/hooks";
import ListSelect from "components/ListSelect";
import {
  WebMainTop,
  MainInfo,
  InfoImg,
  AttesImg,
  InfoNameRow,
  InfoNameCol,
  AddressText,
  NameText, ListSelectBlock,
  InfoDescLoad, TabItemWeb, TabWeb, MainInfoTop,
} from "../../components_";
import copyIcon from "assets/nft_copy.png";
import {
  MainBottom,
} from "pages/Nft/NftItems/components_"
import { useClientWidth } from "state/application/hooks";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { useCurrentAccountMutation } from 'apis/account';
import { User } from "types";

interface TabProps {
  current: "offers" | "collected" | "deals" | "hide";
}

export default function AccountTop({ current }: TabProps) {
  const [acc, setAccount] = useUpdateAccount()
  const { account } = useWeb3React();

  const [,,, setToastOpen] = useToastOpen();
  const [currentAccount] = useCurrentAccountMutation()
  const clientWidth = useClientWidth();
  let navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [addressStr, setAddressStr] = useState("");

  const [isInfoLoad, setIsInfoLoad] = useState(true);
  const [user, setUser] = useState<User>();
  const [walletDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const getAccountInfo = async () => {
    try {
      const { data, success, message } = await currentAccount({ account: account as string }).unwrap();
      if (success) {
        setAccount(data)
      } else {
        setToastOpen({open:true,type:'error',label:message})

      }
    } catch (err) {
      console.log('err', err)
      setToastOpen({open:true,type:'error',label:(err as Error).message})

    }


  }
  const toPage = (url:string) => {
    if(account){
      navigate(url)
    }else{
      setToastOpen({open:true,type:'error',label: "please connect wallet"})

    }


  }
  

  useEffect(() => {
    console.log('acc', acc)

    if (acc && account) {
      setUser(acc.user)
      let str =
        acc.address.substring(0, 7) +
        "..." +
        acc.address.substr(
          acc.address.length - 5
        );
      setAddress(str);
      setAddressStr(acc.address)
      setIsInfoLoad(false)
    } else if (!acc && account) {
      getAccountInfo()
    }
    //  else if (!acc) {
    //   mes.open({ type: "error", content: "please connect wallet" });

    // }
  }, [acc, account]);




  return (
    <>
      <WebMainTop>
        <MainInfo>
          <MainInfoTop>
            <div> <InfoImg src={user?.avatar || ''} /></div>
            <div>
              <InfoNameRow >
                <InfoNameCol style={{ cursor: 'auto' }}>
                  {isInfoLoad ? (
                    <InfoDescLoad style={{ width: "200px" }}>{'   '}</InfoDescLoad>
                  ) : (
                    <>
                      <NameText>{user?.nickname}</NameText>
                      {/* <AttesImg src={attestationIcon} alt="" /> */}
                    </>
                  )}
                </InfoNameCol>

              </InfoNameRow>
              {isInfoLoad ? (
                <InfoDescLoad style={{ width: "10%" }}>{'   '}</InfoDescLoad>
              ) : (
                <InfoNameRow>
                  <InfoNameCol onClick={() =>{ navigator.clipboard.writeText(addressStr);setToastOpen({open:true,type:'success',label:"success"})}}>
                    <AddressText>{address}</AddressText>
                    <AttesImg src={copyIcon} alt="" />
                  </InfoNameCol>
                  <InfoNameCol><AddressText style={{ cursor: 'auto' }}>Joined {acc?.created_at?.split('T')[0]}</AddressText></InfoNameCol>
                  <InfoNameCol style={{ flex: '1' }}></InfoNameCol>
                </InfoNameRow>
              )}

            </div>
          </MainInfoTop>




        </MainInfo>
        <MainBottom>
          <TabWeb>
            <div>
              <TabItemWeb isAct={current === 'collected'} onClick={() => toPage(`/account/${account}/collected`)}>Collected</TabItemWeb>
              <TabItemWeb isAct={current === 'offers'} onClick={() => toPage(`/account/${account}/offers`)}>Offers made</TabItemWeb>
              <TabItemWeb isAct={current === 'deals'} onClick={() => toPage(`/account/${account}/deals`)}>Deals</TabItemWeb>

              {current === 'hide' || Number(clientWidth) < 801 ? (<TabItemWeb isAct={current == 'hide'} onClick={() => toPage(`/account/${account}/hide`)}>Hide</TabItemWeb>) : null}

              <ListSelectBlock>
                <ListSelect
                  value={'More'}
                  optionArr={["Hide"]}
                  typeValues={["hide"]}
                  onChange={() => toPage(`/account/${account}/hide`)}
                  size=""
                /></ListSelectBlock>
            </div>



          </TabWeb>
        </MainBottom>
      </WebMainTop>
    </>
  );
}
