import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useClientWidth } from "state/application/hooks";
import { useGetDropListQuery } from "apis/drop";
import { priceText } from "utils"
import Image from "components/Image"
import { TopBannerBlock, PageCenter, InfoArea, InfoBtns, InfoBtn, InfoBtnIcon, AvatarImg, FlexText, NameIcon, TopBannerBgNone, InfoBtnIconNone } from "../../components_";
import nftDefault from "assets/nft/nft_defult.png";
import dropNameIcon from "assets/nft/drop_name_icon.png";
import { DropInfo, MintStage } from "types";

export default function Home() {
  let navigate = useNavigate();
  const [bannerDrop, setBannerDrop] = useState<DropInfo | null>(null);
  const [isLoad, setIsLoad] = useState(true);
  const [isMint, setIsMint] = useState(false);
  const [mintText, setMintText] = useState('Minting now');
  const [publicStage, setPublicStage] = useState<MintStage>();
  const { data, status } = useGetDropListQuery({
    page: 1,
    pageSize: 1,
    featured: true
  });
  useEffect(() => {
    console.log('datadata', data)
    if (status === 'fulfilled' && data?.data) {
      const drop = data.data[0]
      setBannerDrop(drop);
      let index = -1
      drop.settings?.stages.forEach((stage, stageindex) => {
        if (stage.is_public) {
          index = stageindex
        }
      })
      if (index === -1) {
        setIsMint(false)
        setMintText('stage data errors')
        setIsLoad(false)
        return
      } else {
        setPublicStage(drop.settings?.stages[index])
      }
      let day = new Date(drop.settings?.mint_start_time || '');
      let timestamp = Date.parse(day.toString());

      const firstStage = drop.settings?.stages[0]
      let starttime = firstStage?.start_time || 0
      let endtime = drop.settings?.stages[index]?.end_time || 0

      let nowtime = Date.parse(new Date().toString());

      console.log('nowtimenowtime', nowtime)
      console.log('endtimeendtime', endtime, new Date(endtime * 1000))

      if (nowtime > starttime && nowtime < (endtime * 1000)) {
        setIsMint(true)
        setMintText('Minting now')
      } else if (nowtime > (endtime * 1000)) {
        setIsMint(false)
        setMintText('Mint ended')
      }
      if (data.data[0].settings?.edition === 'limited' && data.data[0].settings?.max_supply === data.data[0].minted) {
        setIsMint(false)
        setMintText('Mint sold out')
      }
      setIsLoad(false)
    }
  }, [data, status]);
  const clientWidth = useClientWidth();
  return (
    <>
      {isLoad ? (<TopBannerBgNone style={{ width: clientWidth, height: '500px' }} />) : (<>

        <PageCenter>
          <TopBannerBlock>
            <Image src={Number(clientWidth) > 800 ? bannerDrop?.drop_page?.pc_banner_image_url || nftDefault : bannerDrop?.drop_page?.mobile_banner_image_url || bannerDrop?.drop_page?.pc_banner_image_url || nftDefault} alt="" style={Number(clientWidth) < 801 ? { maxHeight: '500px', width: '100%', height: '100%', objectFit: 'cover' } : { maxHeight: '500px', width: '100%', objectFit: 'cover' }} />
          </TopBannerBlock>
          <InfoArea>
            <AvatarImg src={bannerDrop?.drop_page?.logo_url || nftDefault} />
            <div>
              <FlexText>
                <div>
                  {bannerDrop?.pre_reveal?.name || '--'}

                </div>
              </FlexText>
              <FlexText>
                <div style={{ fontSize: "17px" }}>{bannerDrop?.owner?.user?.nickname || '--'}</div>
                <NameIcon src={dropNameIcon} />

              </FlexText>

              <div style={{ fontSize: "15px", marginTop: "6px" }}>

                {bannerDrop?.settings?.edition} edition  {bannerDrop?.settings?.stages?.[0].sale_price ? priceText(bannerDrop.settings.stages[0].sale_price) : '--'}
              </div>
            </div>
            <InfoBtns>
              <InfoBtn onClick={() => navigate(`/nfts/collection/${bannerDrop?.chain}/${bannerDrop?.contract_address}/overview`)}>
                {isMint ? <InfoBtnIcon></InfoBtnIcon> : <InfoBtnIconNone></InfoBtnIconNone>}

                <div>{mintText}</div>
              </InfoBtn>
              <InfoBtn onClick={() => navigate(`/nfts/collection/${bannerDrop?.chain}/${bannerDrop?.contract_address}/overview`)}>
                <div>view drop</div>
              </InfoBtn>
            </InfoBtns>

          </InfoArea>
        </PageCenter>

      </>)}


    </>
  );
}
