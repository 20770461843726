import { useWeb3React } from "@web3-react/core";
import { useAddWatchListMutation, useDelWatchOneMutation, useTrendingListQuery, useWatchListIdsQuery } from "apis/ranking";
import sortFilled from "assets/tool/sort_filled.png";
import sortIcon from "assets/tool/sort_icon.png";
import ChainSelect from "components/ChainSelect";
import NoData from "components/NoData";
import PeriodSelect from "components/PeriodSelect";
import {
  MainBB, MainBottom,
  WebMain,
} from "pages/Nft/NftItems/components_";
import { useEffect, useState } from "react";
import { ApplicationModal, setOpenModal } from 'state/application/reducer';
import { useAppDispatch } from 'state/hooks';
import { useCollectionTrendingChain, useCollectionTrendingPeriod, useToastOpen } from 'state/stats/hooks';
import { useToken } from 'state/user/hooks';
import {
  CardTableMain,
  CardTableTitleColFirst,
  CardTableTitleColLast,
  CardTableTitleColTop, CardTableTitleColTop1,
  CardTableTitleRow,
  ChainSelectBlock,
  FilterLine,
  OrderImg,
  PageLine,
  WebAll
} from "../components_";

import CardTableLoadRow from "../components/CardTableLoadRow/index";
import CardTableMainRow from "../components/CardTableMainRow/topIndex";
import Tab from "../components/Tab";
import { TopCollection } from "types";


export function Trending() {
  const dispatch = useAppDispatch()
  const [delWatchOne] = useDelWatchOneMutation()
  const [,,, setToastOpen] = useToastOpen();

  const [collectionTrendingChain,setCollectionTrendingChain] = useCollectionTrendingChain()
  const [collectionTrendingPeriod,setCollectionTrendingPeriod] = useCollectionTrendingPeriod()
  const token = useToken();
  const { account } = useWeb3React();
  const [addWatchList] = useAddWatchListMutation()
  const [page, setPage] = useState(1);
  const [sortby, setSortby] = useState("volumn");
  const [direct, setDirect] = useState("desc");
  const [total, setTotal] = useState(0);
  const [trendList, setTrendList] = useState<TopCollection[]>([]);
  const [watchIdMap, setWatchIdMap] = useState<Record<string, any>>();
  const [loadList] = useState([1,2,3,4,5,6,7,8,9,10,11]);

  const [next, setNext] = useState<number>(1);//再次获取watchids
  const handleChainChange = (value: string) => {
    setCollectionTrendingChain(value);
  };
  const handleTimeChange = (value: string) => {
    setCollectionTrendingPeriod(value);
  };

  const {
    data: trendingListResult,
    status,
  } = useTrendingListQuery({
    page: page,
    pageSize: 82,
    sort: sortby,
    period: collectionTrendingPeriod||'',
    chain: collectionTrendingChain||'',
    direct: direct
  });
  const { data: watchIdsResult } = useWatchListIdsQuery({ account: account,type:'NFT', next: next });
  const getSign = () => {
    dispatch(setOpenModal(ApplicationModal.ACCEPT_SIGN));
  }
  const addOne = async (i: TopCollection, index: number) => {
    if (token) {
      const res = await addWatchList({
        chain: i.chain,
        address: i.contract_address,
        type:'NFT'
      }).unwrap();
      if (res.success) {
        setToastOpen({open:true,type:'success',label:"success"})
        setNext(next + 1)
      } else {
        setToastOpen({open:true,type:'error',label: res.msg})
      }
    } else {//没sign
      getSign()
    }
  }
  const delOne = async (i: TopCollection, index: number) => {
    if (token) {
      const res = await delWatchOne({
        chain: i.chain,
        contract_address: i.contract_address
      }).unwrap();
      if (res.success) {
        setToastOpen({open:true,type:'success',label:"success"})
        setNext(next + 1)
      } else {
        setToastOpen({open:true,type:'error',label: res.msg})

      }
    } else {//没sign
      getSign()
    }
  }

  useEffect(() => {
    var watchIdMap: Record<string, any> = {}
    watchIdsResult && watchIdsResult.data && watchIdsResult.data.forEach((v) => {
      watchIdMap[v] = true
    })
    setWatchIdMap(watchIdMap)

  }, [watchIdsResult])

  //监听拉取数据变化，处理出渲染数据。
  useEffect(() => {
    const newTrendList: TopCollection[] = [];

    if (trendingListResult && trendingListResult.data) {
      if (watchIdMap) {
        trendingListResult.data.forEach((e) => {

          var iswatch = false;          
          let id=e.chain+'/'+e.contract_address

          if (watchIdMap[id.toString()]) {
            iswatch = true;
          }
          newTrendList.push({ ...e, iswatch });
        });
      } else {
        trendingListResult.data.forEach((e) => {
          newTrendList.push({ ...e, iswatch: false });
        });
      }
    }
    setTrendList(newTrendList);
  }, [watchIdMap, trendingListResult]);


  useEffect(() => {
    setPage(1);
  }, [ collectionTrendingPeriod, sortby, direct, collectionTrendingChain]);
  const changeSort = (str: string) => {
    if (sortby === str) {
      if (direct === "asc") {
        setPage(1);
        setDirect("desc");
      } else if (direct === "desc") {
        setPage(1);
        setSortby("");
        setDirect("");
      } else if (direct === "") {
        setPage(1);
        setDirect("asc");
      }
    } else {
      setPage(1);
      setSortby(str);
      setDirect("asc");
    }
  };

  return (
    <>
      <WebAll>
        <WebMain>
          <PageLine>Collections Stats</PageLine>

          <MainBottom>
            <Tab current="trending" />

            <MainBB
              style={{ display: "flex", flexDirection: "column" }}
            >
              <FilterLine>
                <ChainSelectBlock>

                  <ChainSelect
                    value={collectionTrendingChain||''}
                    onChange={handleChainChange}
                    size="big"
                  />
                </ChainSelectBlock>
                <PeriodSelect
                  value={collectionTrendingPeriod||''}
                  onChange={handleTimeChange}
                  size="big5"
                />
              </FilterLine>
              <CardTableTitleRow>
                <CardTableTitleColFirst>Collection</CardTableTitleColFirst>
                <CardTableTitleColTop1
                  onClick={() => {
                    changeSort("volume");
                  }}
                >
                  <div>Volume</div>
                  <OrderImg
                    src={sortby === "volume" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop1>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("volume_change");
                  }}
                >
                  <div>Vol Change</div>
                  <OrderImg
                    src={sortby === "volume_change" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("floor_price_change");
                  }}
                >
                  <div>Floor Change</div>
                  <OrderImg
                    src={sortby === "floor_price_change" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("floor_price");
                  }}
                >
                  <div>Floor price</div>
                  <OrderImg
                    src={sortby === "floor_price" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>
                <CardTableTitleColTop
                  onClick={() => {
                    changeSort("sales");
                  }}
                >
                  <div>Sales</div>
                  <OrderImg
                    src={sortby === "sales" ? sortFilled : sortIcon}
                    style={
                      direct === "desc" ? {} : { transform: "rotate(180deg)" }
                    }
                  />
                </CardTableTitleColTop>                <CardTableTitleColTop style={{ cursor: 'auto' }}>Owners</CardTableTitleColTop>
                <CardTableTitleColLast></CardTableTitleColLast>
              </CardTableTitleRow>
              {status === "fulfilled" ? (!trendList || trendList.length < 1 ? (<NoData onChange={() => setCollectionTrendingChain('')}></NoData>) : <CardTableMain>
                {trendList?.map((i: TopCollection, index: number) => (
                  <CardTableMainRow
                  i={i}
                  iswatch={i.iswatch||false}
                  type={""}
                  onDelete={() => { delOne(i, index) }}
                  onAdd={() => addOne(i, index)}
                />
                ))}
              </CardTableMain>) : (<CardTableMain>
                {loadList?.map(() => (
                  <CardTableLoadRow />
                ))}
              </CardTableMain>)}


            </MainBB>

          </MainBottom>
        </WebMain>
      </WebAll>
    </>
  );
}
