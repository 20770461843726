import { useWeb3React } from "@web3-react/core";
import {  SEADROP_FEE_ADDRESS } from "constants/addresses";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAccount } from "../../../state/user/hooks";
import DetailForm, { CollectionDetail } from "./components/DetailForm";
import EarningsForm, { CollectionEarnings } from "./components/EarningsForm";
import ImagesForm, { CollectionImages } from "./components/ImagesForm";
import LinksForm, { CollectionLinks } from "./components/LinksForm";

import { useGetChainQuery } from "apis/chain";
import erc721SeaDropABI from "abis/erc721SeaDrop.json";
import {useToastOpen } from 'state/stats/hooks'
import { ButtonBlock, ButtonPurple } from "components/Button";
import {
  useErc721SeaDropContract,
  useSeaDropContract
} from "hooks/useContract";

import {
  useCollectionInfoQuery,
  useUpdateCollectionMutation,
} from "apis/collection";
import {
  useGetAllowListMutation,
  useGetDropDraftMutation,
  useSaveDropDraftMutation,
  useSaveDropPubilshMutation,
} from "apis/drop";
import {
  allowListElementsBuffer,
  createMerkleTree,
} from "utils/merkletree";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import DropEarningsForm, { DropEarnings } from "./components/DropEarningsForm";
import PreRevealForm, { PreReveal } from "./components/PreRevealForm";
import SettingsForm from "./components/SettingsForm";

import menuIcon from "assets/h5/menu.png";
import menuIconN from "assets/h5/menu_n.png";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import arrowL from "../../../assets/tool/arrow_u.png";
import arrowLN from "../../../assets/tool/arrow_u_n.png";
import DropPageForm from "./components/DropPageForm";
import { DropPage } from "./components/DropPageForm/types";
import {
  BackLine,
  DropBtn,
  MenuDiv,
  RightBottom,
  RightBottomMain,
  TopImg,
  TopLine,
  TopText,
  TopText1,
  TopText2,
  WebLeft,
  WebMain,
  WebRight,
} from "./components_";

import {
  ERC721SeaDropStructsErrorsAndEvents,
  PublicDropStruct,
} from "abis/types/Erc721SeaDrop";
import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import Menu from "./components/Menu";
import AllowSeadropModal from "components/AllowSeadropModal";
import { useAllowlist } from "hooks/useSeadrop";
import { ApplicationModal, setOpenModal } from "state/application/reducer";
import { useAppDispatch } from "state/hooks";
import { CollectionInfo, DropInfo, DropSettings, MintStage } from "types";

const collectionMenus: { name: string; label: string }[] = [
  {
    name: "Details",
    label: "Details",
  },
  {
    name: "Graphics",
    label: "Graphics",
  },
  {
    name: "Earnings",
    label: "Earnings",
  },
  {
    name: "Links",
    label: "Links",
  },
];
const DropFormMenu = [
  { name: "Settings", label: "Settings" },
  { name: "Pre-reveal", label: "Pre-reveal" },
  { name: "DropEarnings", label: "Earnings" },
  { name: "Drop page", label: "Drop page" },
];

const defaultPublicStage: MintStage = {
  start_time: 0,
  end_time: 0,
  duration: 0,
  is_public: true,
  name: "Public Stage",
  per_wallet_mint_limit: 0,
  sale_price: {
    currency: {
      chain: "",
      contract_address: "",
      decimals: 0,
      image_url: "",
      name: "",
      symbol: "",
    },
    value: "",
  },
};

const defaultSetting: DropSettings = {
  max_supply: 0,
  edition: '',
  mint_start_time: 0,
  stages: []
};
function getPublicStage(stages: MintStage[]): MintStage | null {
  let index = -1
  stages.forEach((stage, stageindex) => {
    if (stage.is_public) {
      index = stageindex

    }
  })
  if (index === -1) {
    return null
  } else {
    return stages[index];
  }
}

export default function EditCollection() {
  const [darkMode] = useDarkModeManager();
  const { chain, contract_address } = useParams();
  const [,,, setToastOpen] = useToastOpen();
  const dispatch = useAppDispatch()
  const [uploadCollection] = useUpdateCollectionMutation();
  const [saveDropDraft] = useSaveDropDraftMutation();
  const [saveDropPubilsh] = useSaveDropPubilshMutation();
  const [getDropDraft] = useGetDropDraftMutation();
  let navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [currentEdit, setCurrentEdit] = useState<"drop" | "collection">(
    "collection"
  );
  const [collection, setCollection] = useState<CollectionInfo>();
  const [drop, setDrop] = useState<DropInfo>();
  const [currentMenu, setCurrentMenu] = useState("Details");
  const [detail, setDetail] = useState<CollectionDetail>();
  const [images, setImages] = useState<CollectionImages>({ logoUrl: '', featuredUrl: '', bannerUrl: '', });
  const [links, setLinks] = useState<CollectionLinks>();
  const [sellerFees, setSellerFees] = useState<CollectionEarnings>();
  const [seadropImpl, setSeadropImpl] = useState<string>('');
  const [seadropAddressFee, setSeadropAddressFee] = useState<string>('');
  const [feeBps, setFeeBps] = useState(0);
  
  const [settings, setSettings] = useState<DropSettings>(defaultSetting);
  const [preReveal, setPreReveal] = useState<PreReveal>();
  const [dropEarnings, setDropEarnings] = useState<DropEarnings>();
  const [dropPage, setDropPage] = useState<DropPage>();
  const { data:chainData } = useGetChainQuery({ chain: chain as string });

  const erc721Seadrop = useErc721SeaDropContract(
    contract_address,
    erc721SeaDropABI,
    true
  );
  const allowlist = useAllowlist(
    settings,
    feeBps,
    chain as string,
    contract_address as string
  );

  const seaDrop = useSeaDropContract(seadropImpl,true);
  let [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    if(chainData?.data&&chainData?.data?.seadrop_impl!==seadropImpl){
      setSeadropImpl(chainData?.data?.seadrop_impl||'')
      setSeadropAddressFee(chainData?.data?.seadrop_fee_recipients?.[0]||'')
      setFeeBps(chainData?.data?.seadrop_fee_bps||0)
    }
  }, [chainData]);
  useEffect(() => {
    var tab = searchParams.get("tab");
    if (tab == null) {
      // let params = serializeFormQuery({tab:"Details"});
      setSearchParams("tab=Details");
    }
    console.log("tab", tab);
    if (
      tab === "Drop page" ||
      tab === "Settings" ||
      tab === "Pre-reveal" ||
      tab === "DropEarnings"
    ) {
      setCurrentEdit("drop");
    }
    tab && setCurrentMenu(tab);
  }, [searchParams]);

  const { data, status } = useCollectionInfoQuery({
    chain: chain as string,
    contract_address: contract_address as string,
  });

  const getDropDraftInfo = async (collection: CollectionInfo) => {
    let paramdata = {
      chain: collection?.chain,
      address: collection?.contract_address,
    };
    const { success, message, data } = await getDropDraft(paramdata).unwrap();
    if (success) {
      setDrop(data);
      if (data && data.settings) {
        var mint_start_time = data.settings.mint_start_time
        if (data.settings.mint_start_time === 0) {
          mint_start_time = Math.floor(Date.now() / 1000)
        }
        let stages: MintStage[] = data.settings.stages
        if (stages == null || stages.length === 0) {
          defaultPublicStage.start_time = mint_start_time
          stages = [defaultPublicStage]
        }
        let item = {
          ...data.settings, mint_start_time,
          stages
        }
        setSettings(item);
      }
      if (data && data.pre_reveal) {
        setPreReveal({
          preName: data.pre_reveal.name,
          preDesc: data.pre_reveal.description,
          preImg: data.pre_reveal.image_url,
        });
      }
      if (data && data.earnings) {
        setDropEarnings({
          dropEarning: data.earnings,
        });
      }
      if (data && data.drop_page) {
        let previewList = data.drop_page.preview_image_urls?.map((el) => {
          return { img: el };
        });
        let dropFrom: DropPage = {
          bannerUrl: data.drop_page.pc_banner_image_url,
          avatarUrl: data.drop_page.logo_url,
          bannerUrlTel: data.drop_page.mobile_banner_image_url,
          name: collection.name,
          tabs: data.drop_page.tabs,
          questList: data.drop_page.faqs,
          mapList: data.drop_page.roadmaps,
          teamList: data.drop_page.team,
          desc: data.drop_page.description,
          secList: data.drop_page.sections,
        }
        if (previewList && previewList.length > 0) {
          dropFrom.imgListUrl = previewList
        }
        setDropPage(dropFrom);
      }
    } else {
      setToastOpen({open:true,type:'error',label:message})

    }
  };

  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      if (data?.data) {
        setCollection(data.data);
        if (data.data.has_drop) {
          getDropDraftInfo(data.data);
        }
      }
    }
  }, [data, status]);

  const isDetailChange = () => {
    if (
      collection?.name !== detail?.name ||
      collection?.description !== detail?.description ||
      collection?.sensitive_content !== detail?.sensitive_content ||
      collection?.collaborators !== detail?.collaborators ||
      collection?.payment_tokens !== detail?.payment_tokens ||
      collection
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isEarningsChange = () => {
    let templist = collection?.seller_fees?.map((element: any) => {
      return {
        address: element.address,
        rate: String(element.rate * 100),
      };
    });
    if (templist !== sellerFees?.earningList) {
      return true;
    } else {
      return false;
    }
  };
  const isLinksChange = () => {
    if (
      collection?.external_link !== links?.linkex ||
      collection?.medium_url !== links?.linkme ||
      collection?.telegram_url !== links?.linkte
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isImagesChange = () => {
    if (
      collection?.image_url !== images?.logoUrl ||
      collection?.featured_image_url !== images?.featuredUrl ||
      collection?.banner_image_url !== images?.bannerUrl
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isSettingChange = () => {
    if (
      settings &&
      (drop?.settings?.edition !== settings.edition ||
        drop?.settings?.max_supply !== settings.max_supply ||
        drop?.settings?.mint_start_time !== settings.mint_start_time ||
        drop?.settings?.stages !== settings.stages)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isPreChange = () => {
    if (
      preReveal &&
      (drop?.pre_reveal?.name !== preReveal.preName ||
        drop?.pre_reveal?.description !== preReveal.preDesc ||
        drop?.pre_reveal?.image_url !== preReveal.preImg)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isDropEarnChange = () => {
    if (dropEarnings && drop?.earnings !== dropEarnings.dropEarning) {
      return true;
    } else {
      return false;
    }
  };
  const isDropPageChange = () => {
    let previewList: string[] | null = null;
    if (dropPage && dropPage.imgListUrl && dropPage.imgListUrl.length > 0) {
      previewList = dropPage.imgListUrl.map((el) => {
        return el.img;
      });
    }
    if (
      dropPage &&
      drop &&
      drop.drop_page &&
      (drop.drop_page.pc_banner_image_url !== dropPage.bannerUrl ||
        drop.drop_page.logo_url !== dropPage.avatarUrl ||
        drop.drop_page.mobile_banner_image_url !== dropPage.bannerUrlTel ||
        drop.drop_page.tabs !== dropPage.tabs ||
        drop.drop_page.faqs !== dropPage.questList ||
        drop.drop_page.roadmaps !== dropPage.mapList ||
        drop.drop_page.team !== dropPage.teamList ||
        drop.drop_page.description !== dropPage.desc ||
        JSON.stringify(drop.drop_page.preview_image_urls) !==
        JSON.stringify(previewList) ||
        drop.drop_page.sections !== dropPage.secList)
    ) {
      return true;
    } else if (
      dropPage &&
      drop &&
      !drop.drop_page &&
      (dropPage.bannerUrl ||
        dropPage.avatarUrl ||
        dropPage.bannerUrlTel ||
        dropPage.tabs ||
        dropPage.questList ||
        dropPage.mapList ||
        dropPage.teamList ||
        dropPage.desc ||
        previewList ||
        dropPage.secList)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isSaveCollect = () => {
    if (
      currentMenu === "Details" ||
      currentMenu === "Graphics" ||
      currentMenu === "Earnings" ||
      currentMenu === "Links"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isCollectionChange = () => {
    return true;
  };
  const isDropChange = () => {
    return true;
  };
  const changeImages = (value: CollectionImages) => {
    setImages(value);
  };
  useEffect(() => {
    let list: MintStage[] = [];
    let str = 0;

    setDropPage({ ...dropPage, ...{ mintSchedule: list } });
    if (settings && settings.mint_start_time) {
      str = settings.mint_start_time;
      console.log("settings.mintStartTime", str);
    }
    setDropPage({ ...dropPage, ...{ mintTime: str, mintSchedule: list } });
  }, [settings]);

  useEffect(() => {
    console.log("useEffectcollection", collection);
    if (collection) {
      const newDetail = {
        name: collection?.name,
        description: collection?.description,
        sensitive_content: collection?.sensitive_content,
        collaborators: collection?.collaborators,
        payment_tokens: collection?.payment_tokens,
      };
      console.log("setDetail", newDetail);

      setDetail(newDetail);
      const newImages = {
        logoUrl: collection?.image_url,
        featuredUrl: collection?.featured_image_url,
        bannerUrl: collection?.banner_image_url,
      };
      setImages(newImages);
      const newLinks = {
        linkex: collection?.external_link,
        linkme: collection?.medium_url,
        linkte: collection?.telegram_url,
      };
      setLinks(newLinks);
      let templist = collection?.seller_fees?.map((element: any) => {
        return {
          address: element.address,
          rate: String(element.rate * 100),
        };
      });
      setSellerFees({ earningList: templist });
      console.log("templist", templist);
      // setSellerFees({ earningList: templist }) sellerFees是collection的earnings
      // setEarnings({ earningList: templist })   drop有自己的earnings，两个不是同个值  setDropEarnings
    }
  }, [collection]);
  const getFormVerify = () => {
    if (settings && (!settings.edition || settings.edition === "")) {
      setToastOpen({open:true,type:'error',label:"Drop Settings edition error"})
      return false;
    }
    else if (
      settings &&
      (!settings.mint_start_time)
    ) {
      console.log('settings.mint_start_time', settings.mint_start_time)
      setToastOpen({open:true,type:'error',label:"Drop Settings mint_start_time error"})
      return false;
    }
    else if (
      settings && (!settings.stages || !settings.stages[0] || (settings.stages[0] && settings.stages[0].sale_price.value === ''))
    ) {
      setToastOpen({open:true,type:'error',label:"Drop stages error" })
      return false;
    } 
    // else if (preReveal && (!preReveal.preName || preReveal.preName === "")) {
    //   setToastOpen({open:true,type:'error',label:"Drop Pre-reval name error" })

    //   return false;
    // } else if (preReveal && (!preReveal.preDesc || preReveal.preDesc === "")) {
    //   setToastOpen({open:true,type:'error',label:"Drop Pre-reval description error" })

    //   return false;
    // } else if (preReveal && (!preReveal.preImg || preReveal.preImg === "")) {
    //   setToastOpen({open:true,type:'error',label:"Drop Pre-reval image_url error" })

    //   return false;
    // } 
    else if (
      dropEarnings &&
      (!dropEarnings.dropEarning || dropEarnings.dropEarning === "")
    ) {
      setToastOpen({open:true,type:'error',label:"Drop dropEarnings error" })

      return false;
    } else {
      return true;
    }
  };
  // collaborators
  const saveCollection = async () => {
    var update: any = {
      chain: collection?.chain,
      contract_address: collection?.contract_address,
    };
    if (isDetailChange()) {
      if (detail && detail.payment_tokens && detail.payment_tokens.length > 0) {
        update = {
          ...update,
          detail,
        };
      } else {
        setToastOpen({open:true,type:'error',label:"请完善Drop Detail信息" })

        return;
      }
    }
    if (isEarningsChange()) {
      let templist = sellerFees?.earningList?.map((element: any) => {
        return {
          address: element.address,
          rate: Number(element.rate) / 100,
        };
      });
      update = { ...update, ...{ seller_fees: templist || [] } };
    }
    if (isLinksChange()) {
      update = {
        ...update,
        ...{
          links: {
            discord_url: "string",
            external_link: links?.linkex,
            medium_url: links?.linkme,
            telegram_url: links?.linkte,
            twitter_username: "",
          },
        },
      };
    }
    if (isImagesChange()) {
      update = {
        ...update,
        ...{
          images: {
            banner_image_url: images?.bannerUrl,
            featured_image_url: images?.featuredUrl,
            image_url: images?.logoUrl,
          },
        },
      };
    }
    //调用保存
    console.log("update", update);
    const { status, Message, data } = await uploadCollection(update).unwrap();
    if (status === "success") {
      setToastOpen({open:true,type:'success',label:'success'})
    } else {
      setToastOpen({open:true,type:'error',label:Message})

    }
  };

  const saveDropPageDraft = async () => {
    if (
      isDropPageChange() ||
      isDropEarnChange() ||
      isPreChange() ||
      isSettingChange()
    ) {
      const isNext = getFormVerify();
      if (!isNext) {
        return;
      }
      let previewList = dropPage?.imgListUrl?.map((el) => {
        return el.img;
      });

      // let public_stage=[settings?.public_stage || ]



      let updateDrop: DropInfo = {
        chain: collection?.chain,
        contract_address: collection?.contract_address,
        settings: settings,
        earnings: dropEarnings?.dropEarning,
        pre_reveal: {
          description: preReveal?.preDesc || "",
          image_url: preReveal?.preImg || "",
          name: preReveal?.preName || "",
        },
        drop_page: {
          description: dropPage?.desc || "",
          faqs: dropPage?.questList || [],
          logo_url: dropPage?.avatarUrl || "",
          mobile_banner_image_url: dropPage?.bannerUrlTel || "",
          pc_banner_image_url: dropPage?.bannerUrl || "",
          preview_image_urls: previewList || [],
          roadmaps: dropPage?.mapList || [],
          sections: dropPage?.secList || [],
          tabs: dropPage?.tabs || [],
          team: dropPage?.teamList || [],
        },
      };
      console.log("updateDropupdateDrop", updateDrop);
      const { success, message, data } = await saveDropDraft(
        updateDrop
      ).unwrap();
      if (success) {
        setToastOpen({open:true,type:'success',label:'success'})

      } else {
        setToastOpen({open:true,type:'error',label:message})

      }
    } else {
      setToastOpen({open:true,type:'error',label:"无改动"})

    }
  };

  const handelAllowedSeadrop=async () => {
    const newAllowed:string[]=[]
    if(!collection) {
      setToastOpen({open:true,type:'error',label:"no collection record"})
      return;
    }
    if (!chainData|| !chainData.data || chainData.data.seadrop_impl===undefined) {
      setToastOpen({open:true,type:'error',label:"blockchain data not load"})
      return
    }
    if(!collection.allowed_seadrop) {
      setToastOpen({open:true,type:'error',label:"no allowed seadrop record"})
      return;
    }
    Object.assign(newAllowed,collection.allowed_seadrop.value)
    newAllowed.push(chainData.data.seadrop_impl)
    try {
    const res = await erc721Seadrop.updateAllowedSeaDrop(newAllowed);
    await res.wait(1)
    console.log(res)
  }catch (err) {
    console.log("error", err);
    setToastOpen({open:true,type:'error',label:(err as Error).message})

  }
    
  }

  const publishDrop = async () => {
    if (!chainData|| !chainData.data || chainData.data.seadrop_impl===undefined) {
      setToastOpen({open:true,type:'error',label:"blockchain data not load"})
      return
    }
    if (!collection) {
      setToastOpen({open:true,type:'error',label:"collection is null"})

      return;
    }

    if(!collection.allowed_seadrop) {
      setToastOpen({open:true,type:'error',label:"no allowed seadrop record"})
      return;
    }
    if(!collection.allowed_seadrop.value.includes(chainData.data.seadrop_impl)) {
      dispatch(setOpenModal(ApplicationModal.ALLOWED_SEADROP));
      return;
    }

    if (
      !dropEarnings ||
      !dropEarnings.dropEarning ||
      dropEarnings.dropEarning === ""
    ) {
      setToastOpen({open:true,type:'error',label:"dropEarnings is null"})

      return;
    }
    const isNext = getFormVerify();
    if (!isNext) {
      return;
    }
    let previewList = dropPage?.imgListUrl?.map((el) => {
      return el.img;
    });

    let updateDrop: DropInfo = {
      chain: collection.chain,
      contract_address: collection.contract_address,
      settings: settings,
      earnings: dropEarnings?.dropEarning,
      pre_reveal: {
        description: preReveal?.preDesc || "",
        image_url: preReveal?.preImg || "",
        name: preReveal?.preName || "",
      },
      drop_page: {
        description: dropPage?.desc || "",
        faqs: dropPage?.questList || [],
        logo_url: dropPage?.avatarUrl || "",
        mobile_banner_image_url: dropPage?.bannerUrlTel || "",
        pc_banner_image_url: dropPage?.bannerUrl || "",
        preview_image_urls: previewList || [],
        roadmaps: dropPage?.mapList || [],
        sections: dropPage?.secList || [],
        tabs: dropPage?.tabs || [],
        team: dropPage?.teamList || [],
      },
    };

    if (settings?.stages) {
      const { success, message } = await saveDropPubilsh(updateDrop).unwrap();
      await getDropDraftInfo(collection)
      console.log("saveDropPubilsh:", success, message);


      const publicStage = getPublicStage(updateDrop.settings?.stages || [])
      if (publicStage == null) {
        setToastOpen({open:true,type:'error',label:"public mint stage is null" })

        return
      }

      const mint_start_time = settings.mint_start_time;
      const end_time = publicStage.end_time;

      console.log("start time:", mint_start_time);
      console.log("end time:", end_time);
      const mintPrice =publicStage?.sale_price.value||'0'
        // publicStage?.sale_price.value &&
        // ethers.utils.parseEther(publicStage?.sale_price.value);
      // const seadropImpl = SEADROP_ADDRESS[chainId as number];
      console.log("seadropImpl:", seadropImpl);

      const publicDrop: PublicDropStruct = {
        mintPrice: mintPrice,
        startTime: mint_start_time,
        endTime: end_time,
        maxTotalMintableByWallet: publicStage?.per_wallet_mint_limit || 0,
        feeBps: feeBps,
        restrictFeeRecipients: true,
      };

      console.log(JSON.stringify(publicDrop));
      var allowedFeeRecipients: string[] = [];
      console.log("collection.contract_address:", collection.contract_address);
        console.log("seaDrop", seaDrop);
      


      try {
        const feeRecipients = await seaDrop.getAllowedFeeRecipients(
          collection.contract_address
        );
      
  
        if (!feeRecipients.includes(seadropAddressFee)) {
          allowedFeeRecipients = [seadropAddressFee];
        }
      } catch (err) {
        setToastOpen({open:true,type:'error',label:(err as Error).message})
      }
      const elementsBuffer = await allowListElementsBuffer(allowlist);
      const merkleTree = createMerkleTree(elementsBuffer);
      let root = merkleTree.getHexRoot()
      if (root === "0x") {
        root = `0x${"0".repeat(64)}`
      }
      console.log('root', root)
      const config: ERC721SeaDropStructsErrorsAndEvents.MultiConfigureStructStruct =
      {
        maxSupply: settings?.max_supply || "",
        baseURI: "",
        contractURI: "",
        seaDropImpl: seadropImpl,
        publicDrop: publicDrop,
        dropURI: "",
        allowListData: {
          merkleRoot: root,
          publicKeyURIs: [],
          allowListURI: "",
        },
        creatorPayoutAddress: dropEarnings.dropEarning,
        provenanceHash: `0x${"0".repeat(64)}`,
        allowedFeeRecipients: allowedFeeRecipients,
        disallowedFeeRecipients: [],
        allowedPayers: [],
        disallowedPayers: [],
        tokenGatedAllowedNftTokens: [],
        tokenGatedDropStages: [],
        disallowedTokenGatedAllowedNftTokens: [],
        signers: [],
        signedMintValidationParams: [],
        disallowedSigners: [],
      };

      console.log('config',config);

      try {
        const res = await erc721Seadrop.multiConfigure(config);
      } catch (err) {
        console.log("error", err);
        setToastOpen({open:true,type:'error',label:(err as Error).message})

      }
    }

  };

  const changeMenu = (name: string) => {
    setSearchParams("tab=" + name);
    setShowMenu(false);
  };

  return (
    <>
      <TopLine>
        <TopText>{currentMenu}</TopText>
        <TopImg
          src={darkMode ? menuIconN : menuIcon}
          alt=""
          onClick={() => setShowMenu(true)}
        />
      </TopLine>
      <WebMain>
        <WebLeft
          style={{ display: currentMenu === "Drop page" ? "none" : "block" }}
        >
          {collection ? (
            <Menu
              current={currentMenu}
              has_drop={collection.has_drop ? true : false}
              logo_url={collection.image_url}
              onChange={(group: string, value: string) => {
                changeMenu(value);
                if (group !== currentEdit) {
                  if (currentEdit === "collection") {
                    if (isCollectionChange()) {
                      console.log("You have unsaved changes");
                    }
                    setCurrentEdit("drop");
                  } else if (currentEdit === "drop") {
                    if (isDropChange()) {
                      console.log("You have unsaved changes");
                    }
                    setCurrentEdit("collection");
                  }
                }
              }}
            />
          ) : null}
        </WebLeft>
        <WebRight>
          <DetailForm
            visible={currentMenu === "Details"}
            value={detail}
            onChange={(value) => setDetail({ ...detail, ...value })}
          />
          <ImagesForm
            visible={currentMenu === "Graphics"}
            value={images}
            collection={collection}
            onChange={(value) => {
              changeImages(value)

            }}
          />
          <EarningsForm
            visible={currentMenu === "Earnings"}
            value={sellerFees}
            onChange={(value) => setSellerFees(value)}
          />
          <LinksForm
            visible={currentMenu === "Links"}
            value={links}
            onChange={(value) => setLinks({ ...links, ...value })}
          />

          <SettingsForm
            visible={currentMenu === "Settings"}
            value={settings}
            onChange={(value) => setSettings({ ...settings, ...value })}
          />
          <PreRevealForm
            visible={currentMenu === "Pre-reveal"}
            value={preReveal}
            onChange={(value) => setPreReveal({ ...preReveal, ...value })}
          />
          <DropEarningsForm
            visible={currentMenu === "DropEarnings"}
            value={dropEarnings}
            onChange={(value) => setDropEarnings(value)}
          />
          <DropPageForm
            visible={currentMenu === "Drop page"}
            value={dropPage}
            onChange={(value) => setDropPage({ ...dropPage, ...value })}
          />
          <div style={{ flex: "1" }}></div>
          <RightBottom>
            <RightBottomMain isAct={true}>
              {currentEdit === "collection" ? (
                // ButtonPurple
                <ButtonPurple
                  padding={"10px"}
                  $borderRadius={"4px"}
                  width={"auto"}
                  onClick={() => saveCollection()}
                >
                  Save collection
                </ButtonPurple>
              ) : // <ButtonForm value={{text:'Save collection',padding:'10px',margin:'0 0 0 0'}} visible={true} onChange={()=>saveCollection()} />

                null}
              {currentEdit === "drop" ? (
                <>
                  <ButtonPurple
                    padding={"10px"}
                    $borderRadius={"4px"}
                    width={"auto"}
                    margin="0 0 0 20px"
                    onClick={() => publishDrop()}
                  >
                    publish
                  </ButtonPurple>
                  {isSaveCollect() ? null : (
                    <ButtonBlock
                      padding={"10px"}
                      margin="0 0 0 30px"
                      $borderRadius={"4px"}
                      width={"auto"}
                      onClick={() => saveDropPageDraft()}
                    >
                      save draft
                    </ButtonBlock>
                  )}

                  <DropBtn isShow={true}>Draft</DropBtn>
                </>
              ) : null}
            </RightBottomMain>
          </RightBottom>
        </WebRight>
      </WebMain>
      <MenuDiv isShow={showMenu}>
        <TopLine>
          <TopText>Collection</TopText>
          <TopImg
            src={darkMode ? closeIconN : closeIcon}
            alt=""
            onClick={() => setShowMenu(false)}
          />
        </TopLine>
        <BackLine
          onClick={() => {
            navigate(-1);
            setTimeout(() => {
              window.location.reload();
            }, 200);
          }}
        >
          <TopImg
            style={{ transform: "rotate(90deg)", opacity: "0.7" }}
            src={darkMode ? arrowLN : arrowL}
            alt=""
          />
          <TopText1>My Collection</TopText1>
        </BackLine>
        {collectionMenus.map((v, i) => {
          return (
            <TopText2
              key={v.name}
              onClick={() => {
                changeMenu(v.name);
              }}
            >
              {v.label}
            </TopText2>
          );
        })}
        {collection?.has_drop ? <TopText2>Drop</TopText2> : null}
        {collection?.has_drop &&
          DropFormMenu.map((v, i) => {
            return (
              <TopText2
                key={v.name}
                onClick={() => {
                  changeMenu(v.name);
                }}
              >
                {v.label}
              </TopText2>
            );
          })}
      </MenuDiv>
      <AllowSeadropModal onSumbit={handelAllowedSeadrop} />
    </>
  );
}
