import React, { HTMLProps } from 'react'
import { ArrowLeft, ExternalLink as LinkIconFeather, Trash, X } from 'react-feather'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components/macro'


export const HeaderBlock = styled.div`
  width: 100vw;
  height: 70px;
`;
export const HeaderTop = styled.div`  height: 70px;`;
export const TopLine = styled.div<{ isTel: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: ${({ isTel }) => (isTel ? "0" : "100vw")};
  height: ${({ isTel }) => (isTel ? "0" : "70px")};
  display:${({ isTel }) => (isTel ? "none" : "flex")};
  padding: 12px 40px 12px 20px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 15;
  background: ${({ theme }) => theme.modalBG1};
  backdrop-filter: blur(5px);
  opacity: ${({ isTel }) => (isTel ? "0" : "1")};
  // overflow: hidden;
`;
export const TopLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const TopCenter = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  flex: 1;
  margin: 0 50px;
  display: flex;
  flex-shrink: 0;
`;
export const TopRight = styled.div`
  display: flex;
  align-items: center;
`;
export const LeftImg = styled.img`
  width: 48px;
  height: 48px;
  border-radius:50%;
  margin-right:5px;
  cursor: pointer;
`;
export const BackImg = styled.img`
  width:20px;
  height: 20px;
  margin-right:5px;
  cursor: pointer;
  
  transform: rotate(90deg);
  
  opacity: 0.7;
  :hover{
    opacity: 1;
  }
`;
export const SearchImg = styled.img`
  width:25px;
  height:25px;
  
  transform: revert;
  
`;
export const CloseImg = styled.img`
  width:15px;
  height: 15px;
  cursor: pointer;

`;
export const LeftUserImg = styled.div`
  width: 48px;
  height: 48px;
  border-radius:50%;
  margin-right:5px;
  cursor: pointer;
`;
export const DownBlock = styled.div`
  width: 100%;
  height:auto;
  padding:20px 10px;
  background: ${({ theme }) => theme.bg0};
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 9;
  border-radius:10px;
  box-shadow: 2px 2px 10px ${({ theme }) => theme.bg3};
`;

export const RightImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;
`;
export const RightImg1 = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export const RightDiv = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;
`;
export const LeftDiv = styled.div`
  font-size: 24px;
  font-weight: normal;
  color: ${({ theme }) => theme.bg0};
  margin-top: 8px;
  position: relative;
  font-family: frick3;
`;
export const LeftTest = styled.div`
  position: absolute;
  right: 0;
  bottom: -8px;
  right: -10px;
  height: 22px;
  line-height: 22px;
  background: #f1a64e;
  border-radius: 6px;
  padding: 0 7px;

  font-size: 16px;
  font-family: Aileron-Regular, Aileron;
  font-weight: 400;
  color: #383838;
  transform: scale(0.7);
`;


export const LogoText = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text1};
`;

export const MenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  padding-right: 1.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};
  white-space: nowrap;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`;
export const PopupDiv = styled.div<{ showUser: boolean }>`
  width: ${({ showUser }) => (showUser ? "100vw" : "0")};
  height: ${({ showUser }) => (showUser ? "100vh" : "0")};
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  opacity: ${({ showUser }) => (showUser ? "1" : "0")};
`;
export const PopupDivTel = styled.div<{ showUserTel: boolean }>`
  width: ${({ showUserTel }) => (showUserTel ? "100vw" : "0")};
  height: ${({ showUserTel }) => (showUserTel ? "100vh" : "0")};
  background: ${({ theme }) => theme.bg1};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  opacity: ${({ showUserTel }) => (showUserTel ? "1" : "0")};
`;

export const PopupRight = styled.div`
  width: 30vw;
  min-width: 400px;
  max-width: 750px;
  height: 100vh;
 background: ${({ theme }) => theme.bg0};
  overflow: hidden;
  padding: 30px 40px 30px 20px;
  box-sizing: border-box;
`;
export const PopupRightTel = styled.div<{ isShow: boolean }>`
background: ${({ theme }) => theme.bg1};
color: ${({ theme }) => theme.text1};

width: ${({ isShow }) => (isShow ? "100%" : "0")};
height: ${({ isShow }) => (isShow ? "100vh" : "0")};
padding: ${({ isShow }) => (isShow ? " 70px 4.3vw 4vw" : "0")};
box-sizing: border-box;
overflow: hidden;
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
`;
export const CloseLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;
export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
`;
export const UserLeft = styled.div`
  display: flex;
  align-items: center;
`;
export const UserImg = styled.img`
  width: 38px;
  height: 38px;
  cursor: pointer;
`;
export const UserText = styled.div`
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 20px;
  font-family: Inter-Medium, Inter;
  font-weight: 500;
  margin-left: 2px;
  color: ${({ theme }) => theme.text1};
`;
export const UserAddress = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
`;
export const CopyImg = styled.img`
  width: 14px;
  height: 18px;
  margin-left: 5px;
`;
export const RightList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const RightItem = styled.div`
  display: flex;
  width: 48%;
  height: 40px;
  align-items: center;
  font-family: Aileron-SemiBold, Aileron;
  cursor: pointer;
`;
export const ItemImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;
export const TopLineTel = styled.div<{ isTel: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: ${({ isTel }) => (isTel ? "100vw" : "0")};
  height: ${({ isTel }) => (isTel ? "70px" : "0")};
  opacity: ${({ isTel }) => (isTel ? "1" : "0")};
  // overflow:hidden;
  display: flex;
  padding: 12px 4.2vw 12px;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.modalBG1};
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  z-index: 15;
`;
export const TopTelLeft = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ isShow }) => (isShow ? "auto" : "0")};
  height: ${({ isShow }) => (isShow ? "auto" : "0")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  overflow:${({ isShow }) => (isShow ? "" : "hidden")};
  display:${({ isShow }) => (isShow ? "" : "none")};
`;
export const TopMenu = styled.img`
  width: 4.8vw;
  height: 4.8vw;
  margin-left: 30px;
`;
export const TopTelLeftImg = styled.img`
  width: 6.4vw;
  height: 6.4vw;
  cursor: pointer;
`;

export const TopTelLeftText = styled.div`
  font-size: 16px;
  font-weight: normal;
  color: ${({ theme }) => theme.text1};
  margin-top: 8px;
  position: relative;
  > div {
    position: absolute;
    right: 0;
    bottom: -16px;
    background: #f1a64e;
    border-radius: 6px;
    padding: 2px 7px;
    font-size: 12px;
    font-family: Aileron-Regular, Aileron;
    font-weight: 400;
    color: #383838;
    transform: scale(0.7);
  }
`;
export const ConnectBtn = styled.div`
height: 9.5vw;
font-size: 16px;
font-family: Inter-Regular, Inter;
font-weight: 400;
line-height: 9.5vw;
text-align: center;
background: #6a5bda;
border-radius: 4px;
color: #fff;
width: 100%;
display: block;
margin-top: 50px;
`
export const RouteItem = styled.div`
width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      >div {
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 16px;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        line-height:50px;
      }
`
export const RouteItem1 = styled.div`
width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      background: ${({ theme }) => theme.bg0};
      >div {
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 16px;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        line-height:50px;
      }
`
export const RouteItemImg = styled.img`
width: 3.8vw;
height: 3.8vw;
`