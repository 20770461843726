//还没改完
import { useState, useEffect } from "react";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { ButtonPurple, ButtonTransparent } from "components/Button"
import NoData from "components/NoData";
import RadixPopover from "components/Popover"

import { useToastOpen } from "state/stats/hooks";
import { openWindow, timestampToTime, priceText } from "utils";
import attestationIcon from "assets/attestation_icon.png";
import actSell from "assets/h5/act_Sell.png";
import actBuy from "assets/h5/act_Buy.png";
import actTransfer from "assets/h5/act_Transfer.png";
import nftDefault from "assets/nft/nft_defult.png";
import refreshN from "assets/tool/refresh_n.png";
import refresh from "assets/tool/refresh.png";

import arrowU from "../../../assets/tool/arrow_u.png";
import arrowUn from "../../../assets/tool/arrow_u_n.png";
import {
  ActivityIcon, ActivityIconRotate
} from "pages/Nft/components_";
import {
  BuyBtn1, BuyBtnTransparent,
  PriceCardMainTelL, CardMain, CardTableTitleRow, CardTableMain, CardTableTitleRow2, CardItem, CardTop,
  BuyBtnTel, TabLine, TabItem,
  TabCard,
  RAddress,
  TabUl,
  TabLi,
  DetailCardTel,
  DetailItemTel,
  DetailItemTelL,
  AddressImg,
  DetailItemTelR,
  ActUl,
  ActItem,
  CardTopImg,
  RecommendTitle,
  CardMainUi,
  ActItemImg,
  CardMainBtn,
  ActItemR,
  ActItemRTop,
  ActItemRBottom,
  RecomImg,
  RecomBottom,
  RecomBottomName,
  RecomBottomNameImg,
  RecomBottomCode,
  RecomBottomPrice,
  InfoDescLoadTel,
  TelMain,
  PageTitle,
  CardMainTel,
  RecommendItemTel,
  RecommendItemTelLoad,
  RTitleTel,
  PriceCardTel,
  PriceCardTitleTel,
  NftImgTelNone,
  NftImgTel,
  PriceCardMainTel,
} from "./components";

import copyIcon from "assets/nft_copy.png";
import { OrderWithCounter, OrderComponents } from "@opensea/seaport-js/lib/types";
import { ActivityItem, Assets, ChainInfo, OrderItem } from "types";

export interface DetailForms {
  recommendLoad?: boolean;
  recommendList?: Assets[];
  address?: string;
  listings?: OrderItem[];
  itemInfo?: Assets | null;
  itemInfoLoad?: boolean;
  actList?: ActivityItem[];
  chainCurrency?: ChainInfo | null;
  isRefresh?: boolean

}

interface DetailFormProps {
  fullfillOrder: (order: OrderWithCounter,hash:string,proaddress:string) => void;
  cancelOrder: (order: OrderComponents[]) => void;
  visible?: boolean;
  value?: DetailForms;
  openSale: () => void;
  onChange: () => void;
  openOffer: () => void;
}

export default function DetailForm({
  fullfillOrder, cancelOrder,
  value,
  onChange, openSale, openOffer
}: DetailFormProps) {
  let navigate = useNavigate();

  const [darkMode] = useDarkModeManager();
  const [,,, setToastOpen] = useToastOpen();

  const { account } = useWeb3React();
  const [showCard9, setShowCard9] = useState(true);
  const [tabIndex, setTabIndex] = useState(1);
  const [itemInfo, setItemInfo] = useState<Assets | null>();
  const [actList, setActList] = useState<ActivityItem[]>([]);
  const [cardType, setCardType] = useState('buy');//sell buy

  // const [recommendLoad, setRecommendLoad] = useState(true);
  // const [recommendList, setRecommendList] = useState<Assets[]>([]);
  const [recommendListNone, setRecommendListNone] = useState([1, 1, 1, 1, 1, 1]);
  const [list1, setList1] = useState<Assets[]>([]);
  useEffect(() => {
    if (value && value.itemInfo !== itemInfo) {
      setItemInfo(value.itemInfo);
    }
    if (value && value.actList !== actList) {
      setActList(value.actList || []);
    }
    // if (value && value.recommendList != list1) {
    //   let list = value.recommendList || []
    //   let templist: Assets[] = []
    //   list?.forEach((el: any, index: number) => {
    //     if (index < 6) {
    //       templist.push(el)
    //     }
    //   });
    //   setList1(list)
    //   setRecommendList(templist);
    //   setRecommendLoad(value.recommendLoad || false);
    // }
    // if (value && value.recommendLoad != recommendLoad) {
    //   setRecommendLoad(value.recommendLoad || false);
    // }
  }, [value]);

  const toPage = (e: string) => {
    window.location.replace(e);
  };

  return (
    <>
      <TelMain>
        <PageTitle>NFT Market</PageTitle>
        {value?.itemInfoLoad ? (
          <NftImgTelNone />
        ) : (
          <NftImgTel src={itemInfo?.image_url || nftDefault} alt="" />
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
          <RTitleTel style={{ marginBottom: '0' }} onClick={() => navigate(
            `/nfts/collection/${itemInfo?.chain_identifier}/${itemInfo?.contract_address}`
          )}>
            {value?.itemInfoLoad ? (
              <InfoDescLoadTel style={{ width: "50%" }}>{'        '}</InfoDescLoadTel>
            ) : itemInfo?.name ? (
              itemInfo?.name
            ) : (
              "#" + itemInfo?.token_id
            )}{" "}
          </RTitleTel>
          {
            value?.isRefresh ? (
              <ActivityIconRotate src={darkMode ? refreshN : refresh} />

            ) : (<ActivityIcon onClick={() => onChange()} src={darkMode ? refreshN : refresh} />
            )
          }
        </div>

        {value?.itemInfoLoad ? (
          <InfoDescLoadTel style={{ width: "50%", marginBottom: "5px" }}>
            {'        '}
          </InfoDescLoadTel>
        ) : (
          <RAddress
            onClick={() =>{
              navigator.clipboard.writeText(value?.address||'');setToastOpen({open:true,type:'success',label:"success"})}}
            style={
              itemInfo && itemInfo.current_price ? {} : { marginBottom: 0 }
            }
          >
            <div>
              {value?.address?.substring(0, 7) +
                "..." +
                value?.address?.substr(value?.address.length - 5)}
            </div>
            <AddressImg src={copyIcon} />
          </RAddress>
        )}
        <PriceCardTel>
          {value?.itemInfo?.owner_address && account && ((value.itemInfo.owner_address)?.toUpperCase() === account?.toUpperCase()) ? (<div style={{ width: '100%' }}>

            {/* <div style={{ width: '100%' }}> */}
            <TabLine>
              <TabItem isAct={cardType === 'sell'} onClick={() => setCardType('sell')}>Sell</TabItem>
              <TabItem isAct={cardType === 'buy'} onClick={() => setCardType('buy')}>Buy</TabItem>
              {/* <ButtonTransparent padding={'5px 10px'} $borderRadius={'4px'} width={'auto'} border="none" onClick={() => setCardType('buy')}>Buy</ButtonTransparent> */}
            </TabLine>
            {cardType === 'sell' ? (<>
              <BuyBtnTel onClick={() => { openSale() }}>
                List 1 item</BuyBtnTel></>) : (<> <PriceCardTitleTel>Price</PriceCardTitleTel>
                  <PriceCardMainTel>
                    <PriceCardMainTelL>
                      <div>
                        {value?.itemInfo?.current_price && value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000)) ? priceText(value.listings[0].price) : 'Not listed...'}

                      </div>
                    </PriceCardMainTelL>
                  </PriceCardMainTel>
                  {value?.itemInfo?.current_price&& value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000)) ? (


                    <BuyBtnTel onClick={() => {
                      fullfillOrder(value?.listings?.[0].protocol_data,value?.listings?.[0].order_hash||'',value?.listings?.[0].protocal_address||'')
                    }}>Buy</BuyBtnTel>

                  ) : ("")}<BuyBtnTransparent onClick={() => { openOffer() }}>
                  Make offer</BuyBtnTransparent></>)}
                  
          </div>) : (<>
            <PriceCardTitleTel>Price</PriceCardTitleTel>
            <PriceCardMainTel>
              <PriceCardMainTelL>
                <div>
                  { value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000)) ? priceText(value.listings[0].price) : 'Not listed...'}

                </div>
              </PriceCardMainTelL>
            </PriceCardMainTel>
            { value?.listings?.[0]?.price && value?.listings?.[0]?.end_time && value?.listings?.[0]?.start_time && (value?.listings?.[0]?.end_time > (new Date().getTime() / 1000)) && (value?.listings?.[0]?.start_time < (new Date().getTime() / 1000)) ? (
              <BuyBtnTel onClick={() => {
                fullfillOrder(value?.listings?.[0].protocol_data,value?.listings?.[0].order_hash||'',value?.listings?.[0].protocal_address||'')
              }}>Buy</BuyBtnTel>

            ) : ("")}
            <BuyBtnTransparent onClick={() => { openOffer() }}>
              Make offer</BuyBtnTransparent></>)}
        </PriceCardTel>

        <TabCard style={{ marginBottom: '20px' }}>
          <TabUl>
            <TabLi
              isAct={tabIndex == 1 ? true : false}
              onClick={() => setTabIndex(1)}
            >
              Overview
            </TabLi>
            <TabLi
              isAct={tabIndex == 2 ? true : false}
              onClick={() => setTabIndex(2)}
            >
              Activity
            </TabLi>
            <TabLi
              isAct={tabIndex == 3 ? true : false}
              onClick={() => setTabIndex(3)}
            >
              Listings
            </TabLi>
          </TabUl>
          <DetailCardTel isShow={tabIndex == 1 ? true : false}>
            <DetailItemTel>
              <DetailItemTelL>Contract</DetailItemTelL>
              {value?.itemInfoLoad ? (
                <InfoDescLoadTel>{'        '}</InfoDescLoadTel>
              ) : (
                <DetailItemTelR onClick={() =>{ navigator.clipboard.writeText(value?.address||''); setToastOpen({open:true,type:'success',label:"success"})}}>
                  <div>
                    {value?.address?.substring(0, 3) +
                      "..." +
                      value?.address?.substr(value?.address.length - 5)}
                  </div>
                  <CardTopImg src={copyIcon} alt="" />
                </DetailItemTelR>
              )}
            </DetailItemTel>
            <DetailItemTel>
              <DetailItemTelL>TOKEN ID</DetailItemTelL>
              {value?.itemInfoLoad ? (
                <InfoDescLoadTel>{'        '}</InfoDescLoadTel>
              ) : (
                <DetailItemTelR
                  onClick={() =>{ navigator.clipboard.writeText(itemInfo?.token_id||''); setToastOpen({open:true,type:'success',label:"success"})} }
                >
                  {/* <div>{itemInfo?.token_id}</div> */}
                  <div>
                    {itemInfo
                      ? itemInfo.token_id.length > 10
                        ? itemInfo.token_id.substring(0, 3) +
                        "..." +
                        itemInfo.token_id.substr(itemInfo.token_id.length - 5)
                        : itemInfo.token_id
                      : ""}
                  </div>
                  <CardTopImg src={copyIcon} alt="" />
                </DetailItemTelR>
              )}
            </DetailItemTel>
            <DetailItemTel>
              <DetailItemTelL>Token Standard</DetailItemTelL>
              <DetailItemTelR>
                {" "}
                {value?.itemInfoLoad ? (
                  <InfoDescLoadTel>{'        '}</InfoDescLoadTel>
                ) : (
                  itemInfo?.token_standard
                )}
              </DetailItemTelR>
            </DetailItemTel>
            <DetailItemTel>
              <DetailItemTelL>Network</DetailItemTelL>
              <DetailItemTelR>
                {" "}
                {value?.itemInfoLoad ? (
                  <InfoDescLoadTel>{'        '}</InfoDescLoadTel>
                ) : (
                  itemInfo?.chain_identifier
                )}
              </DetailItemTelR>
            </DetailItemTel>
            <DetailItemTel>
              <DetailItemTelL>Source</DetailItemTelL>
              <DetailItemTelR>
                {value?.itemInfoLoad ? (
                  <InfoDescLoadTel>{'        '}</InfoDescLoadTel>
                ) : (
                  itemInfo?.source
                )}
              </DetailItemTelR>
            </DetailItemTel>
          </DetailCardTel>
          <ActUl
            isShow={tabIndex == 2 ? true : false}
            id="actmain"
            style={{ maxHeight: "350px", overflowY: "scroll" }}
          >
            {actList && actList.length > 0 ? (
              actList.map((i: any, index: number) => (
                <ActItem key={index}>
                  <ActItemImg
                    src={
                      i.type == "sale"
                        ? actSell
                        : i.type == "transfer"
                          ? actTransfer
                          : actBuy
                    }
                    alt=""
                  />
                  <ActItemR>
                    <ActItemRTop>
                      <div>{i.from_account === '0x0000000000000000000000000000000000000000' ? 'Mint' : i.type}</div>
                      <div>
                        {i?.unit_price ? (<div>
                          {priceText(i.unit_price)}{" "}
                        </div>) : '--'}

                      </div>
                    </ActItemRTop>
                    <ActItemRBottom>
                      <div onClick={() => openWindow(`${value?.chainCurrency?.block_explorer_url}/tx/${i.tx_hash}`)}>{i.timestamp}</div>
                      <div>Transaction price</div>
                    </ActItemRBottom>
                  </ActItemR>
                </ActItem>
              ))
            ) : (
              <NoData onChange={() => { }}></NoData>
              // <ActItem>none</ActItem>
            )}
          </ActUl>
          <ActUl
            isShow={tabIndex == 3 ? true : false}
            id="actmain"
          >
            <CardItem>
              <CardTop onClick={() => setShowCard9(!showCard9)}>
                <div>Listings</div>
                <CardTopImg
                  src={darkMode ? arrowUn : arrowU}
                  style={
                    showCard9
                      ? { transform: "rotateX(180deg)" }
                      : { marginBottom: "6px" }
                  }
                />
              </CardTop>
              <CardMain isShow={showCard9} style={{ padding: '0', overflowX: 'scroll' }}>
                {value?.listings && value?.listings.length > 0 ? (
                  <>
                    <CardTableTitleRow2 style={{ minWidth: '800px' }}>
                      {/* <div style={{ minWidth: '800px' }}>  */}
                      <div>Price</div>
                      <div>USD Price</div>
                      <div>Quantity</div>
                      <div>From</div>
                      <div style={{ width: '20%' }}>Expiration</div>
                      <div></div>
                      {/* </div> */}
                      {/* <div>Event</div> */}

                    </CardTableTitleRow2>
                    <CardTableMain id='actmain'>
                      {value?.listings?.map((i: OrderItem, index: number) => (
                        <CardTableTitleRow2 style={{ minWidth: '800px' }}>
                          <div>
                          <RadixPopover val= {i?.price ? priceText(i.price) : '--'} label={i?.price ? priceText(i.price) : '--'}></RadixPopover>

                            {/* <Popover content={(<div> {i?.price ? priceText(i.price) : '--'}</div>)}>
                              {i?.price ? priceText(i.price) : '--'}
                            </Popover> */}
                          </div>
                          <div>--</div>
                          <div>
                            {i.quantity || '--'}
                          </div>
                          <div>
                            {i?.maker?.address ? i?.maker?.address.substring(0, 3) + '...' + i?.maker?.address.substr(i?.maker?.address.length - 5,) : "--"}


                          </div>
                          <div style={{ width: '20%' }}>
                          <RadixPopover val= {'End: '+timestampToTime(i.end_time || 0)} label={i.timestr}></RadixPopover>

                            {/* <Popover content={(<div>End: {timestampToTime(i.end_time || 0)}</div>)}>
                              {i.timestr}
                            </Popover> */}

                            {/* {i?.maker?.address||'--'} */}
                          </div>
                          {/* <div></div> */}
                          {/* </div> */}

                          <div style={{ position: 'sticky', right: '20px', top: '0',transform: 'scale(0.8)' }}>
                            {i?.maker?.address && account && (account.toUpperCase() === i.maker.address.toUpperCase()) ? <ButtonTransparent padding={'5px 10px'} $borderRadius={'4px'} width={'auto'} onClick={() => { cancelOrder([i.protocol_data.parameters]) }}>
                              Cancel</ButtonTransparent> :
                              i?.end_time && i?.start_time && (i.end_time > (new Date().getTime() / 1000)) && (i.start_time < (new Date().getTime() / 1000)) ?
                                (<ButtonPurple padding={'5px 10px'} $borderRadius={'4px'} width={'auto'} onClick={() => { fullfillOrder(i.protocol_data,i.order_hash||'',i.protocal_address||'') }}>
                                  Buy</ButtonPurple>
                                ) : null}

                          </div>
                        </CardTableTitleRow2>
                      ))}


                    </CardTableMain></>
                ) : (<NoData onChange={() => { }}></NoData>)}

              </CardMain>
            </CardItem>
          </ActUl>
        </TabCard>
        {/* {value?.recommendList && value?.recommendList.length > 0 ? (
          <RecommendTitle>Recommend</RecommendTitle>
        ) : (
          ""
        )}

        {value?.recommendLoad ? (
          <CardMainTel isShow={true}>
            <CardMainUi>
              {recommendListNone.map(() => (
                <RecommendItemTelLoad></RecommendItemTelLoad>
              ))}
            </CardMainUi>
          </CardMainTel>
        ) : value?.recommendList && value?.recommendList.length > 0 ? (
          <CardMainTel isShow={true}>
            <CardMainUi>
              {list1.map((i: any, index: number) => (
                <RecommendItemTel
                  key={index}
                  onClick={() =>
                    toPage(
                      `/nfts/assets/${i.chain_identifier}/${i.contract_address}/${i.token_id}`
                    )
                  }
                >
                  <RecomImg src={i.image_url || nftDefault} alt="" />

                  <RecomBottom>
                    <RecomBottomName>
                      <div>{i.name ? i.name : i?.asset_contract?.name}</div>
                    </RecomBottomName>
                    <RecomBottomCode>#{' '}{i.token_id}</RecomBottomCode>
                    <RecomBottomPrice>
                      <span>
                        {i && i.current_price
                          ? priceText(i.current_price)
                          : "No quote yet"}
                      </span>
                    </RecomBottomPrice>
                  </RecomBottom>
                </RecommendItemTel>
              ))}
            </CardMainUi>
            <CardMainBtn
              isShow={
                list1.length == value?.recommendList.length ? false : true
              }
            >
              <BuyBtn1 onClick={() => setList1(value?.recommendList || [])}>
                View All
              </BuyBtn1>
            </CardMainBtn>
          </CardMainTel>
        ) : (
          ""
        )} */}
      </TelMain>
    </>
  );
}
