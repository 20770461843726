
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChainInfo } from "types";


interface ChainState {
    supportChains:ChainInfo[];
    current:ChainInfo|null
  }
  const initialState: ChainState = {
    supportChains: [],
    current: null,
  }

  const chainSlice = createSlice({
    name: 'chain',
    initialState,
    reducers: {
      setSupportChains(state, { payload }) {
        console.log("state.supportChains:",payload)
        state.supportChains = payload
      },

      setCurrent(state, { payload }) {
        state.current = payload
      },
    },
  })

export const { setCurrent, setSupportChains} = chainSlice.actions
export default chainSlice.reducer