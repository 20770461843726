
import { Input, Textarea } from "@rebass/forms";
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from "react";

import { useUploadImgMutation } from 'apis/file';
import UploadDropzone from "components/Form/upload";
import { useDarkModeManager } from "theme/components/ThemeToggle";
import { matchFileSuffixType } from "utils";

import closeIcon from "assets/tool/close_icon.png";
import closeIconN from "assets/tool/close_icon_n.png";
import editIcon from "assets/tool/menu_edit.png";
import editIconN from "assets/tool/menu_editw.png";
import { ButtonPurple, ButtonTransparent } from "components/Button";
import RadixPopconfirm from "components/Popconfirm";
import { useToastOpen } from "state/stats/hooks";
import {
    AddImgText1,
    CardItem,
    CloseImg,
    inputStyle,
    ItemLabel,
    ItemLabelFlex,
    ItemTip,
    ItemUpImg,
    ItemValue,
    UploadBox
} from "../../../../components_";
import {
    areaStyle,
    areaStyle1,
    CardTitle,
    DescText,
    DropInfoWeb,
    DropInfoWebBottom, DropInfoWebL,
    DropInfoWebLBottom,
    DropInfoWebLImg1,
    DropInfoWebLImg4,
    DropInfoWebLImgL,
    DropInfoWebLImgLImg,
    DropInfoWebLImgnone,
    DropInfoWebMint,
    DropInfoWebR,
    DropTitleWeb,
    EditIcon,
    ImgGroup,
    ImgUpdateItemDel,
    ImgUpdateItemFilled,
    ItemValueArea,
    MintItem, MintItemIcon, MintItemText, MintItemTextSub,
    SecDesc, SecEdit,
    SecImg, SecItem,
    SecList,
    SecTitle,
    SecVideo,
    StyledModal
} from "../../components_";

import dropImg from "assets/nft/drop_img.png";
import delB from "assets/tool/del_b.png";
import delW from "assets/tool/del_w.png";
import imgImg from "assets/tool/img_none.png";
import styled from "styled-components";
import { MintStage } from "types";


const StyeldTextarea = styled(Textarea)`
width: 100%;
height:118px;
border-radius: 4px;
border: 1px solid ${({ theme }) => theme.border1};
overflow: hidden;
margin:20px auto;
resize: none;
`;

export interface DropMintForms {
    desc?: string;
    secList?: {
        description: string;
        image_url: string;
        title: string;
    }[];
    imgListUrl?: { img: string }[];
    mintSchedule?: MintStage[];
    mintTime?: number | null;
}

interface DropMintFormProps {
    visible: boolean;
    value?: DropMintForms;
    onChange: (value: DropMintForms) => void;
}

export default function DropMintForm({
    visible,
    value,
    onChange,
}: DropMintFormProps) {
    const [darkMode] = useDarkModeManager();

    const [, , , setToastOpen] = useToastOpen();
    const { provider } = useWeb3React();
    const [uploadImg] = useUploadImgMutation()
    const [popuptype, setPopuptype] = useState<string>('imglist');
    const [showPopup, setShowPopup] = useState(false);
    const [secList, setSecList] = useState<{
        description: string;
        image_url: string;
        title: string;
    }[]>([]);
    const [secUrl, setSecUrl] = useState<string>('');
    const [secTitle, setSecTitle] = useState<string>('');
    const [secDesc, setSecDesc] = useState<string>('');
    const [secIndex, setSecIndex] = useState<number>(9999);
    const [loading, setLoading] = useState(false);
    // const minter = new ethers.Wallet(randomHex(32), provider);

    const [imgListUrl, setImgListUrl] = useState<{ img: string }[]>([{ img: '' }, { img: '' }, { img: '' }, { img: '' }]);
    useEffect(() => {

        if (value && value.imgListUrl) {
            // if (value && value.imgListUrl && value.imgListUrl !== imgListUrl&&imgListUrl.length<1) {
            console.log('valuevalue', value, value.imgListUrl !== imgListUrl)
            if (value.imgListUrl !== imgListUrl) {
                setImgListUrl([...value.imgListUrl])
                console.log('adddd', imgListUrl)
            }

        }
        if (value && value.secList && value.secList !== secList) {
            setSecList(value.secList)
            console.log('value.secList', value.secList)
        }
    }, [value]);

    const saveSec = () => {
        if (secDesc && secDesc !== '' && secUrl && secUrl !== '' && secTitle && secTitle !== '') {
            let list = [...secList]
            if (secIndex == 9999) {
                list.push({ description: secDesc, image_url: secUrl, title: secTitle })
                setSecList(list)
                setShowPopup(false)
                onChange({ secList: list })
            } else {
                list[secIndex] = { description: secDesc, image_url: secUrl, title: secTitle }
                setSecList(list)
                setShowPopup(false)
                onChange({ secList: list })

            }
        } else {
            setToastOpen({ open: true, type: 'error', label: "请完善section信息" })

            return
        }

    }
    const editSec = (index: number) => {
        setSecIndex(index)
        setSecDesc(secList[index].description)
        setSecUrl(secList[index].image_url)
        setSecTitle(secList[index].title)
        setPopuptype('sections')
        setShowPopup(true)
    }
    const addSec = () => {
        setSecIndex(9999)
        setSecDesc('')
        setSecUrl('')
        setSecTitle('')
        setPopuptype('sections')
        setShowPopup(true)
    }
    const delSec = () => {
        let list = [...secList]
        list.splice(secIndex, 1)
        setSecList(list)
        onChange({ secList: list })

        setShowPopup(false)
    }

    const delOneImg = (index: number) => {
        let list = [...imgListUrl]
        list.splice(index, 1)
        list.push({ img: '' })
        setImgListUrl(list)
    };
    const addOneImg = (url: string, index: number) => {
        // console.log('imgListUrl',imgListUrl)
        let list = [...imgListUrl]
        list[index].img = url
        setImgListUrl(list)
        // console.log('listlist',imgListUrl)
    }
    const saveList = () => {
        onChange({ imgListUrl: imgListUrl })
        setShowPopup(false)
    }
    const saveDesc = () => {

        if (value && value.desc && value.desc !== '') {
            setShowPopup(false)
        } else {
            setToastOpen({ open: true, type: 'error', label: "请输入description信息" })

        }
    }

    return <>
        <DropInfoWebMint isShow={visible} className="web-page">
            <DropInfoWeb isShow={visible} style={{ flexWrap: 'wrap' }}>
                <DropInfoWebL>
                    {imgListUrl[0]?.img ? (<DropInfoWebLImg1 onClick={() => { setPopuptype('imglist'); setShowPopup(true) }}>

                        <DropInfoWebLImgnone src={dropImg} />
                        <DropInfoWebLImgLImg src={imgListUrl[0]?.img} />

                    </DropInfoWebLImg1>) : (
                        <DropInfoWebLImg1 onClick={() => { setPopuptype('imglist'); setShowPopup(true) }}>
                            <DropInfoWebLImgnone src={dropImg} />
                            <DropInfoWebLImgL>
                                <DropInfoWebLImg4 src={imgImg} />
                                <div>Add preview images</div>
                            </DropInfoWebLImgL>



                        </DropInfoWebLImg1>
                    )}


                    <DropInfoWebLBottom>
                        {imgListUrl?.map((i: { img: string }, index: number) => (

                            <DropInfoWebLImg4 key={index} src={i.img} />

                        ))}

                    </DropInfoWebLBottom>
                </DropInfoWebL>
                <DropInfoWebR>
                    <DropTitleWeb onClick={() => { setPopuptype('desc'); setShowPopup(true) }} style={{ cursor: 'pointer', justifyContent: 'flex-start' }}><div>About this collection</div><EditIcon style={{ marginTop: '10px', marginLeft: '5px' }} src={darkMode ? editIconN : editIcon} /></DropTitleWeb>

                    <DescText isShow={true}>
                        {value?.desc ? value?.desc : 'Your description...'}
                    </DescText>



                    <DropTitleWeb>Mint schedule</DropTitleWeb>
                    {value?.mintSchedule?.map((i, index) => (
                        <MintItem key={index}>

                            <MintItemIcon></MintItemIcon>
                            <MintItemText>{i?.name}</MintItemText>
                            <MintItemTextSub>{new Date(value?.mintTime ? value.mintTime * 1000 : '').toString()}</MintItemTextSub>
                            <MintItemTextSub>{i?.sale_price?.value} {i?.sale_price?.currency?.symbol || 'ETH'}</MintItemTextSub>
                        </MintItem>
                    ))}

                </DropInfoWebR>
            </DropInfoWeb>

        </DropInfoWebMint>
        <SecList isShow={visible}> {value?.secList?.map((i, index) => (
            matchFileSuffixType(i.image_url)==='image'?(<SecItem key={index}>
                <SecImg src={i.image_url} style={{ objectFit: 'cover' }} />
                <SecDesc>{i.description}</SecDesc>
                <SecTitle>{i.title}</SecTitle>
                <SecEdit onClick={() => { editSec(index) }}>
                    <EditIcon src={darkMode ? editIconN : editIcon} />
                    Edit section</SecEdit>

            </SecItem>):(
                <SecItem key={index}>
                <SecVideo controls loop> <source src={i.image_url} /></SecVideo>
                <SecDesc>{i.description}</SecDesc>
                <SecTitle>{i.title}</SecTitle>
                <SecEdit onClick={() => { editSec(index) }}>
                    <EditIcon src={darkMode ? editIconN : editIcon} />
                    Edit section</SecEdit>

                </SecItem>
            )
            
        ))}</SecList>
        <DropInfoWebMint isShow={visible} className="web-page">
            <DropInfoWeb isShow={visible} style={{ flexWrap: 'wrap' }}>
            </DropInfoWeb>
            <DropInfoWebBottom onClick={() => { addSec() }}>
                <CardTitle>Add additional images and videos</CardTitle>
                <CardItem> <ItemTip>Adding additional images and videos helps build more depth to your drop and the collection.</ItemTip></CardItem>

                <CardTitle style={{ paddingTop: '0', paddingBottom: '30px' }}>+ Add media</CardTitle>
            </DropInfoWebBottom>

        </DropInfoWebMint>
        <StyledModal
            isOpen={showPopup && popuptype == "imglist"}
            onBackgroundClick={() => setShowPopup(!showPopup)}
            onEscapeKeydown={() => setShowPopup(!showPopup)}
        > <ItemLabelFlex>
                <CardTitle>Add iten preview images</CardTitle>
                <CloseImg
                    src={darkMode ? closeIconN : closeIcon}
                    onClick={() => setShowPopup(!showPopup)}
                />
            </ItemLabelFlex>
            <ImgGroup>
                {imgListUrl.map((i: { img: string }, index: number) => (
                    <ImgUpdateItemFilled key={index}>
                        <UploadDropzone url={i.img} width="150px" height="150px" urlChange={(url) => addOneImg(url, index)}></UploadDropzone>
                        {i.img ? (<ImgUpdateItemDel><CloseImg onClick={() => { delOneImg(index) }}
                            src={darkMode ? delW : delB}
                        /></ImgUpdateItemDel>) : null}
                    </ImgUpdateItemFilled>
                ))}
                <div style={{ width: '150px', height: '0', marginBottom: '0' }}></div>
                <div style={{ width: '150px', height: '0', marginBottom: '0' }}></div>
            </ImgGroup>
            <ButtonPurple padding='10px' $borderRadius='4px' width='100%' onClick={() => saveList()}>save</ButtonPurple>
        </StyledModal>
        <StyledModal
            isOpen={showPopup && popuptype == "sections"}
            onBackgroundClick={() => setShowPopup(!showPopup)}
            onEscapeKeydown={() => setShowPopup(!showPopup)}
        >  <ItemLabelFlex>
                <CardTitle>Add media section</CardTitle>
                <CloseImg
                    src={darkMode ? closeIconN : closeIcon}
                    onClick={() => setShowPopup(!showPopup)}
                />
            </ItemLabelFlex>

            <CardItem>
                <ItemLabel>Image or video *</ItemLabel>
                <ItemUpImg style={{ height: '251px' }}>
                    <UploadBox>
                        <UploadDropzone width={'250px'} height={"100px"} url={secUrl} urlChange={(url) => setSecUrl(url)}></UploadDropzone>
                    </UploadBox>
                    <AddImgText1>Drag and drop or click to upload</AddImgText1>
                    <AddImgText1>12:5 aspect ratio. Minimum of 2500px X 1042px recommended.</AddImgText1>
                </ItemUpImg>
            </CardItem>
            <CardItem>
                <ItemLabel>Title *</ItemLabel>
                <ItemValue>
                    <Input
                        type="text"
                        placeholder="Enter a section title"
                        value={secTitle}
                        sx={inputStyle}
                        onChange={(e) => {
                            setSecTitle(e.target.value);
                        }}
                    />
                </ItemValue>
            </CardItem>

            <CardItem>
                <ItemLabel>Description</ItemLabel>
            
                    <StyeldTextarea
                        id="comment"
                        name="comment"
                        placeholder="Description your section"
                        value={secDesc}
                        sx={areaStyle1}
                        onChange={(e) => {
                            setSecDesc(e.target.value);
                        }}
                    />
             

                {secIndex == 9999 ? null : (
                  <RadixPopconfirm
                  val={'Are you sure to delete this?'}
                  labelNode={ <ButtonTransparent padding='10px' $borderRadius='4px' margin="0 0 10px 0" width='100%'>Del</ButtonTransparent>}
                  onConfirm={() => {
                    delSec();
                  }}
                ></RadixPopconfirm>
                
            )}

                <ButtonPurple padding='10px' $borderRadius='4px' width='100%' onClick={() => saveSec()}>save</ButtonPurple>
            </CardItem></StyledModal>
        <StyledModal
            isOpen={showPopup && popuptype == "desc"}
            onBackgroundClick={() => setShowPopup(!showPopup)}
            onEscapeKeydown={() => setShowPopup(!showPopup)}
        >    <ItemLabelFlex>
                <CardTitle>Add description</CardTitle>
                <CloseImg
                    src={darkMode ? closeIconN : closeIcon}
                    onClick={() => setShowPopup(!showPopup)}
                />
            </ItemLabelFlex>
            <CardItem>
                <ItemTip>
                    Add details about the drop, explain the collection, and tell your story so people can understand and get excited about your drop.
                </ItemTip>
                <ItemValueArea>
                    <Textarea
                        id="comment"
                        name="comment"
                        placeholder="You description here..."
                        value={value?.desc}
                        sx={areaStyle}
                        onChange={(e) => {
                            onChange({ desc: e.target.value });
                        }}
                    />
                </ItemValueArea>
                <ButtonPurple padding='10px' $borderRadius='4px' width='100%' onClick={() => saveDesc()}>save</ButtonPurple>
            </CardItem></StyledModal>

    </>;
}
