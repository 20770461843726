import RadixPopover from "components/Popover";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import { ActivityIcon, ActivityLoadIcon } from "pages/Nft/components_";
import { useClientWidth } from "state/application/hooks";
import { openWindow, priceText, timestampToTime } from "utils";


import actBuy from "assets/h5/act_Buy.png";
import actSell from "assets/h5/act_Sell.png";
import actTransfer from "assets/h5/act_Transfer.png";
import nftDefault from "assets/nft/nft_defult.png";

import { AddressZero } from "constants/addresses";
import "pages/index.scss";
import { ActivityItem, ChainInfo, CollectionInfo } from "types";
const ActivityCardTableTitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 0 14px;
  box-sizing: border-box;
  height: 37px;
  color: ${({ theme }) => theme.text3};
  > div {
    width: 10%;
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-align: right;
  }
  > div:nth-child(2) {
    width: 23%;
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-align: left;
  }
  > div:nth-child(1) {
    width: 15%;
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-align: left;
  }
  > div:nth-child(3) {
    width: 12%;
  }
  > div:last-child {
    width: 20%;
  }
`;
const ItemImg = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 5px;
  object-fit: cover;
`;
const ItemLoadImg = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 5px;

  background: ${({ theme }) => theme.bg2};
`;
const ItemInfo = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin-left: 5px;

  max-width: calc(100% - 60px);
  > div:nth-child(1) {
    font-size: 14px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis; //溢出用省略号显示
    overflow: hidden;
    text-align: left;
  }
`;
const ActivityCardRow = styled.div`

  
  height: 80px;
  
  position: relative;
  color: ${({ theme }) => theme.text3};
  
  >div{
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 80px;
    padding: 0 14px;

    > div {
      // cursor: pointer;
      width: 10%;
      white-space: nowrap;
      font-size: 12px;
      overflow: hidden;
      text-align: right;
    }
    > div:nth-child(2) {
      width: 23%;
      white-space: nowrap;
      display: flex;
  
      align-items: center;
      overflow: hidden;
      text-align: left;
    }
    > div:nth-child(1) {
      width: 15%;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
    > div:nth-child(3) {
      width: 12%;
    }
    > div:nth-child(6),
    > div:nth-child(7) {
      cursor: pointer;
      color: ${({ theme }) => theme.purple1};
    }
    > div:last-child{
      width:20%;
    }
    :hover {
      border-radius: 10px;
  
      background: ${({ theme }) => theme.bg2};
    }
  }
 
`;
const ActivityCardBorder = styled.div`
border-top: 1px solid ${({ theme }) => theme.border1};

position: absolute;
top:0;
left:0;
width:100%;
height:0px !important;
`
const ActivityTel = styled.div<{ show: boolean }>`
  padding: 10px;
  box-sizing: border-box;

  border-radius: 10px;
  background: ${({ theme, show }) => (show ? theme.bg2 : "")};
`;
const ActivityTelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemInfoTel = styled.div`
  width: calc(100% - 60px);

  margin-left: 5px;
  > div {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    color: ${({ theme }) => theme.text2};
  }
`;
const TextBlod = styled.div`
  color: ${({ theme }) => theme.text1};
  font-size: 14px;

  font-weight: 600;
`;
const TextPurple = styled.div`
  color: ${({ theme }) => theme.purple1};

  font-weight: 600;
`;

const ActivityTelLine = styled.div`
  // margin:10px 0;
  padding: 10px 0 0;
  border-top: 1px solid ${({ theme }) => theme.bg2};
  width: 100%;
  color: ${({ theme }) => theme.text2};

  font-size: 12px;
  overflow-x: scroll;
  > div {
    width: 580px;
    height: 30px;
    > div {
      height: 30px;
      line-height: 30px;
      display: inline-block;
      width: 120px;
      text-align: center;
    }
    > div:first-child {
      width: 100px;
      text-align: left;
    }
    // > div:last-child {
    //   width: 240px;
    // }
  }
  > div:last-child {
    > div {
      font-weight: 600;
    }
  }
`;
const DivNone = styled.div`
  white-space: pre;
  display: inline-block;
  color: ${({ theme }) => theme.bg2};
  background: ${({ theme }) => theme.bg2};
`;
interface ActivityRowProps {
  i?: ActivityItem;
  chainCurrency?: ChainInfo;
  showIndex?: number;
  index?: number;
  collectInfo?: CollectionInfo | null;
  onChange: (index: number) => void;
}

export function ActivityTitleRow() {
  const clientWidth = useClientWidth();

  return (
    <>
      {Number(clientWidth) < 801 ? null : (
        <ActivityCardTableTitleRow>
          <div> </div>
          <div>Item</div>
          <div>Price</div>
          <div>Quantiy</div>
          <div>From</div>
          <div>To</div>
          <div>Time</div>
        </ActivityCardTableTitleRow>
      )}
    </>
  );
}
export function ActivityRow({
  i,
  chainCurrency,
  collectInfo,
  showIndex,
  index,
  onChange,
}: ActivityRowProps) {
  const clientWidth = useClientWidth();
  const navigate = useNavigate();



  return (
    <>
      {Number(clientWidth) < 801 ? (
        <ActivityTel show={showIndex === index}>
          <ActivityTelRow>
            <ItemImg src={i?.asset?.image_url || nftDefault} onClick={() =>
              navigate(
                `/nfts/assets/${i?.asset_id}`
              )
            }/>
            <ItemInfoTel>
              <div>
                <TextBlod>
                  {i?.asset?.name ? i.asset.name : (collectInfo?.name || ' ') + (i?.token_id ? "# " + i?.token_id : "")}
                </TextBlod>
                <div>
                  {i?.from_account ===
                    AddressZero
                    ? "Mint"
                    : i?.type}
                </div>
              </div>
              <div>
                <div>{collectInfo?.name}</div>
                <TextBlod style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '50%' }}>
                  {i?.unit_price
                    ? priceText(i.unit_price)
                    : "--"}
                </TextBlod>
              </div>
              <div>
                <div
                  style={{ fontWeight: "600" }}
                  onClick={() => onChange(index || 0)}
                >
                  {showIndex === index ? "-Less" : "+More"}
                </div>
                <div onClick={() =>
                  openWindow(
                    `${chainCurrency?.block_explorer_url}/tx/${i?.tx_hash}`
                  )
                }>
                  {timestampToTime(Number(i?.timestamp) || 0)}
                  {/* </Popover> */}
                </div>
              </div>
            </ItemInfoTel>
          </ActivityTelRow>
          {showIndex === index ? (
            <ActivityTelLine className="scrollbar">
              <div>
                <div>USD Price</div>
                <div>Rarity</div>
                <div>Quantiy</div>
                <div>From</div>
                <div>To</div>
              </div>

              <div>
                <div>--</div>
                <div>--</div>
                <div>--</div>
                <TextPurple>
                  {" "}
                  <RadixPopover val= {i?.from_account||''} label={i?.from_account
                      ? i.from_account.substring(0, 3) +
                      "..." +
                      i.from_account.substr(i.from_account.length - 5)
                      : "--"}></RadixPopover>

                
                  {" "}
                </TextPurple>
                <TextPurple>
                  {" "}
                  <RadixPopover val= {i?.to_account||''} label={i?.to_account
                      ? i.to_account.substring(0, 3) +
                      "..." +
                      i.to_account.substr(i.to_account.length - 5)
                      : "--"}></RadixPopover>
                
                  {" "}
                </TextPurple>
              </div>
            </ActivityTelLine>
          ) : null}
        </ActivityTel>
      ) : (
        <ActivityCardRow>
          <ActivityCardBorder />
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ActivityIcon
                src={
                  i?.type == "sale"
                    ? actSell
                    : i?.type == "transfer"
                      ? actTransfer
                      : actBuy
                }
                style={{ cursor: "auto" }}
              />
              <div style={{ marginLeft: "4px" }}>
                {i?.from_account === AddressZero
                  ? "Mint"
                  : i?.type}
              </div>
            </div>
            <div style={{cursor:'pointer'}} onClick={() =>
              navigate(
                `/nfts/assets/${i?.asset_id}`
              )
            }>
              <ItemImg src={i?.asset?.image_url || nftDefault} />
              <ItemInfo>
                <div>
                  {i?.asset?.name ? i.asset.name : (collectInfo?.name || ' ') + (i?.token_id ? "# " + i?.token_id : "")}
                </div>
                <div># {i?.token_id}</div>
              </ItemInfo>
            </div>
            <div>
              {" "}

              {i?.unit_price
                ? priceText(i.unit_price)
                : "--"}
            </div>
            {/* <div>--</div> */}
            <div>{i?.quantity}</div>
            <div>
            <RadixPopover val= {i?.from_account||''} label={i?.from_account
                      ? i.from_account.substring(0, 3) +
                      "..." +
                      i.from_account.substr(i.from_account.length - 5)
                      : "--"}></RadixPopover>
             
            </div>
            <div>
            <RadixPopover val= {i?.to_account||''} label={i?.to_account
                      ? i.to_account.substring(0, 3) +
                      "..." +
                      i.to_account.substr(i.to_account.length - 5)
                      : "--"}></RadixPopover>
             
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                openWindow(
                  `${chainCurrency?.block_explorer_url}/tx/${i?.tx_hash}`
                )
              }
            >
              {timestampToTime(Number(i?.timestamp) || 0)}
            </div></div>
        </ActivityCardRow>
      )}
    </>
  );
}
export function ActivityLoadRow() {
  const clientWidth = useClientWidth();

  return (
    <>
      {Number(clientWidth) < 801 ? (
        <ActivityTel show={false}>
          <ActivityTelRow>
            <ItemLoadImg />
            <ItemInfoTel>
              <div>
                <TextBlod>
                  <DivNone>{"             "}</DivNone>
                </TextBlod>
                <div>
                  <DivNone>{"             "}</DivNone>
                </div>
              </div>
              <div>
                <div>
                  <DivNone>{"               "}</DivNone>
                </div>
                <TextBlod>
                  <DivNone>{"                 "}</DivNone>
                </TextBlod>
              </div>
              <div>
                <div>
                  <DivNone>{"             "}</DivNone>
                </div>
                <div>
                  <DivNone>{"             "}</DivNone>
                </div>
              </div>
            </ItemInfoTel>
          </ActivityTelRow>
        </ActivityTel>
      ) : (
        <ActivityCardRow>
          <ActivityCardBorder />
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ActivityLoadIcon />
              <div style={{ marginLeft: "4px" }}>{"      "}</div>
            </div>
            <div>
              <ItemLoadImg />
              <ItemInfo>
                <div>
                  <DivNone>{"     "}</DivNone>
                </div>
                <div>
                  <DivNone>{"           "}</DivNone>
                </div>
              </ItemInfo>
            </div>
            <div>
              <DivNone>{"               "}</DivNone>
            </div>
            <div>
              <DivNone>{"          "}</DivNone>
            </div>
            <div>
              <DivNone>{"          "}</DivNone>
            </div>
            <div>
              <DivNone>{"               "}</DivNone>
            </div>
            <div>
              <DivNone>{"               "}</DivNone>
            </div>
            <div>
              <DivNone>{"               "}</DivNone>
            </div></div>
        </ActivityCardRow>
      )}
    </>
  );
}
