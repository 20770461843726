import { Box, Button, Flex, Text } from "rebass";
import { RefreshCcw, Repeat, Lock, Unlock } from "react-feather";

import TokenAvatar from "components/TokenAvatar";
import { BaseButton } from "components/Button";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Row from "components/Row";
import { useMarketPriceQuery } from "apis/token";
import { Input } from "components/NumericalInput";
import { StyledModal } from "components/Modal/styled_modal";
import TokenSelectPanel from "components/TokenSelectPanel";
import { Token } from "types";

const Container = styled(Flex)`
  border-radius: 8px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  justify-content: flex-start;
  padding: 18px 24px;
  flex-direction: column;
  > div {
    flex-direction: row;
  }
  > input {
    border: 0;
    text-align: right;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
`;
const PairLabel = styled(Flex)`

  font-size: 14px;
  justify-content: flex-start;
  align-items: center;
  align-content:center;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.text3};
  > button {
    color: ${({ theme }) => theme.text3};
  }
`;

const ButtonWrap = styled.button`
  padding:8px 12px;
  border-radius: 8px;
  align-items: center;
  align-content:center;
  display:flex;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};
 :disabled {
  background: ${({ theme }) => theme.bg6};
  color: ${({ theme }) => theme.text4};
}
`;

const InputRow = styled(Flex)`
  height: 36px;
  > input {
    border: 0;
    text-align: left;
  }
`;
const MarketPriceBox = styled(Flex)`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;
const MarketPriceOp = styled(Flex)`
  font-size: 14px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  > div {
    margin: 0 5px;
  }
  > button {
    padding: 0;
    margin: 0;
    height: 16px;
    width: 16px;
    color: ${({ theme }) => theme.text3};
  }
`;

const ButtonUse = styled(BaseButton)`
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};
  padding: 6px 16px;
  line-height: 12px;
  font-size: 14px;
  font-weight: 500;

`;
const ButtonRefresh = styled(BaseButton)`
  padding: 0;
  margin: 0;
  height: 16px;
  width: 16px;

  color: ${({ theme }) => theme.text2};
`;


const ButtonReverse = styled(Button)`
  cursor: pointer;
  color: ${({ theme }) => theme.text2};
`;

const TokenLabel=styled.div`
display: -webkit-flex; /* Safari */
align-items: center;
cursor: pointer;
`
export default function UnitPriceInput({

  base,
  quote,
  unitPrice,
  onChange,
  onReverse,
  onQuoteChange,
  onBaseChange
}: {

  base: Token;
  quote: Token;
  unitPrice:number|string;
  onChange:(price:number|string)=>void;
  onQuoteChange:(token:Token)=>void;
  onBaseChange:(token:Token)=>void;
  onReverse:()=>void;
}) {
  const [priceRate,setPriceRate]=useState(0)
  const [marketPrice,setMarketPrice]=useState(0)
  const [baseModalVisable,setBaseModalVisable]=useState(false)
  const [quoteModalVisable,setQuoteModalVisable]=useState(false)


  const handleQuoteTokenChange=(token:Token)=>{
   onQuoteChange(token)
  }
  const handleBaseTokenChange=(token:Token)=>{
    onBaseChange(token)
   }
  const {data,isSuccess,refetch,isFetching}=useMarketPriceQuery({base:base.chain+"/"+base.contract_address,quote:quote.chain+"/"+quote.contract_address})


  useEffect(()=>{
    if(isSuccess){
      setMarketPrice(data.data.unit_price)
    }
   
  },[data, isSuccess, marketPrice])


  useEffect(()=>{
    if(marketPrice>0 && unitPrice as number>0){
      const rate=(unitPrice as number-marketPrice)*100/marketPrice
      setPriceRate(Number(rate.toFixed(2)))
    }

  },[unitPrice,marketPrice])




  const handleOnUserInput=(input:string)=>{

      onChange(input)
  }
  return (
    <>
      <Container>
        <Flex justifyContent={"space-between"} alignItems={"center"} marginBottom={"12px"}>
          <PairLabel>
            <Box>
              <Flex alignItems={"center"}>
              <Text marginRight={"5px"}>1</Text>  <TokenLabel onClick={()=>{
          setBaseModalVisable(true)
        }} ><TokenAvatar
              size={24}
              token={base}
            />
         
          <div style={{marginLeft:"5px"}}>{base.symbol}</div></TokenLabel>
              </Flex>
            </Box>
          </PairLabel>
          <Box>
          <ButtonReverse onClick={()=>{onReverse()}}>
            <svg width="16px" height="16px" viewBox="0 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4834 5.71191C19.0879 5.29883 18.4727 5.30762 18.0859 5.71191L13.6562 10.2471C13.4805 10.4229 13.3662 10.6953 13.3662 10.9326C13.3662 11.4863 13.7529 11.8643 14.2979 11.8643C14.5615 11.8643 14.7725 11.7764 14.9482 11.5918L16.7588 9.71094L17.9189 8.375L17.8486 10.2383L17.8486 21.6465C17.8486 22.1914 18.2441 22.5869 18.7891 22.5869C19.334 22.5869 19.7207 22.1914 19.7207 21.6465L19.7207 10.2383L19.6592 8.375L20.8105 9.71094L22.6211 11.5918C22.7969 11.7764 23.0166 11.8643 23.2803 11.8643C23.8164 11.8643 24.2031 11.4863 24.2031 10.9326C24.2031 10.6953 24.0889 10.4229 23.9131 10.2471L19.4834 5.71191ZM7.84668 22.2793C8.24218 22.6924 8.85742 22.6836 9.24414 22.2793L13.6738 17.7529C13.8496 17.5684 13.9639 17.2959 13.9639 17.0586C13.9639 16.5137 13.5771 16.1357 13.0322 16.1357C12.7773 16.1357 12.5576 16.2236 12.3818 16.3994L10.5713 18.2803L9.41992 19.6162L9.48144 17.7529L9.48144 6.34473C9.48144 5.80859 9.08594 5.4043 8.54101 5.4043C8.00488 5.4043 7.60937 5.80859 7.60937 6.34473L7.60937 17.7529L7.6709 19.6162L6.51953 18.2803L4.70898 16.3994C4.5332 16.2236 4.31347 16.1357 4.05859 16.1357C3.51367 16.1357 3.12695 16.5137 3.12695 17.0586C3.12695 17.2959 3.24121 17.5684 3.41699 17.7529L7.84668 22.2793Z" fill="currentColor"></path></svg>
            </ButtonReverse></Box>
        </Flex>
        <InputRow>
          <Input placeholder="0.0" onUserInput={handleOnUserInput} value={unitPrice} />
          <TokenLabel onClick={()=>{
          setQuoteModalVisable(true)
        }}>
            <TokenAvatar
              size={32}
              token={quote}
            />
         
          <div style={{marginLeft:"5px"}}>{quote.symbol}</div>
        </TokenLabel>
        </InputRow>
      </Container>
      <MarketPriceBox>
        <Text>Market price</Text>
        <MarketPriceOp>
          <Text>
          {marketPrice>0? (<>1 {base.symbol}≈ {marketPrice} {quote.symbol}</>):<>No market price</>}
          </Text>
        {marketPrice>0?(<><Box>
            <ButtonRefresh onClick={refetch} disabled={isFetching}>
              <RefreshCcw />
            </ButtonRefresh>{" "}
          </Box>          <Box>
            <ButtonUse onClick={()=>{onChange(marketPrice.toFixed(8))}}>Use</ButtonUse>
          </Box></>):null}  

        </MarketPriceOp>
      </MarketPriceBox>
      <StyledModal isOpen={baseModalVisable} modalTitle={"Select Crypto"} onDismiss={()=>{setBaseModalVisable(false)}}>
        <div><TokenSelectPanel value={""} onChange={function (e: Token): void {
          handleBaseTokenChange(e);
          setBaseModalVisable(false);
        } } /></div>
      </StyledModal>

      <StyledModal isOpen={quoteModalVisable} modalTitle={"Select Crypto"} onDismiss={()=>{setQuoteModalVisable(false)}}>
        <div><TokenSelectPanel value={""} onChange={function (e: Token): void {
          handleQuoteTokenChange(e);
          setQuoteModalVisable(false);
        } } /></div>
      </StyledModal>

    </>
  );
}
