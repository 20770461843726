import { Box, Flex, Text } from "rebass";
import TokenAvatar from "components/TokenAvatar";
import styled from "styled-components";
import { formatNumber, NumberType } from "utils/formatNumbers";
import { useEffect, useState } from "react";
import { Order, Token } from "types";

const Container = styled(Flex)`
  border-radius: 8px;
  width: 450px;
  flex-direction: column;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  > div {
    width: 100%;
    justify-content: space-between;
    margin: 5px;
  }
`;

function TokenLabel({ token }: { token: Token }) {
  return (
    <>
      <Flex wrap="nowrap" alignItems={"center"}>
        <Box marginRight={"8px"}>
          <TokenAvatar size={32} token={token} />
        </Box>
        <Box>
          <Text>{token.symbol}</Text>
        </Box>
      </Flex>
    </>
  );
}

export function OrderDetail({ order }: { order: Order }) {
  const [unitPrice, setUnitPrice] = useState<number | null>(null);

  useEffect(() => {
    const amountOut =parseFloat(order.output.amount)
    const amountIn = parseFloat(order.input.amount)
    const price = amountIn / amountOut;
    if (Number.isNaN(price)|| !Number.isFinite(price)) {
      setUnitPrice(null);
    } else {
      setUnitPrice(price);
    }
  }, [order]);

  return (
    <>
      <Container>
        <Flex>
          <Box>
            <h2>Order details</h2>
          </Box>
        </Flex>
        <Flex>
          <Box>Received crypto</Box>
          <Box>
            <TokenLabel token={order.input.token} />
          </Box>
        </Flex>
        <Flex>
          <Box>Received amount</Box>
          <Box>{order.input.amount}</Box>
        </Flex>
        <Flex>
          <Box>Sold crypto</Box>
          <Box>
            <TokenLabel token={order.output.token} />
          </Box>
        </Flex>
        <Flex>
          <Box>Total amount</Box>
          <Box> {order.output.amount}</Box>
        </Flex>
        <Flex>
          <Box>Unit price</Box>
          <Box>
            <Box>
              1 {order.output.token.symbol} = {formatNumber({ input: unitPrice, type: NumberType.SwapPrice })} {order.input.token.symbol}
            </Box>
            <Box>
              1 {order.input.token.symbol} = {formatNumber({
                input: unitPrice != null ? 1 / unitPrice : null,
                type: NumberType.SwapPrice,
              })} {order.output.token.symbol}
            </Box>
          </Box>
        </Flex>
        <Flex>
          <Box>Expiration</Box>
          <Box> {order.expiry}</Box>
        </Flex>
      </Container>
    </>
  );
}
