import Row from "components/Row";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { DropPageProps } from "./types";
import {
  PageAll, TabWeb, TabItemWeb
} from "./components_";

import { useDarkModeManager } from "theme/components/ThemeToggle";
import DropTopForm, { DropTopForms } from "./components/DropTop";
import DropMintForm, { DropMintForms } from "./components/DropMint";
import DropMapForm, { DropMapForms } from "./components/DropMap";
import DropTeamForm, { DropTeamForms } from "./components/DropTeam";
import DropFaqForm, { DropFaqForms } from "./components/DropFaq";
import arrowL from "assets/tool/arrow_u.png";
import arrowLN from "assets/tool/arrow_u_n.png";
import { CollectionInfo } from "types";
const Container = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export default function DropPageForm({
  visible,
  value,
  onChange,
}: DropPageProps) {
  let navigate = useNavigate();
  const [darkMode] = useDarkModeManager();

  const [tab, setTab] = useState<string>('Mint');
  // const [imglist, setImglist] = useState<{img:string}[]>();
  const [collection, setCollection] = useState<CollectionInfo | null>();
  const [dropTopForm, setDropTopForm] = useState<DropTopForms>();
  const [dropMintForm, setDropMintForm] = useState<DropMintForms>();
  const [dropMapForm, setDropMapForm] = useState<DropMapForms>();
  const [dropTeamForm, setDropTeamForm] = useState<DropTeamForms>();
  const [dropFaqForm, setDropFaqForm] = useState<DropFaqForms>();


  useEffect(() => {
    if (value) {
      console.log('value.value',value)
      setDropTopForm({
        bannerUrl: value.bannerUrl,
        avatarUrl: value.avatarUrl,
        bannerUrlTel: value.bannerUrlTel,
        name: value.name,
        tabs: value.tabs,
      })
      setDropFaqForm({questList: value.questList})
      setDropMapForm({mapList: value.mapList})
      setDropTeamForm({teamList: value.teamList})
      setDropMintForm({  
        desc:value.desc,
        secList:value.secList,
        imgListUrl:value.imgListUrl,
        mintSchedule:value.mintSchedule,
        mintTime:value.mintTime
      })
    }
}, [value]);
useEffect(() => {
  if(dropTopForm){
    if(dropTopForm.bannerUrl&&(!value||!value.bannerUrl||dropTopForm.bannerUrl!=value?.bannerUrl)){
      onChange({ bannerUrl: dropTopForm.bannerUrl })
    }
    if(dropTopForm.avatarUrl&&(!value||!value.avatarUrl||dropTopForm.avatarUrl!=value.avatarUrl)){
      onChange({ avatarUrl: dropTopForm.avatarUrl })
    }
    if(dropTopForm.bannerUrlTel&&(!value||!value.bannerUrlTel||dropTopForm.bannerUrlTel!=value?.bannerUrlTel)){
      onChange({ bannerUrlTel: dropTopForm.bannerUrlTel })
    }
    if(dropTopForm.tabs&&(dropTopForm.tabs!=value?.tabs)){
      onChange({ tabs: dropTopForm.tabs })
    }
  }
  if(dropMintForm){
    if(dropMintForm.desc&&(dropMintForm.desc!=value?.desc)){
      onChange({ desc: dropMintForm.desc })
    }
    if(dropMintForm.imgListUrl&&(dropMintForm.imgListUrl!=value?.imgListUrl)){
      console.log('dropMintForm.imgListUrl&&(dropMintForm.imgListUrl!=value?.imgListUrl')
      onChange({ imgListUrl:dropMintForm.imgListUrl})
    }
    if(dropMintForm.secList&&(dropMintForm.secList!=value?.secList)){
      onChange({ secList: dropMintForm.secList })
    }
  }
  if(dropMapForm){
    if(dropMapForm.mapList&&(dropMapForm.mapList!=value?.mapList)){
      onChange({ mapList: dropMapForm.mapList })
    }
  }
  if(dropTeamForm){
    if(dropTeamForm.teamList&&(dropTeamForm.teamList!=value?.teamList)){
      onChange({ teamList: dropTeamForm.teamList })
    }
  }
  if(dropFaqForm){
    if(dropFaqForm.questList&&(dropFaqForm.questList!=value?.questList)){
      onChange({ questList: dropFaqForm.questList })
    }
  }
}, [dropTopForm,dropMintForm,dropMapForm,dropTeamForm,dropFaqForm]);
  return (
    <>
      <Container visible={visible}>
      <DropTopForm
        value={dropTopForm}
        onChange={(value) => setDropTopForm({ ...dropTopForm, ...value })} />

      <PageAll className="web-page" style={tab == 'Mint'?{minHeight:'0',height:'auto'}:{}}>
        <TabWeb>
          <TabItemWeb isAct={tab == 'Mint'} onClick={() => setTab('Mint')}>Mint</TabItemWeb>
          {dropTopForm?.tabs?.includes('roadmap')?<TabItemWeb isAct={tab == 'Roadmap'} onClick={() => setTab('Roadmap')}>Roadmap</TabItemWeb>:null}
          {dropTopForm?.tabs?.includes('team')?<TabItemWeb isAct={tab == 'Team'} onClick={() => setTab('Team')}>Team</TabItemWeb>:null}
          {dropTopForm?.tabs?.includes('faqs')?<TabItemWeb isAct={tab == 'FAQ'} onClick={() => setTab('FAQ')}>FAQ</TabItemWeb>:null}
          
          
          
        </TabWeb>
       
        <DropMapForm
          visible={tab == 'Roadmap'}
          value={dropMapForm}
          onChange={(value) => setDropMapForm({ ...dropMapForm, ...value })} />
        <DropTeamForm
          visible={tab == 'Team'}
          value={dropTeamForm}
          onChange={(value) => setDropTeamForm({ ...dropTeamForm, ...value })} />
        <DropFaqForm
          visible={tab == 'FAQ'}
          value={dropFaqForm}
          onChange={(value) => setDropFaqForm({ ...dropFaqForm, ...value })} />


      </PageAll>
      <DropMintForm
          visible={tab == 'Mint'}
          value={dropMintForm}
          onChange={(value) => setDropMintForm({ ...dropMintForm, ...value })} />
      <div style={{ height: '70px' }}></div>
      {/* <PageFooter >

        <div  onClick={() => savePage()}
        >save draft</div>
      </PageFooter>*/}
      </Container> 
    </>
  );
}
