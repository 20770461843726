import { Flex, Text, Box, Button, Image } from "rebass";

import styled from "styled-components";
import React, { useEffect, useState } from "react";

import { Input } from "@rebass/forms";
import { useTokenListQuery } from "apis/token";
import { Search } from "react-feather";
import { BaseButton, ButtonClear } from "components/Button";
import TokenAvatar from "components/TokenAvatar";
import { shortenAddress } from "utils/address";
import { useSupportChains } from "state/chain/hooks";
import { Separator } from "theme";
import { Token } from "types";

const Container = styled.div`
  padding: 24px;
  width: 100wh;
`;
const InputWrap = styled(Flex)`
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  line-height: 24px;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme }) => theme.bg2};
  justify-content: center;
  align-items: center;
  padding: 8px;
  > div:first-child {
    > div {
      margin-right: 5px;
    }
  }
  > div {
    margin-right: 5px;
  }
  > input {
    border: 0;
    text-align: left;
    margin: 0;
    padding: 0;
  }
`;
const List = styled.div`
  width: 100%;
  min-width: 400px;
 
  height: 400px;
  display:block;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
    background-color: ${({ theme }) => theme.bg1};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.bg3};
  }
  @media screen and (min-width: 0px) and (max-width: 800px) {
    height: auto;
  }
`;

const SearchIcon = styled(Search)`
  width: 18px;
  color: ${({ theme }) => theme.text4};
`;

const TextButton = styled(Flex)<{selected:boolean}>`
  margin:6px 6px 0 0;
  cursor: pointer;;
  float: left;
  justify-content: center;
  align-items:: center;
  align-content:center;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme,selected}) => selected?theme.bg5:theme.bg2};
  border-radius: 8px;
  display: block;
  height: 40px;
  width: 40px;
  :active {
    border: 1px solid ${({ theme }) => theme.bg5};
  }
`;
const IconButton = styled(Flex)<{selected:boolean}>`
margin:6px 6px 0 0;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme }) => theme.modalBG1};
  border: 1px solid ${({ theme,selected }) => selected?theme.bg5:theme.bg2};
  border-radius: 8px;
  float: left;
  display: block;
  height: 40px;
  width: 40px;
  padding: 5px;
  cursor: pointer;
  > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
  }
  :active {
    border: 1px solid ${({ theme }) => theme.bg5};
  }
`;

const ChainSelect=styled.div`

margin-top:24px;
display:block;
`
const Tile=styled(Flex)`
border-radius: 8px;
padding:12px 7px 12px 0;
cursor:pointer;
:hover {
  background: ${({ theme }) => theme.bg2};
}
    

`

interface TokensSelectProps {
  value: string;
  onChange: (e: Token) => void;
}

export default function TokenSelectPanel({
  value,
  onChange,
}: TokensSelectProps) {
  const [key, setKey] = useState("");
  const [chain,setChain]=useState("")

  const [tokenList, setTokenList] = useState<Token[]>([]);
  const [supportChains, setCurrentChain] = useSupportChains();
  const { data } = useTokenListQuery({
    chain: chain,
    key: key,
    current: 1,
    pageSize: 20,
  });
  useEffect(() => {
    if (data && data.success) {
      setTokenList(data.data);
    }
  }, [data]);

  return (
    <>
      <Container>
        <InputWrap>
          <div>
            <SearchIcon />
          </div>
          <Input
            onChange={(e) => {
              setKey(e.target.value);
            }}
            value={key}
          />
          <ButtonClear
            onClick={() => {
              setKey("");
              console.log("fff");
            }}
          />
        </InputWrap>
        <ChainSelect>
          <Text>Select network:</Text>
          <Box height={"46px"}>
            <TextButton onClick={()=>setChain("")} selected={chain===""?true:false}>
              <Text textAlign={"center"}>All</Text>
            </TextButton>
            {supportChains.map((v) => {
              return (
                <IconButton selected={chain===v.id?true:false} onClick={()=>setChain(v.id)}>
                  <img src={v.logo} />
                </IconButton>
              );
            })}
          </Box>
        </ChainSelect>
            
            <Text marginTop={"24px"}>Select crypto</Text>
        <List>
          {tokenList.map((v) => {
            return (
              <>
                <Tile
      onClick={()=>{onChange(v)}}
                >
                  <TokenAvatar size={36} token={v} />
                  <Box marginLeft={"12px"}>
                    <Text fontSize={16}>{v.symbol}</Text>
                    <Text fontSize={12}>
                      {shortenAddress(v.contract_address, 8, 8)}
                    </Text>
                  </Box>
                </Tile>
              </>
            );
          })}
        </List>
      </Container>
    </>
  );
}
