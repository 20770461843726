import { DropInfo } from 'types';
import { emptySplitApi,emptySplitFileApi } from './emptySplitApi'
export interface DropListResponse {
  data: DropInfo[],
  success: string | null,
  message: string | null
  total: number;
}

const dropApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    saveDropDraft: builder.mutation<{
      data: DropInfo,
      success: string | null,
      message: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/drop/draft/${params.chain}/${params.contract_address}`,
        method: "PUT",
        body: { ...params }
      }),
    }),
    saveDropPubilsh: builder.mutation<{
      data: DropInfo,
      success: string | null,
      message: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/drop/publish/${params.chain}/${params.contract_address}`,
        method: "PUT",
        body: { ...params }
      }),
    }),
    changeDropDisplay: builder.mutation<{
      success: string | null,
      message: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/drop/publish/${params.chain}/${params.address}/display`,
        method: "PUT",
        body: { ...params }
      }),
    }),
    changeDropFeatured: builder.mutation<{
      success: string | null,
      message: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/drop/publish/${params.chain}/${params.address}/featured`,
        method: "PUT",
        body: { ...params }
      }),
    }),
    getDropDraft: builder.mutation<{
      data: DropInfo,
      success: string | null,
      message: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/drop/draft/${params.chain}/${params.address}`,
        method: "GET"
      }),
    }),
    getDropPublish: builder.mutation<{
      data: DropInfo,
      success: string | null,
      message: string | null
    }, Record<string, any>>({
      query: (params) => ({
        url: `/drop/publish/${params.chain}/${params.address}`,
        method: "GET"
      }),
    }),
    getDropList: builder.query<DropListResponse, Record<string, any>>({
      query: (params) => ({
        url: `/drop/publish?page=${params.page}&pageSize=${params.pageSize}${params.featured?'&featured='+params.featured:''}`,
        method: "GET"
      }),
    }),

    getAllowList: builder.mutation<{
      data: {address:string,custom_mint_limit:number,price:string}[],
      success: boolean,
      message: string | null,
      total:number
    }, Record<string, any>>({
      query: (params) => ({
        url: `/drop/draft/${params.chain}/${params.address}/allowlist?key=${params.key}`,
        method: "GET"
      }),
    }),
   


  }),
});

export const {
  useSaveDropDraftMutation,
  useGetDropDraftMutation,
  useGetDropListQuery,
  useSaveDropPubilshMutation,
  useGetDropPublishMutation,
  useChangeDropDisplayMutation,
  useChangeDropFeaturedMutation,
  useGetAllowListMutation,
} = dropApi;
