


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetDropListQuery } from "apis/drop";
import { priceText } from "utils";
import Image from "components/Image"
import Paging from "components/Paging";
import {
  ItemBottomName, ItemBottomNameText, CollectItemBottomInfo, BottomInfoTitle, BottomInfoDesc, CollectItemNone, CollectList, CollectItem,
  BottomInfoLine, CollectItemTop, CollectItemBottom
} from "../../components_";
import nftDefault from "assets/nft/nft_defult.png";
import { DropInfo } from "types";


const App: React.FC = () => {
  const [posts, setPosts] = useState<DropInfo[] | null>(null);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [list20None,] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]);
  let navigate = useNavigate();
  const { data, status } = useGetDropListQuery({
    page: current,
    pageSize: 20,
  });

  useEffect(() => {
    if (status === 'fulfilled' && data?.success) {
      let arr: DropInfo[] = []
      data.data.forEach((el: DropInfo) => {
        let item = { ...el, ...{ mint_text: 'Now' } }
        if (item.settings?.stages) {
          let start = item.settings.stages[0].start_time
          let end = item.settings.stages[item.settings.stages.length - 1].end_time

          if (end < Date.parse(new Date().toString()) / 1000) {
            item.mint_text = 'End'
          } else if (start > Date.parse(new Date().toString()) / 1000) {
            item.mint_text = new Date().toString().split(' ')[1] + ' ' + new Date().toString().split(' ')[2]
          }
        }
        arr.push(item)
      })
      setPosts(arr);
      setTotal(data.total);

    }

  }, [data, status]);




  return (
    <>
      {status !== "fulfilled" ? (
        <CollectList>
          {list20None.map(() => (
            <CollectItemNone></CollectItemNone>
          ))}
        </CollectList>
      ) : (<>
        <CollectList>
          {posts?.map((i: DropInfo, index: number) => (
            <CollectItem
              key={index}
              onClick={() => navigate(`/nfts/collection/${i.chain}/${i.contract_address}/overview`)}
            >
              <CollectItemTop>
                <Image src={i.pre_reveal?.image_url || nftDefault} alt="" style={{ width: '100%', objectFit: 'cover' }} />

              </CollectItemTop>
              <CollectItemBottom>
                <ItemBottomName>
                  <ItemBottomNameText>{i.pre_reveal?.name || '--'}</ItemBottomNameText>
                </ItemBottomName>
                <CollectItemBottomInfo>
                  <BottomInfoLine>
                    <BottomInfoTitle>Minting</BottomInfoTitle>
                    <BottomInfoDesc>
                      <div>
                        {i.mint_text}
                      </div>
                    </BottomInfoDesc>
                  </BottomInfoLine>
                  <BottomInfoLine>
                    <BottomInfoTitle>Price</BottomInfoTitle>
                    <BottomInfoDesc>{i.settings?.stages?.[0]?.sale_price ? priceText(i.settings.stages[0].sale_price) : 'none'}</BottomInfoDesc>
                  </BottomInfoLine>
                </CollectItemBottomInfo>
              </CollectItemBottom>
            </CollectItem>
          ))}
          <CollectItem style={{ height: '0', opacity: '0' }}></CollectItem>
          <CollectItem style={{ height: '0', opacity: '0' }}></CollectItem>
          <CollectItem style={{ height: '0', opacity: '0' }}></CollectItem>
        </CollectList>
      </>
      )}
      <Paging value={{ total: total, current: current, pagesize: 20, isLoad: status !== 'fulfilled' }} onChangeCurrent={(val) => setCurrent(val)} />

    </>
  );
};
export default App;
