

import { Links, User } from 'types';
import {   emptySplitFileApi } from './emptySplitApi'

export interface CreateCollectionResponse {
  data: {
    user: User;
    token: string;
  };

  msg: null | string;
  code: "fail" | "ok";
}

export interface UploadResponse {
  data: {
    url: string;
  };
  success:null|boolean;
  msg: null | string;
  code: "fail" | "ok";
}


export interface CreateCollectionRequest {
  logo: string;
  featuredImage: string | null;
  bannerImage: string | null;
  name: string;
  url: string | null;
  description: string | null;
  category: string[] | null;
  links: Links;
  blockchain:string|null
}



const fileApi = emptySplitFileApi.injectEndpoints({
  
  overrideExisting: false,

  endpoints: (builder) => ({

    createCollection: builder.mutation<CreateCollectionResponse, CreateCollectionRequest>({
      query: (collection) => ({
        url: `/file`,
        method: "POST",
        body: { ...collection },
      }),
    }),
    uploadImg: builder.mutation<UploadResponse, {data: FormData}>({
      
      query: (form) => (
        {
        url: `/file/upload`,
        method: "POST",
        body: form.data,
        formData: true
      }),
    }),
    upload: builder.mutation({
      query(body:FormData) {
        return {
          url: `/file/upload`,
          method: 'POST',
          body,
          formData: true,
        }
      },
    }),
    updateAllowList: builder.mutation<{
      data: string[],
      success:  string | null,
      message: string | null,
      total:number
    },  {
      chain:string,
      contract_address:string,
      data: FormData}>({
      query: (params) => ({
        url: `/drop/draft/${params.chain}/${params.contract_address}/allowlist`,
        method: "POST",
        body:params.data,
        formData: true,
      }),
    }),
  }),
});

export const {
  useCreateCollectionMutation,
  useUploadImgMutation,
  useUploadMutation,
  useUpdateAllowListMutation
} = fileApi;
