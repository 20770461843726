import UploadDropzone from "components/Form/upload";
import { useEffect, useState } from "react";

import { CollectionInfo } from "types";
import {
  AddImgText1,
  CardBox,
  CardItem,
  CardMain,
  CardTitle,
  ItemLabel,
  ItemTip,
  ItemUpImg,
  UploadBox
} from "../../components_";
export interface CollectionImages {
  logoUrl?: string;
  featuredUrl?: string;
  bannerUrl?: string;
  
}

interface CollectionImagesFormProps {
  visible: boolean;
  value: CollectionImages;
  collection?:CollectionInfo;
  onChange: (value: CollectionImages) => void;
}


export default function DetailForm({
  visible,
  value,
  collection,
  onChange,
}: CollectionImagesFormProps) {
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [featuredUrl, setFeaturedUrl] = useState<string>('');
  const [bannerUrl, setBannerUrl] = useState<string>('');


  useEffect(() => {
    console.log('value',value)
    if(value&&value.bannerUrl&&value.bannerUrl!==bannerUrl&&bannerUrl==''){
      setBannerUrl(value.bannerUrl)
    }
    if(value&&value.logoUrl&&value.logoUrl!==logoUrl&&logoUrl==''){
      setLogoUrl(value.logoUrl)
    }
    if(value&&value.featuredUrl&&value.featuredUrl!==featuredUrl&&featuredUrl==''){
      setFeaturedUrl(value.featuredUrl)
    }
  }, [value]);
  
  useEffect(() => {
      saveImg()
  }, [featuredUrl,bannerUrl,logoUrl]);
  const saveImg=()=>{
      let changedata={ logoUrl: logoUrl,featuredUrl:featuredUrl,bannerUrl:bannerUrl }
      if(changedata!==value){
        onChange(changedata)
      }
 
  }
  

  return <>  <CardBox isAct={visible}>
    <CardMain>
      <CardTitle>Collection Graphics</CardTitle>
      <CardItem>
        <ItemLabel>Logo image</ItemLabel>
        <ItemTip>
          This image will also be used for display purposes.
        </ItemTip>
        <ItemTip>
        logoUrl:{logoUrl}
        </ItemTip>
        <ItemUpImg>
        <UploadBox>
            <UploadDropzone id="logoUrl" url={value?.logoUrl} urlChange={(url) => {console.log('url',url);setLogoUrl(url)}}></UploadDropzone>
          </UploadBox>
          <AddImgText1>Recommended size: 350 x 350</AddImgText1>
        </ItemUpImg>
      </CardItem>
      <CardItem>
        <ItemLabel>Featured image</ItemLabel>
        <ItemTip>
          This image will be used for featuring your collection on the
          homepage, category pages, or other display areas of OpenSea.
        </ItemTip>
        <ItemTip>
        featuredUrl:{featuredUrl}
        </ItemTip>
        <ItemUpImg>
          <UploadBox>
            <UploadDropzone id="featuredUrl" url={value?.featuredUrl} width={'180px'} height={"110px"} urlChange={(url1) => {setFeaturedUrl(url1)}}></UploadDropzone>

          </UploadBox>
          <AddImgText1>Recommended size: 600 x 400</AddImgText1>
        </ItemUpImg>

      </CardItem>
      <CardItem>
        <ItemLabel>Banner image</ItemLabel>
        <ItemTip>
          This image will appear at the top of your collection page.
        </ItemTip>
        <ItemTip>
        bannerUrl:{bannerUrl}
        </ItemTip>
        <ItemUpImg>
        <UploadBox>
            <UploadDropzone id="bannerUrl" url={value?.bannerUrl} width={'300px'} urlChange={(url2) =>  {setBannerUrl(url2)} }></UploadDropzone>

          </UploadBox>
          <AddImgText1>Recommended size: 1400 x 350</AddImgText1>
        </ItemUpImg>

      </CardItem>
      {/* <CardItem>
        <ItemLabel onClick={()=>saveImg()}>save</ItemLabel>
        
      </CardItem> */}
    </CardMain>
  </CardBox></>;
}