import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { save, load } from "redux-localstorage-simple"
// import { emptySplitApi } from 'apis/emptySplitApi';
import application from './application/reducer'
import user from './user/reducer'
import stats from './stats/reducer'
import wallets from './wallets/reducer'
import chain from './chain/reducer'
import { emptySplitApi, emptySplitFileApi } from '../apis/emptySplitApi';
import { unauthenticatedMiddleware } from './middleware';

// import chatMiddleware from './pushing/socketMiddleware';

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists',"trade","wallets","stats"]


const store = configureStore({
  reducer: {
    application,
    user,
    wallets,
    stats,
    chain,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    [emptySplitFileApi.reducerPath]: emptySplitFileApi.reducer,

  },
  middleware:
    (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true,serializableCheck: false})
    // .concat(chatMiddleware)
    .concat(unauthenticatedMiddleware)
    .concat(emptySplitApi.middleware)
    .concat(emptySplitFileApi.middleware)
    .concat(save({ states: PERSISTED_KEYS, debounce: 1000 })),
    preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: process.env.NODE_ENV === 'test' }),
})

setupListeners(store.dispatch)
export default store
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>

